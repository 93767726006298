import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@pepconnect/services/auth.service';
import { CountryService } from '@pepconnect/services/country.service';
import { LocaleService } from '@pepconnect/services/locale.service';
import { OriginRedirectService } from '@pepconnect/services/origin-redirect.service';
import { TierService } from '@pepconnect/services/tier.service';
import { UserService } from '@pepconnect/services/user.service';
import { patchUser } from '@pepconnect/state/auth/auth.actions';
import { selectAuthState, selectSessionHasExpired } from '@pepconnect/state/auth/auth.selector';
import { AuthState, initialAuthState } from '@pepconnect/state/auth/auth.state';
import { AppState } from '@pepconnect/state/index';
import { MetadataActions } from '@pepconnect/state/metadata/metadata.actions';
import { firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { computeTransitions } from '../functions/app-state-transitions';
import { CustomWindow, WINDOW } from '../providers/window-provider';
import { NotificationService } from './notification.service';
import { UserHidService } from './user-hid.service';

/**
 * This service watches state and acts on any changes via subscriptions.
 * It will also hydrate the metadata store.
 *
 * See README in the root store folder.
 * */

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private authState: AuthState = initialAuthState();
  private $state = this.store.select(selectAuthState);
  private $sessionHasExpired = this.store.select(selectSessionHasExpired);
  constructor(
    private localeService: LocaleService,
    private store: Store<AppState>,
    private tierService: TierService,
    private translateService: TranslateService,
    private countryService: CountryService,
    private router: Router,
    private authService: AuthService,
    private originService: OriginRedirectService,
    private userService: UserService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    @Inject(WINDOW) private window: CustomWindow,
    private userHidService: UserHidService
  ) {
  }

  /** Once the app is loaded and NGX is init-ing, load the application data we wish to store */
  start() {
    this.watchAuthState();
    this.watchForExpiredSession();
    this.hydrateMetadataStore();
  }

  private hydrateMetadataStore() {
    this.$state.pipe(filter(authState => authState.isInitialized), distinctUntilChanged()).subscribe(() => {
      this.store.dispatch({ type: MetadataActions.LoadCountries });
      this.store.dispatch({ type: MetadataActions.LoadLocales });
    });
  }

  private watchAuthState() {
    this.$state.pipe(filter(authState => authState.isInitialized), distinctUntilChanged()).subscribe(async newState => {
      const transitions = computeTransitions(this.authState, newState);
      this.authState = newState;
      if (!transitions.isLoggingIn && (transitions.countryChanged || transitions.localeChanged)) {
        await firstValueFrom(this.authService.getRefreshToken());
        // clear tiers to force rehydrating tiers after getting the refresh token
        this.authState = Object.assign({}, this.authState);
        this.authState.tiers = undefined;
        this.window.__setWalkmeUser__(this.authState.user);
      }
      if (transitions.localeChanged) {
        this.localeService.setStoredLocale(this.authState.user.locale);
        this.translateService.use(this.authState.user.locale.locale);
        this.tierService.hydrateTiers(this.authState.user);
        this.localeService.updateRouteLocaleParam(this.authState.user.locale, this.router, this.route.snapshot);
        this.updateUserFlags(this.authState);
      }
      if (!transitions.localeChanged && (transitions.isLoggingIn || !this.areTiersHydrated(this.authState))) {
        this.tierService.hydrateTiers(this.authState.user);
      }
      if (transitions.countryChanged) {
        this.countryService.hydrateCountry(this.authState.user);
        this.countryService.setStoredCountry(this.authState.user.country);
        this.updateUserFlags(this.authState);
        this.originService.ensureCorrectOriginFromState(this.authState, this.userService);
      }
      if (transitions.isInitializing || transitions.isLoggingIn) {
        this.store.dispatch({ type: MetadataActions.LoadCountries });
        this.store.dispatch({ type: MetadataActions.LoadLocales });
        this.window.__setWalkmeUser__(this.authState.user);
      }
      if (transitions.storeFlagChange) {
        if (!this.authState.user?.flags['pep-connect-store']) {
          this.router.navigateByUrl(`/${this.authState.user?.locale?.locale}`);
        }
      }
      if (transitions.isLoggingOut) {
        this.window.__setWalkmeUser__(this.authState.user);
      }
      if (transitions.deInitialized) {
        // reinitialize the user
        firstValueFrom(this.userService.initialize());
      }
    });
  }


  private areTiersHydrated(authState: AuthState): boolean {
    return !!authState.tiers;
  }

  private async updateUserFlags(authState: AuthState) {
    let f = await firstValueFrom(this.userService.getDefaultFlags(authState.user));
    this.store.dispatch(patchUser({ mergeProps: { flags: f } }));
  }

  /**
   *  Watch for a users session to expire because they were unable to obtain a refresh token.
   *  When the session expires, we pop a message and log them out.
   *
   *  This method is the last in a chain that begins with "VerifyAuthData" in the auth service.
   *  Verify Auth Data is called in the page-policy guard and before each XHR request.
   * */
  private watchForExpiredSession() {
    this.$sessionHasExpired.pipe(filter(sessionHasExpired => sessionHasExpired)).subscribe(expired => {
      this.translateService.get('LOGIN_SESSION_EXPIRED').subscribe(t => {
        this.notificationService.showWarning(t);
      });
    });
  }
}
