<!-- result template for search-results.  Note, this is inside of a shadow dom so reg css classes dont work. -->
<ng-template #rt let-r="result">
    <div style="display: inline-grid; column-gap: 8px; grid-template-columns: auto 1fr; align-items: center;white-space: pre-wrap;">
        <span><!--spacer-->&Tab;</span>
        <span [innerHTML]="r.text"></span>
    </div>
</ng-template>

<form #heroSearchField (ngSubmit)="onSubmit()" [formGroup]="searchForm" id="search-form">
    <uimc-searchfield
    formControlName="searchText"
    [attr.border-style]="hideBorder ? 'none' : undefined"
    [prefix]="prefix"
    [theme]="theme"
    [placeholder]="'SEARCH_PLACEHOLDER_TEXT_V2' | translate"
    [editable]="true"
    (selectItem)="itemSelected($event)" 
    [uimcTypeahead]="search"
    (focusChange)="searchFocusChange.emit($event)"
    [resultTemplate]="rt">
    </uimc-searchfield>
</form>