import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants';
import { User } from '@pepconnect/models/user/user.model';
import { UserService } from '@pepconnect/services/user.service';
import { BaseComponent } from '@pepconnect/shared/base/base-component/base.component';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { of } from 'rxjs';
import { first, switchMap, take } from 'rxjs/operators';
import { SamlService } from '@pepconnect/features/sso/services/saml.service';
import { SsoLaunchService } from '@pepconnect/features/sso/services/sso-launch.service';

/**
 * This endpoint is where users will be sent when they come from a 3rd party
 * Identity Provider after the razor pages have handled the initial SAML login
 * request. Users will be authenticated into PEPconnect here and then passed
 * on to either the relaystate redirect or to the normal drop page for
 * logged in users.
 * */
@Component({
  selector: 'app-saml-sp-login',
  template: ''
})
export class SamlSpLoginComponent extends BaseComponent implements OnInit {
  private $state = this.store.pipe(select(selectAuthState));
  private requestId: string;
  private nonce: string;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private samlService: SamlService,
    private ssoService: SsoLaunchService,
    private store: Store<AppState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.requestId = this.route.snapshot.paramMap.get('requestId');
    this.nonce = this.route.snapshot.paramMap.get('nonce');

    if (this.requestId) {
      // once the user is initialized then call the SSO stuff
      this.$state.pipe(
        first(state => state.isInitialized),
        switchMap(state => {
          if (state.isLoggedIn) {
            return this.userService.logout(false, false).pipe(take(1), switchMap(() => of(state)));
          } else {
            return of(state);
          }
        })
      ).subscribe(state => this.processLogin(state.user));
    } else {
      this.router.navigate(['404']);
    }
  }

  processLogin(user: User) {
    // call the SAML service to get the SAML response
    this.samlService.processSpAuthn(this.requestId, this.nonce, user)
      .pipe(
        take(1),
        switchMap(resp => {
          // if we have a relay state, that's where we're going to redirect to once they've logged in.
          if (resp.relayState) {
            sessionStorage.setItem(constants.SS_REDIRECT_URL, resp.relayState);
          }
          // Log the user in via the SAML response object
          return this.ssoService.login(resp, this.router)
            .pipe(
              take(1),
              switchMap(loggedInUser => {
                // Call the SSO launch process that should send the user wherever they're supposed to be
                return this.ssoService.launch(resp, loggedInUser, this.router);
              })
            );
        })
      ).subscribe();
  }

}
