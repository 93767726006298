<!-- hidden for print -->
<header id="v2-header" class="marcom-compatible no-print" data-ste-chapter="header" appRootFontSize>
    <app-app-bar></app-app-bar>
    <app-topnav></app-topnav>
</header>

<!-- print only view-->

<div class="application-bar print-only margin-left-1">
    <div class="application-bar-container">
        <div class="application-bar__logo">
            <img src="assets/img/logo.svg" alt="PEPconnect" class="print-logo"  />
            <h3 class="display-inline margin-left-1">{{'ABOUT_PEPCONNECT_TITLE' | translate }}</h3>
        </div>
    </div>
</div>
