<app-page-container id="about-container">
    <app-narrow-wrap>
        <h3 id="pepconnect">
            {{ 'ABOUT_PEPCONNECT_TEXT' | translate }}
        </h3>
        <p>
            {{ 'ABOUT_PEPCONNECT_DESCRIPTION1' | translate }}
        </p>
        <p [innerHTML]="insertHtmlTags('ABOUT_PEPCONNECT_DESCRIPTION2' | translate)"></p>
        <ul>
            <li [innerHTML]="insertHtmlTags('ABOUT_PEPCONNECT_DESCRIPTION3_ITEM_0' | translate)"></li>
            <li [innerHTML]="insertHtmlTags('ABOUT_PEPCONNECT_DESCRIPTION3_ITEM_1' | translate)"></li>
            <li [innerHTML]="insertHtmlTags('ABOUT_PEPCONNECT_DESCRIPTION3_ITEM_2' | translate)"></li>
        </ul>
        <h3 id="pepconnections">
            {{ 'ABOUT_PEPCONNECTIONS_TITLE' | translate }}
        </h3>
        <p>
            {{ 'ABOUT_PEPCONNECTIONS_DESC1' | translate }}
        </p>
        <p [innerHTML]="insertHtmlTags('ABOUT_PEPCONNECTIONS_DESC2' | translate)"></p>
        <ul>
            <li [innerHTML]="insertHtmlTags('ABOUT_PEPCONNECTIONS_DESC3_ITEM0' | translate)"></li>
            <li [innerHTML]="insertHtmlTags('ABOUT_PEPCONNECTIONS_DESC3_ITEM1' | translate)"></li>
            <li [innerHTML]="insertHtmlTags('ABOUT_PEPCONNECTIONS_DESC3_ITEM2' | translate)"></li>
            <li [innerHTML]="insertHtmlTags('ABOUT_PEPCONNECTIONS_DESC3_ITEM3' | translate)"></li>
        </ul>
        <p>
            <em>{{ 'ABOUT_PEPCONNECTIONS_DESC4' | translate }}</em>
        </p>
        <p>
            <em>{{ 'ABOUT_PEPCONNECTIONS_DESC5' | translate }}</em>
        </p>
        <div id="bottom"></div>
    </app-narrow-wrap>
</app-page-container>
