import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { forms } from '@pepconnect/constants/forms';
import { ForgotPasswordRequest } from '@pepconnect/dtos/account/password-request.dto';
import { OnToken } from '@pepconnect/interfaces/itoken.interface';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { User } from '@pepconnect/models/user/user.model';
import { UserService } from '@pepconnect/services/user.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { emailValidator } from '@pepconnect/shared/validators/email-validator.directive';
import { AppState } from '@pepconnect/state/index';
import { map, Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseSmartComponent implements OnInit, OnToken {
  forgotPasswordForm: FormGroup;
  user: User;
  hasError: boolean;
  pageContent: Observable<string>;
  supportMessage: Observable<string>;
  routerLocale: string;
  submitted = false;
  loading = false;
  token: string;

  constructor(private fb: FormBuilder,
    private userSvc: UserService,
    protected store: Store<AppState>,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private router: Router) { super(store); }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe(state => {
      this.user = state.user;
      this.routerLocale = state.user.locale.locale;
    });

    this.forgotPasswordForm = this.fb.nonNullable.group({
      email: this.fb.control('', { validators: [Validators.required, emailValidator(), Validators.maxLength(forms.VALIDATOR_EMAIL_MAX_LENGTH)] })
    });
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  onToken(token: string, cb?: () => void) {
    this.token = token;
    // Because this is an external callback, angular doesn't always see the changes
    this.cd.detectChanges();
    if(cb) {
      cb();
    }
  }
  showRecaptcha: boolean = false;
  resend() {
    this.showRecaptcha = true;
  }

  forgotPassword() {
    if (!this.forgotPasswordForm?.valid || !this.token) {
      return;
    }

    this.loading = true;
    this.submitted = true;
    const email = this.forgotPasswordForm.get('email').value;
    const token = this.token;
    const request: ForgotPasswordRequest = {
      email: email,
      locale: this.user.locale,
      checkCode: token
    };

    // api call
    this.userSvc.forgotPassord(request).subscribe({
      next: (response: UnserializedResponseMessage) => {
        this.hasError = false;
        this.loading = false;
        this.processPageContent(response.Message, email);
      },
      error: (err: UnserializedResponseMessage) => {
        this.hasError = true;
        this.loading = false;
        this.processPageContent(err.Message, email);

      }
    });
  }

  supportPage() {
    this.router.navigate([this.user.locale.locale, 'support']);
  }

  private processPageContent(message: string, email: string) {
    this.pageContent = this.translate.stream(message)
      .pipe(takeUntil(this.ngUnsubscribe), map(m => m.replace('@@EMAIL@@', email)));
    this.supportMessage = this.translate.stream('FORGOT_PASSWORD_SUCCESS_2')
      .pipe(takeUntil(this.ngUnsubscribe));
      this.showRecaptcha = false;
  }

}
