import { Component, Input, OnInit } from '@angular/core';
import { ObjectCard } from '@pepconnect/models/object-card/object-card.model';

@Component({
  selector: 'app-recommendation-carousel',
  templateUrl: './recommendation-carousel.component.html',
  styleUrls: ['./recommendation-carousel.component.scss']
})
export class RecommendationCarouselComponent implements OnInit {

  @Input() popularItems: ObjectCard[];
  @Input() newestItems: ObjectCard[];

  constructor() { }

  ngOnInit(): void { }

}
