import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { ModalsService } from '@pepconnect/services/modals.service';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { NavigationService } from '@pepconnect/services/navigation.service';

const inactiveCountryExceptions = ['support', 'login', 'register'];
const inactiveCountryRedirectExceptions = ['support'];

class InactiveCountriesGuard  {
  static onNext(route: ActivatedRouteSnapshot, store: Store<AppState>, navService: NavigationService, modalsService: ModalsService) {
    const current = route.url.toString();
    return store.select(selectAuthState).pipe(first(authState => authState.isInitialized),
      map(authState => {
        // pop up a modal with a login form
        if (!authState.user.country.isActive && !authState.isLoggedIn && current && !inactiveCountryExceptions.includes(current)) {
          this.openInactiveCountryModal(modalsService);
        }

        // pop up a modal that will redirect the user if they login with an inactive country
        if (!authState.user.country.isActive && authState.isLoggedIn && current && !inactiveCountryRedirectExceptions.includes(current)) {
          this.openInactiveCountryRedirectModal(modalsService);
        }
        if (authState.user.country.isEmbargoed) {
          navService.gotoNoAccess();
        }
        return true;
      }),
      catchError((err) => {
        return of(false);
      })
    )
  }

  static openInactiveCountryModal(modalsService: ModalsService) {
    modalsService.definitions.openInactiveCountryModal();
  }

  static openInactiveCountryRedirectModal(modalsService: ModalsService) {
    modalsService.definitions.openInactiveCountryRedirectModal()
  }
}


export const canActivateInactiveCountries: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store<AppState>);
  const navService = inject(NavigationService);
  const modalsService = inject(ModalsService);
  return InactiveCountriesGuard.onNext(route, store, navService, modalsService);
}

export const canActivateInactiveCountriesChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store<AppState>);
  const navService = inject(NavigationService);
  const modalsService = inject(ModalsService);
  return InactiveCountriesGuard.onNext(route, store, navService, modalsService);
}