import { Component, OnInit, inject } from '@angular/core';
import { constants } from '@pepconnect/constants';
import { ObjectCardSearchParameters } from '@pepconnect/dtos/search-request.dto';
import { environment } from '@pepconnect/environment';
import { ObjectCard } from '@pepconnect/models/object-card/object-card.model';
import { SearchService } from '@pepconnect/services/search.service';
import { UserService } from '@pepconnect/services/user.service';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import searchHelpers from '@pepconnect/utils/search-helpers';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseV2SmartComponent implements OnInit {

  private searchSvc = inject(SearchService);
  private userService = inject(UserService);

  env = environment;
  popularItems: ObjectCard[];
  newestItems: ObjectCard[];
  showCarousel: boolean;
  carouselLoading: boolean;

  ngOnInit() {
    combineLatest([this.authState$.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(
      /* emit if the current state is different that previous state, we check for specific data points here
      could add/remove if needed for future needs */
      (prev, curr) => prev.user?.locale?.locale == curr.user?.locale?.locale &&
        prev.user?.country?.code == curr.user?.country?.code &&
        prev.user?.group?.groupID == curr.user?.group?.groupID)),
    this.userService.checkFeatureFlag(constants.FLAG_HOME_RECOMMENDATION).pipe(takeUntil(this.ngUnsubscribe))])
      .subscribe(([state, flag]) => {
        this.showCarousel = false;
        if (!!state && flag) {
          this.executeSearch(state);
        }
      });
  }

  private executeSearch(state: AuthState)  {
    const requestParams: ObjectCardSearchParameters = {
      term: constants.SEARCH_DEFAULT_BLANK_SEARCH,
      size: constants.SEARCH_DEFAULT_CAROUSEL_SEARCH_LIMIT,
      skip: 0,
      locale: state.user.locale.locale,
      group: state.user.group?.groupID?.toString() ?? constants.USER_DEFAULT_GROUP_ID.toString(),
      filters: [],
      countryCode: state.user.country?.code,
      objects: []
    }

    this.carouselLoading = true;
    this.showCarousel = true;
    // most popular tab data
    this.searchSvc.fetchPopular(requestParams).pipe(take(1)).subscribe({next: response => {
      this.carouselLoading = false;
      this.popularItems = searchHelpers.mapResults(response);
    }, error: () => this.handleError()});
    // new releases tab data
    this.searchSvc.fetchNewest(requestParams).pipe(take(1)).subscribe({next: response => {
      this.newestItems = searchHelpers.mapResults(response);
    }, error: () => this.handleError() });
  }

  private handleError() {
    // turn off spinner and show nothing.
    // this can happen if the search service is down for some reason and can't connect.
    this.carouselLoading = false;

  }
}
