import {
  Component, Output, OnInit, EventEmitter, ContentChildren, QueryList, ElementRef,
  ViewChild, ContentChild, ViewContainerRef, ChangeDetectorRef, AfterViewInit, Input, AfterContentInit, OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LayeredNavContentDirective } from './layered-nav-content.directive';
import { LayeredNavTitleComponent } from './layered-nav-title.component';

@Component({
  selector: 'app-layered-nav-link',
  templateUrl: './layered-nav-link.component.html',
})
export class LayeredLinkComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {

  @Output() click = new EventEmitter();

  @Output() childClick = new EventEmitter<(LayeredLinkComponent | LayeredNavTitleComponent)>();

  @Input() active: boolean = false;

  @ContentChildren(LayeredLinkComponent) submenuItems: QueryList<LayeredLinkComponent>;

  @ContentChild(LayeredNavTitleComponent) navTitle: LayeredNavTitleComponent

  @ContentChild(LayeredNavContentDirective) navContent: LayeredNavContentDirective;

  @ViewChild('item') el: ElementRef<HTMLLIElement>;

  @ViewChild('componentTemplate', { static: true }) template;

  @Input() clickable: boolean = true;
  isExpanded: boolean = false;
  suppressionHandle: any;

  private contentSubscription: Subscription;

  private childSubscriptions: Subscription[] = [];

  contentChecked: boolean = false;
  private index: number = 0;
  private depth: number = 0;
  private applyTransform: boolean = false;
  private mediaMatcher: MediaQueryList = matchMedia(`screen and (min-width: 960px)`);

  constructor(private viewContainer: ViewContainerRef, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.viewContainer.createEmbeddedView(this.template).detectChanges();

    const listener = (mql: MediaQueryListEvent) => {
      this.applyTransform = mql.matches
    };
    this.applyTransform = this.mediaMatcher.matches;

    if (this.mediaMatcher.addEventListener) {
      this.mediaMatcher.addEventListener('change', listener);
    } else {
      // this is to support Safari version older than 14
      this.mediaMatcher.addListener(listener);
    }
  }

  ngOnDestroy(): void {
    this.contentSubscription.unsubscribe();
    this.childSubscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterContentInit() {
    this.contentSubscription = this.submenuItems.changes.subscribe((queryList: QueryList<LayeredLinkComponent>) => {
      this.subscribeToQuerylist(queryList);
    });
    this.subscribeToQuerylist(this.submenuItems);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.contentChecked = true;
      this.cd.detectChanges();
    }, 10);
  }

  onClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
    if (this.clickable) {
      this.click.emit($event);
    }
    this.isExpanded = !this.isExpanded;
  }

  get hasSubmenu(): boolean {
    return this.contentChecked && this.submenuItems && this.submenuItems.length > 0;
  }

  get shouldShowSubmenu(): boolean {
    return this.hasSubmenu &&
      (!this.suppressionHandle?.suppress ||
        window.matchMedia('(max-width: 959px)').matches);
  }

  get isToplevel(): boolean {
    if (!this.contentChecked) {
      return false;
    }

    const ulParent = this.viewContainer.element.nativeElement.parentElement;
    if (ulParent && ulParent.tagName === 'UL' && ulParent.className.indexOf('nav-container') != -1) {
      return true;
    }
    return false;
  }

  get hasContent(): boolean {
    return this.contentChecked && !!this.navContent;
  }

  get hasTitle(): boolean {
    return this.contentChecked && !!this.navTitle;
  }

  get transformSubmenuAmount(): string {
    if (!this.applyTransform || this.depth < 1 || !this.shouldShowSubmenu) {
      return '0';
    }

    // subtract one because one child's bottom will need to be the same as ours
    const childAboveCount = this.submenuItems.length + (this.hasTitle ? 0 : -1);
    const offset = this.index;

    // the bottom of childAboveCount children will be at the bottom of the offset item
    // we want to align it such that the top of the children will match the top of
    // this menu item. So first, we find out how many items that is
    const deficiency = childAboveCount - offset;

    // return the deficiency css calculation to align the menu
    return `calc(${deficiency * 2.76}em + ${deficiency * 6}px)`;
  }

  collapseAll() {
    this.isExpanded = false;
    for (const sub of this.submenuItems) {
      sub.collapseAll();
    }
  }

  private subscribeToQuerylist(queryList: QueryList<LayeredLinkComponent>) {
    this.childSubscriptions.forEach(s => s.unsubscribe());
    this.childSubscriptions = [];

    const add = this.navTitle ? 1 : 0;
    queryList.forEach((component, index) => {
      component.depth = this.depth + 1;
      component.index = index + add;
      this.childSubscriptions.push(component.click.subscribe(() => this.childClick.emit(component)));
      this.childSubscriptions.push(component.childClick.subscribe(item => this.childClick.emit(item)));
    });

    if (this.navTitle) {
      this.childSubscriptions.push(
        this.navTitle.click.subscribe(() => this.childClick.emit(this.navTitle))
      );
    }
  }
}
