<app-page-container id="login-container" class="container-fluid">
    <app-full-height-wrap>
        @if (showLogin) {
            <div class="col-lg-12 col-xl-6" id="left">
                <div class="row justify-content-center align-content-center height-50">
                    <div class="col-sm-12 col-md-10 justify-content-center">
                        <h1 id="login-header" class="font-size-xxxlarge">{{'HEADER_LOGIN' | translate}}</h1>
                        @if(hidLogin) {
                            <app-hid-login></app-hid-login>
                        }
                        @else {
                            <app-login-form [loginSource]="source"></app-login-form>
                            <app-additional-login-options></app-additional-login-options>
                        }
                    </div>
                </div>
            </div>
            <div class="col-xl-6 hidden-lg-down" id="right">
                <img src="https://content-pep.siemens-info.com/ui/v2/faceofhope_2000h-1504w.png" alt="PEPconnect">
            </div>
        }
        @else {
            <div class="col-lg-12 col-xl-6">
                <p class="color-red-dark margin-top-2">IMPORTANT: This site is for review and translation purposes only.  Close this tab and return to Content Manager to log into Review PEPconnect.</p>
            </div>
        }
    </app-full-height-wrap>
</app-page-container>
