import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TermTypes } from '@pepconnect/enums/term-types.enum';

/** Stand alone terms page.
 * The terms-text directive does most of the heavy lifting and this page only exists for its routing ability.
 */

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  termType: TermTypes;
  constructor(private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document ) { }

  ngOnInit(): void {
    this.setTermType();
    this.document.getElementById('terms-container').scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'}); // Scroll to top of page
  }

  setTermType() {
    this.termType = this.route.snapshot.data.terms
  }

}
