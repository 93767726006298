import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { WINDOW } from '@pepconnect/providers/window-provider';
import { IdpLogin } from '@pepconnect/models/auth/idp-login.model';
import { BaseComponent } from '@pepconnect/shared/base/base-component/base.component';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { first, take } from 'rxjs/operators';
import { SamlService } from '@pepconnect/features/sso/services/saml.service';
import { constants } from '@pepconnect/constants/constants';
import { ssoConstants } from '@pepconnect/features/sso/model/sso-constants';

/**
 * This is the endpoint used for logging into a 3rd party via PEPconnect as the Identity Provider.
 * The user can be directed here directly (via a redirect link or hitting the url) or they can
 * be directed here via a service provider using Service Provider initiated login. If they come
 * via a SP initiated they'll have a request tagged onto the pathing (you'll see 2 different routes
 * in the route module for this) so that the login side can pair up this user to the SP request.
 * */
@Component({
  selector: 'app-saml-idp-initiate',
  template: ''
})
export class SamlIdpInitiateComponent extends BaseComponent implements OnInit {
  private $state = this.store.pipe(select(selectAuthState));
  private serviceProviderId: string;
  private requestId: string;
  private nonce: string;
  private relayState: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private samlService: SamlService
  ) {
    super();
  }

  ngOnInit(): void {
    this.populateRouteVariables();

    if (this.serviceProviderId) {
      // Make sure we're initialized before we do anything 
      this.$state.pipe(
        first(state => state.isInitialized)
      ).subscribe(s => this.processLaunch(s));
    } else {
      this.router.navigate(['404']);
    }
  }

  private processLaunch(state: AuthState) {
    if (state.isLoggedIn) {
      this.inititateAuthn();
    } else {
      const idpInfo: IdpLogin = {
        nonce: this.nonce,
        requestId: this.requestId,
        sp: this.serviceProviderId,
        relayState: this.relayState,
        processed: false
      };
      sessionStorage.setItem(constants.SS_IDP_LOGIN, JSON.stringify(idpInfo));
      this.router.navigate([state.user.locale.locale, constants.REDIRECT_REQUIREMENT_LOGGEDIN]);
    }
  }

  private inititateAuthn() {
    this.samlService.getIdpInitiateAuthn(this.serviceProviderId, this.relayState, this.requestId, this.nonce)
      .pipe(take(1))
      .subscribe(request => {
        // look to see what type of redirect binding we should be using
        if (request.Binding === ssoConstants.SAML_BINDING_POST) {
          // if it's post then we need to post the auth data to the endpoint
          // I could not find a better working way of doing this in Typescript so we're just doing it the old school way
          // create a form we will submit
          let form = document.createElement('form');
          form.method = 'post';
          form.action = request.ACSUrl;
          // create a hidden field for our data
          let hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = ssoConstants.SAML_AUTHN_RESPONSE_NAME;
          hiddenField.value = request.AuthnResponse;
          form.appendChild(hiddenField);
          // add the form to the DOM and submit
          document.body.appendChild(form);
          form.submit();
        } else {
          // if we're doing a redirect everything is crammed into the URL and we just toss them over the fence
          this.window.location.href = request.ACSUrl;
        }
      });
  }

  private populateRouteVariables() {
    this.serviceProviderId = this.route.snapshot.paramMap.get('sp');
    this.requestId = this.route.snapshot.paramMap.get('requestId') ?? '';
    this.nonce = this.route.snapshot.paramMap.get('nonce') ?? '';
    this.relayState = this.route.snapshot.queryParamMap.get('RelayState') ?? '';
  }

}
