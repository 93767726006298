<div *ngIf="(settings | async)?.length > 0" id="additional-login-section">
    <h2 id="additional-login-header" class="additional-login-header font-size-medium font-family-headline-small">
        {{'HEADER_LOGIN_ADDITIONAL' | translate}}</h2>
    <ul id="login-options-list" class="bare-list">
        <li *ngFor="let sso of settings | async">
            <a [attr.data-sso-vendor]="sso.vendorName" class="additional-login-link text-decoration-underline"
                (click)="login(sso)" href="#" appPreventDefault>{{sso.vendorName}}</a>
        </li>
    </ul>
</div>
