import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CountryLanguageTuple } from '@pepconnect-marcom/country-language-selector/country-language-selector.directive';
import { UserHidService } from '@pepconnect/services/user-hid.service';
import { UserService } from '@pepconnect/services/user.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state';
import { setUserCountry, setUserLocale } from '@pepconnect/state/auth/auth.actions';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { selectMetadataState } from '@pepconnect/state/metadata/metadata.selector';
import { MetadataState } from '@pepconnect/state/metadata/metadata.state';
import { firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, first, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-country-lang-selector',
  templateUrl: './country-lang-selector.component.html',
  styleUrls: ['./country-lang-selector.component.scss']
})


// Note: I use the verbiage of language instead of locale in this component because thats what the argument for the marcom component is labeled as.
export class CountryLangSelectorComponent extends BaseSmartComponent implements OnInit {
  $metaState = this.store.select(selectMetadataState);
  $authState = this.store.select(selectAuthState);

  loading = true;
  metaState: MetadataState;
  authState: AuthState;

  constructor(
    protected store: Store<AppState>,
    private userService: UserService,
    private userHidService: UserHidService
  ) {
    super(store);
  }

  ngOnInit() {
    this.$authState
      .pipe(
        filter(s => s.isInitialized),
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged((prev, curr) =>
          prev.user.country.code === curr.user.country.code && prev.user.locale.locale === curr.user.locale.locale && prev.isEmployee === curr.isEmployee),
        switchMap(authState => {
          this.loading = true;
          this.authState = authState;
          return this.$metaState.pipe(
            first(metaState => !!metaState.countries.length && !!metaState.locales.length)
          );
        }))
      .subscribe(metaState => {
        this.metaState = metaState;
        this.loading = false;
      });
  }

  get show() {
    return !this.loading && this.metaState.countries.length && this.metaState.locales.length;
  }

  async onCountryLanguageChanged($event: CountryLanguageTuple) {
    const countryCode = $event[0];
    const languageCode = $event[1];

    this.setCountry(countryCode);
    this.setLanguage(languageCode);

    if (this.authState.isLoggedIn) {
      await this.updateUserSettings(countryCode, languageCode);
    }
  }

  setCountry(newCountryCode: string) {
    const newCountry = this.getCountryFromCode(newCountryCode);
    this.store.dispatch(setUserCountry({ country: newCountry }));
  }

  setLanguage(newLanguageCode: string) {
    const newLanguage = this.getLanguageFromCode(newLanguageCode);
    this.store.dispatch(setUserLocale({ locale: newLanguage }));
  }

  updateUserSettings(countryCode: string, languageCode: string) {
    const country = this.getCountryFromCode(countryCode);
    const locale = this.getLanguageFromCode(languageCode);
    return firstValueFrom(this.userService.updateSettings({ ...this.authState.user, country, locale }));
  }

  getCountryFromCode(countryCode: string) {
    return this.metaState.countries.find(c => c.code === countryCode);
  }

  getLanguageFromCode(languageCode: string) {
    return this.metaState.locales.find(c => c.locale.toLowerCase() === languageCode.toLowerCase());
  }
}
