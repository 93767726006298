import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants';
import { footerLinks } from '@pepconnect/constants/footer-links';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { SurveyType } from '@pepconnect/enums/survey-type.enum';
import { UsesAuthState } from '@pepconnect/interfaces/iuses-state.interface';
import { Tier } from '@pepconnect/models/content/tier.model';
import { IFooterSiteMapItem } from '@pepconnect/models/footer/sitemap.model';
import { Link } from '@pepconnect/models/general/link.model';
import { ConfigService } from '@pepconnect/services/config-service';
import { LinksService } from '@pepconnect/services/links.service';
import { MetadataService } from '@pepconnect/services/metadata.service';
import { ModalsService } from '@pepconnect/services/modals.service';
import { SurveyLaunchService } from '@pepconnect/services/survey-launch.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { selectAuthState, selectUserLocale, selectUserTopTiers } from '@pepconnect/state/auth/auth.selector';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { selectUseCheckoutLayout } from '@pepconnect/state/flags/flags.selector';
import { AppState } from '@pepconnect/state/index';
import { filter, first, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseSmartComponent implements OnInit, UsesAuthState {
  private readonly configService = inject(ConfigService);
  loading = true;
  $authState = this.store.select((selectAuthState));
  $topTiers = this.store.select((selectUserTopTiers));
  $locale = this.store.select((selectUserLocale));
  $useCheckoutLayout = this.store.select(selectUseCheckoutLayout);
  authState: AuthState
  footerLinks: IFooterSiteMapItem[];
  locale: string;
  $version = this.metadataService.getVersion().pipe(take(1));
  isStoreEnabled: boolean;
  year = new Date().getFullYear();
  showCNLic = false;
  hidFlag = false;

  constructor(
    protected store: Store<AppState>,
    private linksService: LinksService,
    private metadataService: MetadataService,
    private modalService: ModalsService,
    private router: Router,
    private surveyLaunchService: SurveyLaunchService
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.getAuthState();
    this.setLocale();

  }

  getAuthState() {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe), filter(authState => authState.isInitialized)).subscribe(
      authState => this.setAuthState(authState)
    );

  }

  setAuthState(authState: AuthState) {
    this.authState = authState;
    this.footerLinks = footerLinks.map(link => ({
      link: link.link,
      showTiers: link.showTiers,
      items: link.items?.filter(item => item.authenticatedOnly ? authState.isLoggedIn : true)
                        .filter(item => item.isSupport ? !authState.simulatedUser : true)
     }));
    this.isStoreEnabled = this.authState.isCustomer && this.authState?.user?.flags && this.authState.user.flags[constants.FLAG_STORE];
    this.showCNLic = this.authState?.user?.country?.useChinaSettings;
    this.loading = false;
  }

  setLocale() {
    this.$locale.pipe(takeUntil(this.ngUnsubscribe)).subscribe(l => {
      this.locale = l.locale;
    })
  }

  isMailtoLink(link: Link): boolean {
    return link.rel === LinkRel.mailto;
  }

  isUrlLink(link: Link): boolean {
    return link.rel === LinkRel.url;
  }

  isPageLink(link: Link): boolean {
    return link.rel === LinkRel.path;
  }

  useDispatch(link: Link): boolean {
    return !this.isMailtoLink(link) && !this.isPageLink(link) && !this.isUrlLink(link)
  }

  getTierLink(tier: Tier): string {
    return tier.links?.find(l => l.rel == LinkRel.page)?.href;
  }

  getRouterLink(link: Link): string[] {
    return this.linksService.getRouterLinkPath(link, this.locale);
  }


  dispatch(link: Link) {
    switch (link.rel) {
      case LinkRel.faqLink:
        this.openFaqPage();
        break;
      case LinkRel.openPEPconnectionsVideo:
        this.openPreviewVideoModal();
        break;
      case LinkRel.openSurvey:
        this.openSatisfactionSurvey();
        break;
      default:
        this.linksService.dispatch(link);
        break;
    }
  }

  openFaqPage(): void {
    this.checkFeatureFlag(constants.FLAG_SUPPORT_SERVICENOW).pipe(take(1))
      .subscribe(serviceNowFlag => {
        if (serviceNowFlag) {
          this.configService.teamPlay$.pipe(first()).subscribe(teamPlay => {
            const link = new Link(LinkRel.url, teamPlay.faqUrl);
            this.dispatch(link);
          });
        }
        else {
          this.$locale.pipe(first()).subscribe(l => {
            const link = new Link(LinkRel.url, l.faqUrl)
            this.dispatch(link);
          })
        }

      })
  }

  openPreviewVideoModal() {
    this.modalService.definitions.openPreviewVideoModal();
  }

  routeToStoreHome() {
    this.router.navigate(['/', this.locale, 'store', 'home']);
  }

  openSatisfactionSurvey() {
    this.surveyLaunchService.launchSurvey(SurveyType.Satisfaction, constants.SATISFACTION_SURVEY_API_PATH).pipe(take(1)).subscribe();
  }

}
