import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Country } from '@pepconnect/models/intl/country.model';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { setUserCountry } from '@pepconnect/state/auth/auth.actions';
import { AppState } from '@pepconnect/state/index';
import { selectMetadataCountries } from '@pepconnect/state/metadata/metadata.selector';
import { first } from 'rxjs/operators';

/** This country selector exists within a form.  Consider the country selector in the shared module that can be used inside or outside a form instead  */

@Component({
  selector: 'app-standalone-country-selector',
  templateUrl: './standalone-country-selector.component.html',
  styleUrls: ['./standalone-country-selector.component.scss']
})
export class StandaloneCountrySelectorComponent extends BaseSmartComponent implements OnInit {

  $countries = this.store.pipe(select(selectMetadataCountries));
  countries: Country[];
  @Output() onCountrySelect = new EventEmitter();

  @Input() set countryCode(code: string) {
    this.countryForm.get('country').setValue(code);
  }

  constructor(protected store: Store<AppState>, private fb: UntypedFormBuilder) { super(store) }

  countryForm = this.fb.group({
    country: this.fb.control(null, [Validators.required])
  });

  ngOnInit() {
    this.$countries.pipe(
      first(c => this.populatedGuard(c))
    ).subscribe(countries => {
      this.countries = countries;
    })
  }

  submit() {
    if (!this.countryForm.valid || !this.countries) {
      return;
    }
    const selectedCountry = this.countries.find(c => c.code === this.countryForm.get('country').value);
    this.store.dispatch(setUserCountry({ country: selectedCountry }));
    this.onCountrySelect.emit();
  }
}
