import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { User } from '@pepconnect/models/user/user.model';
import { UserService } from '@pepconnect/services/user.service';
import { BaseComponent } from '@pepconnect/shared/base/base-component/base.component';
import { AppState } from '@pepconnect/state';
import { setIsDirectLaunch } from '@pepconnect/state/auth/auth.actions';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { first, of, switchMap, take } from 'rxjs';
import { LtiSsoService } from '@pepconnect/features/sso/services/lti-sso.service';
import { SsoLaunchService } from '@pepconnect/features/sso/services/sso-launch.service';

@Component({
  selector: 'app-lti-sso-login',
  template: ''
})
export class LtiSsoLoginComponent extends BaseComponent implements OnInit {
  private $state = this.store.pipe(select(selectAuthState));
  private launchId: string;
  private nonce: string;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ltiService: LtiSsoService,
    private ssoService: SsoLaunchService,
    private store: Store<AppState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.launchId = this.route.snapshot.paramMap.get('launchid');
    this.nonce = this.route.snapshot.paramMap.get('nonce');

    if (this.launchId) {
      // once the user is initialized then call the SSO stuff
      this.$state.pipe(
        first(state => state.isInitialized),
        switchMap(state => {
          if (state.isLoggedIn) {
            return this.userService.logout(false, false).pipe(take(1), switchMap(() => of(state)));
          } else {
            return of(state);
          }
        })
      ).subscribe(state => this.processLogin(state.user));
    } else {
      this.router.navigate(['404']);
    }
  }

  processLogin(user: User) {
    // get the sso response object from the LTI launch
    this.ltiService.getLaunchRequest(this.launchId, this.nonce, user)
      .pipe(take(1),
        switchMap(ssoResp => {
          // If this is a direct launch set the state for that
          if (ssoResp.isDirectLaunch) {
            this.store.dispatch(setIsDirectLaunch({ directLaunch: ssoResp.isDirectLaunch }));
          }
          // log the user in
          return this.ssoService.login(ssoResp, this.router).pipe(take(1),
            // call the launch process that should send them wherever they need to go
            switchMap(loggedInUser => this.ssoService.launch(ssoResp, loggedInUser, this.router))
          );
        })
      ).subscribe();
  }

}
