import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@pepconnect/shared/base';
import { SamlSettings } from '@pepconnect/models/saml/saml-settings.model';
import { SamlService } from '@pepconnect/features/sso/services/saml.service';
import { constants } from '@pepconnect/constants';

@Component({
  selector: 'app-additional-login-options',
  templateUrl: './additional-login-options.component.html',
  styleUrls: ['./additional-login-options.component.scss']
})
export class AdditionalLoginOptionsComponent extends BaseComponent {

  constructor(private router: Router, private samlSvc: SamlService) { super(); }

  get settings(): Observable<SamlSettings[]> {
    return this.samlSvc.getAvailableIDPs()
      .pipe(takeUntil(this.ngUnsubscribe), catchError(err => of(null)));
  }

  login(sso: SamlSettings) {
    // Get the relaystate to include in the launch if we need to redirect back to a specific page
    const relayState = sessionStorage.getItem(constants.SS_REDIRECT_URL);
    if (relayState) {
      sessionStorage.removeItem(constants.SS_REDIRECT_URL);
      this.router.navigateByUrl(`/sso/saml/sp/${sso.id}?RelayState=${encodeURIComponent(relayState)}`);
    } else {
      this.router.navigateByUrl(`/sso/saml/sp/${sso.id}`);
    }
  }
}
