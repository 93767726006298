<footer class="footer" data-ste-chapter="footer" *ngIf="($useCheckoutLayout | async) === false">
    <div class="container" *ngIf="!loading">
        <div class="row">
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="sitemap">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let col of footerLinks">
                            <h5 class="sitemap__title">
                                <a href="#" appPreventDefault (click)="dispatch(col.link)" class="">{{col.link.text | translate}}</a>
                            </h5>
                            <ul class="bare-list sitemap__list" *ngIf="!col.showTiers">
                                <li class="sitemap__item" *ngFor="let item of col.items">
                                    <a *ngIf="isUrlLink(item.link)" [href]="item.link.href" class="sitemap__link" target="_blank">
                                        {{ item.link.text | translate }}
                                    </a>
                                    <a *ngIf="isMailtoLink(item.link)" [href]="item.link.href" class="sitemap__link">
                                        {{ item.link.text | translate }}
                                    </a>
                                    <a *ngIf="isPageLink(item.link)" [routerLink]="getRouterLink(item.link)" class="sitemap__link">
                                        {{ item.link.text | translate }}
                                    </a>
                                    <a *ngIf="useDispatch(item.link)" href="#" appPreventDefault (click)="dispatch(item.link)" class="sitemap__link">
                                        {{ item.link.text | translate }}
                                    </a>
                                </li>
                            </ul>
                            <ul class="bare-list sitemap__list" *ngIf="col.showTiers">
                                <li class="sitemap__item" *ngFor="let tier of ($topTiers | async )">
                                    <a [routerLink]="getTierLink(tier)" class="sitemap__link">{{ tier.name }}</a>
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <h5 class="footer__col-title">{{'FOOTER_CONNECT' | translate}}</h5>
                <app-social-links></app-social-links>
            </div>
            <div *ngIf="isStoreEnabled" class="col-12 col-md-6 col-lg-4 col-xl-3">
                <h5 class="footer__col-title">{{'LABEL_PEPCONNECT_STORE' | translate}}</h5>
                <uimc-button [primary]="false" (click)="routeToStoreHome()">{{'FOOTER_VISIT_STORE_BUTTON' | translate}}</uimc-button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <ul class="legal-links">
                    <li class="legal-links__item">&copy; {{ 'COPYRIGHT1' | translate }} 2007-{{ year }}</li>
                    <li class="legal-links__item"><a href="https://www.siemens-healthineers.com/terms-of-use" class="legal-links__link" target="_blank">{{ 'TERMS_OF_USE' | translate }}</a></li>
                    <li class="legal-links__item"><a [routerLink]="['/', locale, 'privacy']" class="legal-links__link" target="_blank">{{ 'PRIVACY_POLICY' | translate }}</a></li>
                    <li class="legal-links__item">{{ 'COPYRIGHT2' | translate }}</li>
                    <li class="legal-links__item" *ngIf="!showCNLic">{{ ($version | async)?.message }}</li>
                    <li class="legal-links__item" *ngIf="showCNLic">
                        吴中经济技术开发区岭云网络技术工作室 版权所有 <a href="http://www.beian.miit.gov.cn" target="_blank">苏ICP备18034268号-20</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
