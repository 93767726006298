import { ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn } from "@angular/router";
import { map, Observable } from "rxjs";
import { CheckResetCodeResponse } from "@pepconnect/models/user/check-reset-code-response";
import { inject } from "@angular/core";
import { UserService } from "@pepconnect/services/user.service";

/** Route Resolver for user account pages such as reset password, confirm, complete sign-up */

export const userAccountPathResolver: ResolveFn<CheckResetCodeResponse> = 
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CheckResetCodeResponse> => {
    const userService = inject(UserService);
    let isGenerated = false;
    // flag to identify if the user is created from VO, grab it from route data for reset page
    if (route.data.isUserCreatedFromVo) {
      isGenerated = true;
    }

    /** flag that tells where the user is coming from. This is particulary for confirm page
     * where it has 2 route flow, and data is set for the page accordingly
     * Reset and complete sign-up pages always come with code in the path
     */
    if (route.data.hasCode) {
      return userService.checkResetCode(route.params.code).pipe(map(user => {
        const payload: CheckResetCodeResponse = { ...user, resetCode: route.params.code, isGenerated };

        return payload;
      }));
    } else {
      return null;
    }
  }
