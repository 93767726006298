import { Component, Input, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalsService } from '@pepconnect/services/modals.service';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { take } from 'rxjs';

@Component({
  selector: 'app-hid-login',
  templateUrl: './hid-login.component.html',
  styleUrls: ['./hid-login.component.scss']
})
export class HidLoginComponent extends BaseV2SmartComponent implements OnInit {
  private readonly translate = inject(TranslateService);
  private readonly modalsService = inject(ModalsService);

  @Input() guestCheckout: boolean = false;

  locale: string;

  ngOnInit(): void {
    this.userLocale$.pipe(take(1)).subscribe((l) => this.locale = l.locale);
  }

  get pepExistingAccountText() {
    return this.translate.instant('TEXT_HID_LOGIN_PAGE_EXISTING')
      .replace('@@TEXT_HID_LOGIN_PAGE_LINK_ACCOUNT@@', this.translate.instant('TEXT_HID_LOGIN_PAGE_LINK_ACCOUNT'));
  }

  get registerText() {
    return this.translate.instant('TEXT_HID_LOGIN_PAGE_REGISTER')
      .replace('@@REGISTER_BUTTON@@', this.translate.instant('REGISTER_BUTTON'));
  }

  linkAccount() {
    this.modalsService.definitions.openHIDTransitionFormModal();
  }
}
