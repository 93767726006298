/**
 * Constants for the registration process
 * */
class RegisterConstants {
  /** Locale storage keys for the CMS/Inline stuff */
  readonly HID_USER_LINKED = 'HID_USER_LINKED';
  readonly USER_ALREADY_EXISTS = 'REGISTRATION_USER_EXIST';
  readonly EMAIL_PLACEHOLDER = '@@EMAIL@@';
}

export const registerConstants = new RegisterConstants();

export enum RegistrationTypes {
  Legacy = 'Legacy',
  NewSiemensHID = 'NewSiemensHID',
  SiemensHIDAlreadyExisted = 'SiemensHIDAlreadyExisted'
}

  
