import { constants } from "@pepconnect/constants";
import { ScormSettings } from "@pepconnect/models/general/scorm-settings";
import { CdnSettings } from "@pepconnect/models/settings/cdn-settings.model";

export function setScormSettings(cdnSettings: CdnSettings, window: Window) {
  window[constants.SCORM_SETTINGS_KEY] = {
    cdn: cdnSettings,
  };
}

export function getScormSettings(): ScormSettings {
  return window[constants.SCORM_SETTINGS_KEY];
}
