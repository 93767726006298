import { FactoryProvider, InjectionToken } from "@angular/core";
import { ILoggerProvider } from "@pepconnect/providers/logging/ilogger-provider.interface";
import { EnvironmentService } from "@pepconnect/services/environment.service";
import { LogType } from "./log-type.enum";

/** Injection Token */
export const LOGGER = new InjectionToken<ILoggerProvider>('LoggerToken');

/** Factory to return a implementation of an analytics provider */
export function loggingFactory(env: EnvironmentService) {
  return env.allowLogging ? new StageLogger() : new DefaultLogger();
}

export const LOGGING_PROVIDER: FactoryProvider = {
  provide: LOGGER,
  useFactory: loggingFactory,
  deps: [EnvironmentService]
}


/** Default Logger inteded for production use.  Nothing will get logged */
export class DefaultLogger implements ILoggerProvider {

  log(message: string, logType?: LogType) { }
  trace(...args) { }
  dir(object: Object) { }
  table(object: Object | any[]) { }
  startTimer(label: string) { }
  endTimer(label: string) { }
  assert(...args) { }

}

/** Logger intended for use on stage environments. */
export class StageLogger implements ILoggerProvider {
  log(message: string, logType: LogType = LogType.Information): void {
    const logAction = {
      [LogType.Information]: console.log,
      [LogType.Error]: console.error,
      [LogType.Warning]: console.warn
    };

    logAction[logType](`${new Date()}: ${message}`);
  }
  trace(...args: any[]): void {
    console.trace(...args);
  }
  dir(object: Object): void {
    console.dir(object);
  }
  table(object: Object | any[]) {
    console.table(object);
  }
  startTimer(label: string) {
    console.time(label)
  }
  endTimer(label: string) {
    console.timeEnd(label);
    console.timeLog(label);
  }
  assert(condition: boolean, ...data) {
    console.assert(condition, ...data);
  }

}
