import { Injectable, inject } from '@angular/core';
import { SsoType } from '@pepconnect/features/sso/enums/sso-type';
import { SsoRequest } from '@pepconnect/features/sso/model/sso-request';
import { SsoResponse } from '@pepconnect/features/sso/model/sso-response';
import { ApiService } from '@pepconnect/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PepSsoService {
  private readonly api = inject(ApiService);

  getSsoResponse(request: SsoRequest, type: SsoType): Observable<SsoResponse> {
    return this.api.post(`sso/${type}`, request);
  }
}
