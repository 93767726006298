<article id="ept-section" class="container" *ngIf="showEPT" data-ste-component="cta"
    data-ste-component-state="EPT Access">
    <div class="row align-items-center">
        <div class="col-md-4 marketing-container ept">
            <div class="image-slot">
                <img class="image" src="assets/img/placeholder.jpg" alt="ept-image" />
            </div>
        </div>
        <div class="col-md-8 marketing-container ept">
            <div class="ept-content-block">
                <h2 class="hero-block__title">
                    {{'EP_TOOL_HEADER' | translate}}
                </h2>
                <p [innerHTML]="('EP_TOOL_CONTENT' | translate)"></p>
                <uimc-button style="float: right;" [primary]="true" (click)="goToEpt()">
                    {{'EP_TOOL_BUTTON_LABEL' | translate}}
                </uimc-button>
            </div>
        </div>
    </div>
    <hr>
</article>

<article id="experience-pepconnect" class="container" data-ste-component="marketing-video">
    <div class="row align-items-center">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <h1 class="hero-block__title">{{'MAIN_MARKETING_TITLE2' | translate}}</h1>
            <p [innerHTML]="'MAIN_MARKETING_CONTENT2' | translate"></p>
            <a [routerLink]="pepconnectionsLink" class="text-link">
                <span class="icon-link"></span>{{'MAIN_MARKETING_LINK2' | translate}}
            </a>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-6 pull-right">
            <div class="embed-responsive embed-responsive-16by9 video-container">
                <app-video-js-player videoId="exp-pepconnect-video"
                [src]="transformedVideoSource()"
                [poster]="transformedPoster()"
                [options]="{ fluid: true }">
            </app-video-js-player>
            </div>
        </div>
    </div>
</article>
