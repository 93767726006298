<app-page-container id="hid-transition-page">
    <app-narrow-wrap>
        <app-headline [headline]="'TEXT_HID_TRANSITION_PAGE_HL' | translate" [container]="false">
        </app-headline>
        <p class="lead">
            {{ 'TEXT_HID_TRANSITION_PAGE_L1' | translate: { EMAIL: userEmail }  }}
        </p>
        <ul>
            <li [innerHTML]="'TEXT_HID_TRANSITION_PAGE_L2' | translate"></li>
            <li>{{ 'TEXT_HID_TRANSITION_PAGE_L3' | translate }}</li>
            <li>{{ 'TEXT_HID_TRANSITION_PAGE_L4' | translate }}</li>
            <li>{{ 'TEXT_HID_TRANSITION_PAGE_L5' | translate }}</li>
            <li>{{ 'TEXT_HID_TRANSITION_PAGE_L6' | translate }}</li>
        </ul>
    </app-narrow-wrap>
</app-page-container>
