import { DatePipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideDialogConfig } from '@ngneat/dialog';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LayeredNavContentDirective } from '@pepconnect/components/navigation/layered-navbar/layered-nav-content.directive';
import { LayeredLinkComponent } from '@pepconnect/components/navigation/layered-navbar/layered-nav-link.component';
import { modalConfig } from '@pepconnect/extensions/modal-config';
import { CartService } from '@pepconnect/features/checkout/services/cart.service';
import { PEPCONNECT_INITIALIZER } from '@pepconnect/providers/initialization-provider';
import { RECAPTCHA_PROVIDER } from '@pepconnect/providers/recaptcha-provider';
import { VideoDataTrackService } from '@pepconnect/services/video-data-track.service';
import { SharedModule } from '@pepconnect/shared/module';
import { LocaleDatePipe } from '@pepconnect/shared/pipes/localization/date.pipe';
import { ResponseHandler } from '@pepconnect/utils/response-handler';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CountryLangSelectorComponent } from './core/components/country-lang-selector/country-lang-selector.component';
import { DisclaimerAlertComponent } from './core/components/disclaimer-alert/disclaimer-alert.component';
import { LayeredNavTitleComponent } from './core/components/navigation/layered-navbar/layered-nav-title.component';
import { LayeredNavbarComponent } from './core/components/navigation/layered-navbar/layered-navbar.component';
import { NavDropdownComponent } from './core/components/navigation/nav-dropdown.component';
import { NavItemComponent } from './core/components/navigation/nav-item.component';
import { NavLinkComponent } from './core/components/navigation/nav-link.component';
import { NotificationComponent } from './core/components/notification/notification.component';
import { SearchBarComponent } from './core/components/search-bar/search-bar.component';
import { StandaloneCountrySelectorComponent } from './core/components/standalone-country-selector/standalone-country-selector.component';
import { SurveyComponent } from './core/components/survey/survey.component';
import { BlurBodyDirective } from './core/directives/blur-body.directive';
import { ApiMainService } from './core/extensions/api-main.service';
import { ApiMockService } from './core/extensions/api-mock.service';
import { FooterComponent } from './core/layout/footer/footer.component';
import { PreviewPepconnectionsComponent } from './core/layout/footer/preview-pepconnections/preview-pepconnections.component';
import { SocialLinksComponent } from './core/layout/footer/social-links/social-links.component';
import { AppBarComponent } from './core/layout/header/app-bar/app-bar.component';
import { HeaderComponent } from './core/layout/header/header.component';
import { TopnavComponent } from './core/layout/header/topnav/topnav.component';
import { UserIdentifierComponent } from './core/layout/header/user-identifier/user-identifier.component';
import { ANALYTICS_PROVIDER } from './core/providers/analytics-provider';
import { LOGGING_PROVIDER } from './core/providers/logging/logger-provider';
import { MEDALLIA_PROVIDER } from './core/providers/medallia-provider';
import { WINDOW_PROVIDERS } from './core/providers/window-provider';
import { ApiService } from './core/services/api.service';
import { CustomTranslateLoaderService } from './core/services/custom-translate-loader.service';
import { EnvironmentService } from './core/services/environment.service';
import { AboutComponent } from './features/about/about.component';
import { ChangePasswordModalComponent } from './features/account/change-password-modal/change-password-modal.component';
import { ConfirmComponent } from './features/account/confirm/confirm.component';
import { ForgotPasswordComponent } from './features/account/forgot-password/forgot-password.component';
import { InactiveCountryRedirectComponent } from './features/account/intl/inactive-country-redirect/inactive-country-redirect.component';
import { InactiveCountryComponent } from './features/account/intl/inactive-country/inactive-country-modal.component';
import { AdditionalLoginOptionsComponent } from './features/account/login/additional-login-options/additional-login-options.component';
import { LoginFormComponent } from './features/account/login/login-form/login-form.component';
import { LoginModalComponent } from './features/account/login/login-modal/login-modal.component';
import { LoginComponent } from './features/account/login/login.component';
import { ProfilePromptModalComponent } from './features/account/profile-prompt-modal/profile-prompt-modal.component';
import { RegisterFormComponent } from './features/account/register/register-form/register-form.component';
import { RegisterComponent } from './features/account/register/register.component';
import { ResetPasswordComponent } from './features/account/reset-password/reset-password.component';
import { DenyModalComponent } from './features/account/terms/deny-modal/deny-modal.component';
import { TermsTextComponent } from './features/account/terms/terms-text/terms-text.component';
import { TermsComponent } from './features/account/terms/terms.component';
import { ActivityModule } from './features/activity/activity.module';
import { CancelCheckoutModalComponent } from './features/checkout/features/checkout/cancel-checkout-modal/cancel-checkout-modal.component';
import { NordicRegistrationComponent } from './features/checkout/features/nordic-registration/nordic-registration.component';
import { DeniedComponent } from './features/denied/denied.component';
import { EventsModule } from './features/events/events.module';
import { HeroComponent } from './features/home/hero/hero.component';
import { HomeComponent } from './features/home/home.component';
import { MarketingComponent } from './features/home/marketing/marketing.component';
import { RecommendationCarouselComponent } from './features/home/recommendation-carousel/recommendation-carousel.component';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { UnsubscribeComponent } from './features/profile/features/unsubscribe/unsubscribe.component';
import { SearchComponent } from './features/search/search.component';
import { CmsSsoLoginComponent } from './features/sso/components/cms-sso-login/cms-sso-login.component';
import { LtiSsoLoginComponent } from './features/sso/components/lti-sso-login/lti-sso-login.component';
import { PepSsoLoginComponent } from './features/sso/components/pep-sso-login/pep-sso-login.component';
import { SamlIdpInitiateComponent } from './features/sso/components/saml-idp-initiate/saml-idp-initiate.component';
import { SamlSpInitiateComponent } from './features/sso/components/saml-sp-initiate/saml-sp-initiate.component';
import { SamlSpLoginComponent } from './features/sso/components/saml-sp-login/saml-sp-login.component';
import { SsoMainComponent } from './features/sso/components/sso-main/sso-main.component';
import { TcmSsoLoginComponent } from './features/sso/components/tcm-sso-login/tcm-sso-login.component';
import { TeamsSharedModule } from './features/teams/teams-shared/teams-shared.module';
import { DataOptionsComponent } from './features/translate/data-options/data-options.component';
import { TranslateGridComponent } from './features/translate/translate-grid/translate-grid.component';
import { TranslateModalComponent } from './features/translate/translate-modal/translate-modal.component';
import { TranslateSpannerComponent } from './features/translate/translate-spanner/translate-spanner.component';
import { LOCALE_PROVIDER, registerSupportedPEPLocales } from './locale-support';
import { MarcomModule } from './marcom/marcom.module';
import { McTextFieldDirective } from './marcom/text-field/mc-text-field.directive';
import { metaReducers, reducers } from './store';
import { MetadataEffects } from './store/metadata/metadata.effects';
import { HidTransitionModalComponent } from './features/account/login/hid-transition-modal/hid-transition-modal.component';
import { HidTransitionIntermediateComponent } from './features/account/login/hid-transition-intermediate/hid-transition-intermediate.component';
import { HidLoginComponent } from './features/account/login/hid-login/hid-login.component';
import { HidAlreadyMigratedModalComponent } from './features/account/login/hid-already-migrated-modal/hid-already-migrated-modal.component';
import { HidTransitionFormModalComponent } from './features/account/login/hid-transition-form-modal/hid-transition-form-modal.component';
import { NextStepComponent } from './features/account/next-step/next-step.component';
import { AccountExistsComponent } from './features/account/account-exists/account-exists.component';

registerSupportedPEPLocales();

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppBarComponent,
    SearchBarComponent,
    FooterComponent,
    HeaderComponent,
    TopnavComponent,
    HeroComponent,
    UserIdentifierComponent,
    TranslateSpannerComponent,
    TranslateGridComponent,
    RegisterComponent,
    NavLinkComponent,
    NavItemComponent,
    NavDropdownComponent,
    CountryLangSelectorComponent,
    MarketingComponent,
    LoginComponent,
    LoginFormComponent,
    AdditionalLoginOptionsComponent,
    TermsTextComponent,
    TermsComponent,
    DenyModalComponent,
    InactiveCountryComponent,
    BlurBodyDirective,
    InactiveCountryRedirectComponent,
    LoginModalComponent,
    SurveyComponent,
    NotificationComponent,
    DataOptionsComponent,
    TranslateModalComponent,
    LayeredNavbarComponent,
    LayeredLinkComponent,
    LayeredNavContentDirective,
    LayeredNavTitleComponent,
    CancelCheckoutModalComponent,
    DisclaimerAlertComponent,
    RecommendationCarouselComponent,
    SsoMainComponent,
    SamlSpLoginComponent,
    SamlSpInitiateComponent,
    SamlIdpInitiateComponent,
    PepSsoLoginComponent,
    TcmSsoLoginComponent,
    CmsSsoLoginComponent,
    PreviewPepconnectionsComponent,
    NordicRegistrationComponent,
    LtiSsoLoginComponent,
    StandaloneCountrySelectorComponent,
    AboutComponent,
    SearchComponent,
    ForgotPasswordComponent,
    RegisterFormComponent,
    ResetPasswordComponent,
    ConfirmComponent,
    ChangePasswordModalComponent,
    SocialLinksComponent,
    UnsubscribeComponent,
    ProfilePromptModalComponent,
    DeniedComponent,
    NotFoundComponent,
    HidTransitionModalComponent,
    HidTransitionIntermediateComponent,
    HidLoginComponent,
    HidAlreadyMigratedModalComponent,
    HidTransitionFormModalComponent,
    NextStepComponent,
    AccountExistsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MarcomModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoaderService,
      },
      defaultLanguage: 'en-us',
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    EffectsModule.forRoot([MetadataEffects]),
    StoreDevtoolsModule.instrument({
      name: 'PEPconnect',
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true
    }),
    StoreRouterConnectingModule.forRoot({ navigationActionTiming: NavigationActionTiming.PostActivation }),
    SharedModule,
    EventsModule,
    TeamsSharedModule,
    ActivityModule
  ],
    providers: [
      PEPCONNECT_INITIALIZER,
      CartService,
      VideoDataTrackService,
      EnvironmentService,
      ResponseHandler,
      McTextFieldDirective,
      { provide: ApiService, useClass: environment.isInlineTranslate ? ApiMockService : ApiMainService },
      WINDOW_PROVIDERS,
      ANALYTICS_PROVIDER,
      LOGGING_PROVIDER,
      LOCALE_PROVIDER,
      MEDALLIA_PROVIDER,
      RECAPTCHA_PROVIDER,
      DatePipe,
      LocaleDatePipe,
      provideDialogConfig(modalConfig),
      provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
