import {
  AfterContentInit,
  AfterViewChecked, Component, ContentChildren, ElementRef,
  HostListener, Input, OnDestroy, OnInit, QueryList, ViewChildren
} from '@angular/core';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { BaseComponent } from '@pepconnect/shared/base/base-component/base.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { LayeredLinkComponent } from './layered-nav-link.component';
import { LayeredNavTitleComponent } from './layered-nav-title.component';

@Component({
  selector: 'app-layered-navbar',
  templateUrl: './layered-navbar.component.html',
  styleUrls: ['./layered-navbar.component.scss']
})
export class LayeredNavbarComponent extends BaseComponent implements AfterViewChecked, AfterContentInit, OnDestroy, OnInit {

  constructor(private env: EnvironmentService) {
    super();
    // we subscribe to all of the item clicks. When they emit, the clickResetter
    // observable is fired. Here, we subscribe to that with a 500ms debounce where
    // we set the click to pending for 500ms.
    this.clickResetter.pipe(
      takeUntil(this.ngUnsubscribe),
      tap(item => {
        this.clickPending = true;

        // collapse the menu if the item has no sub menu (only applies in the CSS in the mobile view)
        if (item instanceof (LayeredLinkComponent) && !(item as LayeredLinkComponent).hasSubmenu || item instanceof (LayeredNavTitleComponent)) {
          this.collapseAll();
        }
      }), debounceTime(250),
      tap(() => {
        this.suppressionHandle.suppress = true;
      }),
      debounceTime(250)).subscribe(() => {
        this.clickPending = false;
        this.suppressionHandle.suppress = false;
      });
  }

  @Input() header: string;
  toggleOpen: boolean = false;
  clickPending: boolean = false;
  isCMS = false;
  private itemSubscription: Subscription;
  private childSubscriptions: Subscription[] = [];
  private clickResetter = new Subject<LayeredLinkComponent | LayeredNavTitleComponent>();
  private suppressionHandle: any = { supress: false };

  ngOnInit() {
    this.isCMS = this.env.isCMS;
  }


  ngAfterViewChecked(): void {
    this.onResize(null);
  }

  @ViewChildren('menuItem') menuLinks: QueryList<ElementRef<HTMLAnchorElement>>;
  @ContentChildren(LayeredLinkComponent) headerLinks: QueryList<LayeredLinkComponent>;

  ngAfterContentInit() {
    // detect changes on the subscriptions
    this.itemSubscription = this.headerLinks.changes.subscribe((links: QueryList<LayeredLinkComponent>) => {
      this.subscribeToClicks(links);
    });

    this.subscribeToClicks(this.headerLinks);
  }

  ngOnDestroy() {
    this.itemSubscription.unsubscribe();
    this.childSubscriptions.forEach(s => s.unsubscribe());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    for (const menu of this.headerLinks) {
      if (!menu.el || !menu.el.nativeElement) {
        continue;
      }
      const left = menu.el.nativeElement.offsetLeft;
      const submenu = menu.el.nativeElement.querySelector('ul') as HTMLUListElement;
      if (submenu) {
        submenu.style.paddingLeft = `${left}px`;
      }
    }
  }

  collapseAll() {
    this.toggleOpen = false;
    for (const sub of this.headerLinks) {
      sub.collapseAll();
    }
  }

  private subscribeToClicks(links: QueryList<LayeredLinkComponent>) {
    this.suppressionHandle.suppress = false;
    this.childSubscriptions.forEach(s => s.unsubscribe());

    this.suppressionHandle = { suppress: false };
    links.forEach(item => {
      item.suppressionHandle = this.suppressionHandle;
      this.childSubscriptions.push(item.click.subscribe(() => this.clickResetter.next(item)));
      this.childSubscriptions.push(item.childClick.subscribe(itm => this.clickResetter.next(itm)));
    });
  }
}
