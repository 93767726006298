import { Component, Input } from '@angular/core';
import { NavItem, NavItemType } from '@pepconnect/models/ui/nav-item.model';

@Component({
  selector: 'app-nav-item',
  template: `
      <app-nav-link [item]="item" *ngIf="isNavLink(item)"></app-nav-link>
      <app-nav-dropdown [item]="item" *ngIf="isNavDropdown(item)"></app-nav-dropdown>
  `
})
export class NavItemComponent {
  @Input() item: NavItem

  constructor() { }

  isNavLink(item: NavItem) {
    return item.type === NavItemType.link;
  }

  isNavDropdown(item: NavItem) {
    return item.type === NavItemType.dropdown;
  }

}
