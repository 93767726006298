<uimc-country-language-selector-v2 #marcomComponent id="country-lang-selector" *ngIf="show"
                                slot="meta-menu"
                                [buttonLabel]="'LABEL_UPDATESETTINGS' | translate"
                                [countryTitle]="'LABEL_SELECT_COUNTRY_V2' | translate"
                                [languageTitle]="'LABEL_SELECT_LANGUAGE' | translate"
                                [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate"
                                [labelCountryChange]="'COUNTRY_DIFFERENT_LOCATION' | translate"
                                [selectedCountry]="authState?.user?.country?.code"
                                [selectedLanguage]="authState?.user?.locale?.locale"
                                [disableCountry]="authState?.isEmployee"
                                (changed)="onCountryLanguageChanged($event)"
                                >
    <option country *ngFor="let c of metaState?.countries ?? []" [value]="c.code">{{c.name}}</option>
    <option language *ngFor="let l of metaState?.locales ?? []" [value]="l.locale">{{l.name}}</option>
</uimc-country-language-selector-v2>
