// Available locales on PEP
export enum Locales {
    English = 'en-us',
    Spanish = 'es-es',
    German = 'de-de',
    French = 'fr-fr',
    Italian = 'it-it',
    Polish = 'pl-pl',
    Portuguese = 'pt-br',
    Indonesian = 'id-id',
    Japanese = 'ja-jp',
    Chinese = 'zh-cn',
    Korean = 'ko-kr',
    Russian = 'ru-ru',
    Turkish = 'tr-tr',
    Vietnam = 'vi-vn'
}