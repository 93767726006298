import { Injectable } from '@angular/core';
import { BuildInfo } from '@pepconnect/models/general/metadata.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  constructor(private api: ApiService) { }

  getVersion() {
    return this.api.get<BuildInfo>('webhooks/build')
  }

}

