import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Message } from '@pepconnect/models/message-center/messages.model';
import { MessageCenterService } from '@pepconnect/services/message-center.service';
import { NotificationService } from '@pepconnect/services/notification.service';
import { BaseComponent } from '@pepconnect/shared/base/base-component/base.component';
import { AppState } from '@pepconnect/state/index';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { distinct, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent implements OnInit {

  // conditions to show messages:
  //   1. User first comes to the page
  //   2. User logs in
  $authState = this.store.pipe(select(selectAuthState));
  private _messagesSeen: boolean

  constructor(private messageCenterSvc: MessageCenterService, private toastrSvc: NotificationService, private store: Store<AppState>, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe), distinct(authState => authState.isLoggedIn)).subscribe(authState => {
      // If page is first loading or user is logged in, show the messages
      if (!this._messagesSeen || authState.isLoggedIn) {
        this.showSiteMessages(authState);
        this._messagesSeen = true;
      }
    });
  }


  // display site-messages
  showSiteMessages(authState: AuthState) {
    if (!authState.user || !authState.user.locale) {
      return;
    }

    this.messageCenterSvc.getUnreadCount(authState.user.locale.id).pipe(take(1)).subscribe(count => {
      if (count == 0) {
        return;
      }

      this.messageCenterSvc.getSiteMessages(authState.user.locale.id).pipe(take(1)).subscribe((messages: Message[]) => {
        for (const message of messages) {

          const toastrMessage = this.toastrSvc.showWarning(message.text, '', { closeButton: true, progressBar: true, timeOut: 15000, extendedTimeOut: 15000 });
          toastrMessage.onTap.subscribe(_ => {
            this.router.navigateByUrl(`/${authState.user.locale.locale}/messagecenter/site/${message.id}`);

            this.toastrSvc.clearMessage(toastrMessage.toastId);
          });
        }
      });
    });

  }

}
