import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state/index';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent extends BaseSmartComponent {
  locale: string;
  constructor(protected store: Store<AppState>) {
    super(store);
  }

  setLocale(): void {
    this.$userLocale.pipe(takeUntil(this.ngUnsubscribe)).subscribe(l => {
      this.locale = l.locale;
    });
  }
}
