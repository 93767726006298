import { Injectable, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '@pepconnect/constants/constants';
import { BaseComponent } from '@pepconnect/shared/base';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetadataTranslateService extends BaseComponent implements OnDestroy {

  constructor(
    private titleService: Title,
    private meta: Meta,
    private translate: TranslateService
  ) {
    super();
  }
  /**
   *  Set the HTML metadata for both the title and description.  Will only update if actual truthy values are passed in.
   *  Title/description must already be translated.
   * @param title a string or array.  If its an array, they array items will be joined together with a title seperator.
   * @param description
   */
  async setHTMLMetadata(title: string | string[], description: string) {
    if (title) await this.setPageTitle(title);
    if (description) this.setPageDescription(description);
  }

  /**
 *  Set the page title in the browser tab and metadata.  Title can be a untranslated locale key.
 *  If your title is already translated, use setPageTitle
 *  @param pageTitle a string or array.  If its an array, they array items will be joined together with a title seperator.
 */
  async setPageTitleWithTranslation(untranslatedPageTitle: string) {
    const pageTitle = await firstValueFrom<string>(this.translate.get(untranslatedPageTitle));
    await this.setPageTitle(pageTitle)

  }

  /**
   *  Set the page title in the browser tab and metadata.  Title must already been translated, no locale keys.
   *  @param pageTitle a string or array.  If its an array, they array items will be joined together with a title seperator.
   */
  async setPageTitle(pageTitle: string | string[]) {
    // if the pageTitle prop is a string, and set it.   Easy.
    if (typeof pageTitle === 'string') {
      //strip any html tags
      this.titleService.setTitle(pageTitle.replace(/(<([^>]+)>)/gi, ""));
      // if the pageTitle prop is not a string, we assume its an array of strings to formulate into a string joined with the title seperator.
    } else {
      let transformedTitle = '';
      // Loop through the segments, get the translations and append it to the transformed title variable.
      // We also check to see if its the last item of the array.If its not, append the seperator, else skip.
      for (const [index, segment] of pageTitle.entries()) {
        transformedTitle += `${segment} ${index !==  pageTitle.length -1 ? constants.TITLE_SEPERATOR : ''} `;
      }
      this.titleService.setTitle(transformedTitle);
    }
  }

  /**
   *  Set the HTML description for the page
   *  @param pageDescription
   */
  setPageDescription(pageDescription: string) {
    this.meta.updateTag({ name: 'description', content: pageDescription })
  }
}

