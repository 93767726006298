<!-- New nav-bar -->
<app-layered-navbar [header]="'ABOUT_PEPCONNECT_TITLE' | translate" *ngIf="showNewNav && !($useMinimalLayout | async) && !($useCheckoutLayout | async)">
    <app-layered-nav-link *ngFor="let item of navItems" (click)="dispatch(item)" [active]="item.isActive">
        {{ item.label | translate}}

        <!-- second layer menu -->
        <app-layered-nav-title *ngIf="item.children?.length" [clickable]="isClickable(item.titleItem)"
            (click)="dispatch(item.titleItem)" [active]="!!item.titleItem?.isActive">
            {{ item.label | translate}}
        </app-layered-nav-title>

        <app-layered-nav-link *ngFor="let child of item.children" [active]="child.isActive" (click)="dispatch(child)"
            [clickable]="isClickable(child)">
            {{ child.label | translate }}

            <!-- third layer menu -->
            <app-layered-nav-title *ngIf="child.children?.length" [clickable]="isClickable(child.titleItem)"
                (click)="dispatch(child.titleItem)" [active]="!!child.titleItem?.isActive">
                {{ child.label | translate}}
            </app-layered-nav-title>

            <app-layered-nav-link *ngFor="let grandchild of child.children" [active]="grandchild.isActive"
                [clickable]="isClickable(grandchild)" (click)="dispatch(grandchild)">
                {{ grandchild.label | translate }}
            </app-layered-nav-link>
        </app-layered-nav-link>
        <!-- add additional content for nav-items like this -->
        <div nav-content *ngIf="!!item.content" [innerHTML]="item.content | safe: 'html'"></div>
    </app-layered-nav-link>
</app-layered-navbar>

<!-- Current nav-bar in PRODUCTION -->
<div class="navigation-bar" *ngIf="!showNewNav && !($useMinimalLayout | async) && !($useCheckoutLayout | async)" data-ste-chapter="navigation">
    <div class="navigation-bar__container">
        <div class="navigation-bar__panel">
            <div class="navigation-bar__wrapper">
                <button class="navigation-bar__toggle-button" appMcMobileMenu>
                    <i class="icon-burger"></i>
                </button>
                <div class="navigation-bar__app-identifier">{{'ABOUT_PEPCONNECT_TITLE' | translate }}</div>
            </div>
        </div>
        <div>
            <ul class="bare-list navigation-bar__list">
                <app-nav-item *ngFor="let item of navItems" [item]="item"></app-nav-item>
                <li *ngIf="isCMS" class="cms-header hidden-md font-size-xxsmall color-red-dark">{{ 'CMS_HEADER' | translate }}</li>
            </ul>
        </div>
    </div>
</div>
