import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChildFn } from '@angular/router';
import { of } from 'rxjs';
import { AppState } from '@pepconnect/state/index';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { Store } from '@ngrx/store';
import { switchMap, first } from 'rxjs/operators';
import { constants } from '@pepconnect/constants';
import { inject } from '@angular/core';

export const canActivateStoreChild: CanActivateChildFn = 
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const store = inject(Store<AppState>);
    const router = inject(Router);
    return store.select(selectAuthState).pipe(first(authState => authState.isInitialized), switchMap(authState => {
      const storeEnabledFlag = authState.user?.flags[constants.FLAG_STORE] ?? false;
      if (storeEnabledFlag) {
        return of(true);
      } else {
        router.navigate(['/denied']);
        return of(false);
      }
    }));
  }
