import { Component, inject } from '@angular/core';
import { constants } from '@pepconnect/constants';
import { LoginSource } from '@pepconnect/dtos/account/login-request.dto';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { take } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseV2SmartComponent {
  private readonly environmentService = inject(EnvironmentService);
  showLogin: boolean = true;
  source = LoginSource.LoginPage;
  hidLogin: boolean = false;

  ngOnInit(): void {
    this.checkFeatureFlags([constants.FLAG_PEP_HID_LOGIN]).pipe(take(1))
      .subscribe((flags) => {
        if (this.environmentService.isCMS) {
          this.showLogin = false;
        }
        this.hidLogin = flags[constants.FLAG_PEP_HID_LOGIN];
      });
  }

  afterLogin() {
    //noop
  }

}
