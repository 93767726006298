
@if (loading && !hasError) {
    <app-spinner class="col-md-12" [centered]="true"></app-spinner>
}
@if (hasError) {
    <div class="display-flex justify-content-center">
        <app-alert indicator="error" class="col-sm-6">
            {{ 'SEARCH_ERROR' | translate }}
        </app-alert>
    </div>
}
<div *ngIf="results && !loading">
    <div class="container">
        <h1 class="color-orange" [innerText]="headerLabel"></h1>
    </div>

    <div class="search-container" id="search-container">
        <!-- Show spinner until data is available -->
        <app-spinner *ngIf="!results" class="col-md-12" [centered]="true"></app-spinner>

        <div class="row" *ngIf="results as res">
            <input #showFilterCheckbox id="show-filter-checkbox"
                   (change)="applyNoscrollToBody(showFilterCheckbox.checked)" type="checkbox" class="invisible-checkbox">
            <div class="expandable-search col-md-3">
                <div class="search-filter-section">
                    <label for="show-filter-checkbox" class="close-button"><i class="icon-close"></i></label>
                    <!-- Filters -->
                    <app-search-filter [showSearchTextBox]="false" [searchProvider]="searchSvc"
                                       [objectType]="objectType">
                    </app-search-filter>
                </div>
                <div class="show-results-button">
                    <uimc-button [primary]="true"
                                 (click)="showFilterCheckbox.checked = false; applyNoscrollToBody(false);">
                        {{'LABEL_SHOW_RESULTS' | translate}}
                    </uimc-button>
                </div>
            </div>
            <div class="result-card-section">
                <div class="row">
                    <div class="col-md-3">
                        <label class="filter-label-button" for="show-filter-checkbox">
                            <span class="button button--secondary"><i class="icon-filter"></i>Filters</span>
                        </label>
                    </div>
                </div>
                <!-- Cards -->
                <app-search-cards [searchResults]="res.results" [searchLogID]="searchLogID"></app-search-cards>
                <uimc-button id="subscription-show-button" *ngIf="resultListIsIncomplete(res)" class="show-button"
                             type="link" icon="plus" (click)="getMoreResults()">{{'LABEL_SHOW_MORE' | translate}}</uimc-button>
            </div>
        </div>
    </div>
</div>
