<page-container id="not-found">
    <div class="container">
        <app-headline [headline]="'404_TITLE' | translate"></app-headline>
        <div class="container">
            <p class="margin-bottom-1">{{ '404_TEXT' | translate }}</p>
            <a [routerLink]="['/', locale]" class="button button--primary">
                {{ 'BUTTON_HOME' | translate }}
            </a>
        </div>
    </div>
</page-container>