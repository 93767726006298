import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeEsMx from '@angular/common/locales/es-MX';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeKo from '@angular/common/locales/ko';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localePtPt from '@angular/common/locales/pt-PT';
import localeJa from '@angular/common/locales/ja';
import localeRu from '@angular/common/locales/ru';
import localeTr from '@angular/common/locales/tr';
import localeId from '@angular/common/locales/id';
import localeVi from '@angular/common/locales/vi';
import localeZh from '@angular/common/locales/zh';
import { TranslateService } from '@ngx-translate/core';
import { LOCALE_ID, StaticClassProvider } from '@angular/core';

export function registerSupportedPEPLocales() {
  registerLocaleData(localeDe, 'de-de');
  registerLocaleData(localeEs, 'es-es');
  registerLocaleData(localeEsMx, 'es-mx');
  registerLocaleData(localeFr, 'fr-fr');
  registerLocaleData(localeIt, 'it-it');
  registerLocaleData(localeJa, 'ja-jp');
  registerLocaleData(localeKo, 'ko-kr');
  registerLocaleData(localePl, 'pl-pl');
  registerLocaleData(localePt, 'pt-br');
  registerLocaleData(localePtPt, 'pt-pt');
  registerLocaleData(localeRu, 'ru-ru');
  registerLocaleData(localeTr, 'tr-tr');
  registerLocaleData(localeId, 'id-id');
  registerLocaleData(localeVi, 'vi-vn');
  registerLocaleData(localeZh, 'zh-cn');
}

export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
      super();
  }

  toString() {
      return this.service.currentLang;
  }
}

export const LOCALE_PROVIDER: StaticClassProvider = {
  provide: LOCALE_ID,
  useClass: DynamicLocaleId,
  deps: [TranslateService]
};


