import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SsoType } from '@pepconnect/features/sso/enums/sso-type';
import { SsoRequest } from '@pepconnect/features/sso/model/sso-request';
import { PepSsoService } from '@pepconnect/features/sso/services/pep-sso.service';
import { SsoLaunchService } from '@pepconnect/features/sso/services/sso-launch.service';
import { UserService } from '@pepconnect/services/user.service';
import { AppState } from '@pepconnect/state';
import { setIsImpersonating } from '@pepconnect/state/auth/auth.actions';
import { of } from 'rxjs';
import { first, switchMap, take } from 'rxjs/operators';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';

/**
 * This is where SSO from PEPconnect to itself happens. Examples of this are
 * using the log-in-as functionality in the admin or redirecting a user who
 * is on the incorrect country-domain from one to the other and keeping them
 * logged in throughout.
 * */
@Component({
  selector: 'app-pep-sso-login',
  templateUrl: './pep-sso-login.component.html'
})
export class PepSsoLoginComponent extends BaseSmartComponent implements OnInit {
  private encryptedTicket: string;
  
  constructor(
    protected store: Store<AppState>,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private ssoService: PepSsoService,
    private launchService: SsoLaunchService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.encryptedTicket = this.route.snapshot.queryParamMap.get('tp') ?? '';

    if (this.encryptedTicket) {
      // Make sure we're initialized before we do anything 
      this.$authState.pipe(
        first(authState => this.initializationGuard(authState)),
        switchMap(state => {
          if (state.isLoggedIn) {
            return this.userService.logout(false, false).pipe(take(1), switchMap(() => of(state)));
          } else {
            return of(state);
          }
        })
      ).subscribe(() => this.processLaunch());
    } else {
      this.router.navigate(['404']);
    }
  }

  processLaunch() {
    const ssoRequest: SsoRequest = {
      toDecrypt: this.encryptedTicket,
      isCert: false
    };
    this.ssoService.getSsoResponse(ssoRequest, SsoType.PEP)
      .pipe(take(1),
        switchMap(response => {
          if (response.isImpersonating) {
            this.store.dispatch(setIsImpersonating({ isImpersonating: response.isImpersonating }));
          }
          return this.launchService.login(response, this.router)
            .pipe(take(1),
              switchMap(user => this.launchService.launch(response, user, this.router))
            );
        })
      ).subscribe();
  }

}
