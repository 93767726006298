<div class="application-bar application-bar--compact" data-ste-chapter="app-bar">
    <div class="hide-application-bar-for-search">

    </div>
    <div class="application-bar__container">
        <div class="application-bar__logo">
            <a href='#' (click)="confirmRedirect()" appPreventDefault aria-label="siemens healthineers logo" class="logo logo--compact"></a>
        </div>
        <div>
            <div class="application-bar__app-identifier">{{appIdentifier | translate }}</div>
        </div>
        <div class="search-bar-section" *ngIf="($suppressSearchBar | async) === false && ($useCheckoutLayout | async) === false">
            <div class="app-bar-search">
                <label aria-hidden="true">
                    <input id="search-bar-active" type="checkbox" [checked]="menuExpanded" class="search-bar-active" />
                    <i class="icon-search"></i>
                    <app-search-bar (searchFocusChange)="onSearchbarFocus($event)" [hide-border]="hideBorder" [theme]="searchBarTheme"></app-search-bar>
                </label>
            </div>
        </div>
        <div class="checkout-label-section" *ngIf="($useCheckoutLayout | async) === true">
            <h1>
                {{'CHECKOUT_PAGE_TITLE' | translate}}
            </h1>
        </div>
        <div class="application-bar__additional-functions">
            <span class="icon-locked" *ngIf="($useCheckoutLayout | async) === true"></span>
        </div>
        <div class="application-bar__meta-menu">
            <div class="meta-menu js-meta-menu">
                <app-country-lang-selector *ngIf="($useCheckoutLayout | async) === false"></app-country-lang-selector>
                <div class="dropdown dropdown--right js-dropdown">
                    <button type="button"
                            class="button button--control button--off-content dropdown__toggle meta-menu__toggle-button" aria-hidden="true">
                        <span class="icon-dots-ellipsis-horizontal"></span>
                    </button>
                    <div class="dropdown__panel">
                        <ul class="menu meta-menu__hidden-list"></ul>
                    </div>
                </div>
            </div>
        </div>
        <app-user-identifier class="application-bar__user-identifier"
                             *ngIf="($useMinimalLayout | async) === false && ($useCheckoutLayout | async) === false && ($isInitialized | async)"></app-user-identifier>
    </div>
</div>

