import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { forms } from '@pepconnect/constants/forms';
import { LoginRequest } from '@pepconnect/dtos/account/login-request.dto';
import { SetPasswordRequest } from '@pepconnect/dtos/account/password-request.dto';
import { OnToken } from '@pepconnect/interfaces/itoken.interface';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { CheckResetCodeResponse } from '@pepconnect/models/user/check-reset-code-response';
import { UserService } from '@pepconnect/services/user.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { passwordMatchValidator } from '@pepconnect/shared/validators/password-match-validator.directive';
import { passwordValidator } from '@pepconnect/shared/validators/password-validator.directive';
import { AppState } from '@pepconnect/state/index';
import { combineLatest, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent extends BaseSmartComponent implements OnInit, OnToken {
  extractedUser: CheckResetCodeResponse;
  submitted: boolean;
  resetPasswordForm: FormGroup;
  loading = false;
  errorMessage: string;
  hasError: boolean;
  showErrorMessage: string;
  isGenerated = false;
  locale: string;
  token: string;

  constructor(protected store: Store<AppState>,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder) { super(store); }

  ngOnInit(): void {
    combineLatest([this.$authState, this.$routeData]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([authState, routeData]) => {
      this.locale = authState.user.locale.locale;
      this.extractedUser = routeData.content;
      this.isGenerated = this.extractedUser.isGenerated;

      this.setUpForm(this.extractedUser, this.isGenerated);
      this.setUserDetails(this.extractedUser);
    });
  }

  setUpForm(user: CheckResetCodeResponse, generated: boolean) {
    this.resetPasswordForm = this.fb.nonNullable.group({
      firstname: this.fb.control(user.firstname),
      lastname: this.fb.control(user.lastname),
      email: this.fb.control(user.email),
      code: this.fb.control('', Validators.required),
      password: this.fb.control('', { validators: [Validators.required, Validators.minLength(forms.VALIDATOR_PASSWORD_MIN_LENGTH), passwordValidator()] }),
      confirmPassword: this.fb.control('', { validators: [Validators.required] }),
      optIn: this.fb.control(user.optIn, generated ? [Validators.required] : []),
      optInFeed: this.fb.control(user.optInFeed, generated ? [Validators.required] : []),
      termsAgree: this.fb.control(false, generated ? [Validators.requiredTrue] : []),
      privacyAgree: this.fb.control(false, generated ? [Validators.requiredTrue] : [])
    }, { validators: passwordMatchValidator() });
  }

  setUserDetails(user: CheckResetCodeResponse) {
    let { firstname, lastname, email } = user;
    this.resetPasswordForm.patchValue({
      firstname, lastname, email
    });
    // update form validation status
    this.resetPasswordForm.updateValueAndValidity();

    this.resetPasswordForm.controls.firstname.disable();
    this.resetPasswordForm.controls.lastname.disable();
    this.resetPasswordForm.controls.email.disable();
  }

  get f() {
    return this.resetPasswordForm?.controls;
  }

  onTermsAgree($event) {
    this.f.termsAgree.setValue($event);
  }
  onPrivacyAgree($event) {
    this.f.privacyAgree.setValue($event);
  }

  onToken($event: string) {
    this.token = $event;
    // Because this is an external callback, angular doesn't always see the changes
    this.cd.detectChanges();
  }

  async resetPassword() {
    if (!this.resetPasswordForm?.valid || !this.token) {
      return;
    }

    const token = this.token;
    const request: SetPasswordRequest = {
      confirmationCode: this.resetPasswordForm.get('code').value,
      firstname: this.resetPasswordForm.get('firstname').value,
      lastname: this.resetPasswordForm.get('lastname').value,
      email: this.resetPasswordForm.get('email').value,
      password: this.resetPasswordForm.get('password').value,
      resetCode: this.extractedUser.resetCode,
      optIn: (this.isGenerated ? this.resetPasswordForm.get('optIn').value : undefined),
      optInFeed: (this.isGenerated ? this.resetPasswordForm.get('optInFeed').value : undefined),
      termsAgree: (this.isGenerated ? this.resetPasswordForm.get('termsAgree').value : undefined),
      privacyAgree: (this.isGenerated ? this.resetPasswordForm.get('privacyAgree').value : undefined),
      checkCode: token
    }

    this.userService.setPassword(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      // success
      next: (response) => {
        const loginRequest: LoginRequest = {
          email: this.resetPasswordForm.get('email').value,
          password: this.resetPasswordForm.get('password').value
        }
        this.userService.login(loginRequest).pipe(take(1)).subscribe({
          next: () => { /* don't interrupt success side effects */ }, error: (err) => this.errorMessage = err.error_description
        });
      },
      // failure
      error: (error: UnserializedResponseMessage) => {
        {
          this.hasError = true;
          this.showErrorMessage = error.Message;
        }
      }
    })
  }
}
