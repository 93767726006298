<app-page-container id="forgot-password-container">
    <app-narrow-wrap>
        <app-block-centered>
            <app-headline [headline]="'FORGOT_PASSWORD' | translate">
            </app-headline>
            <app-spinner *ngIf="loading"></app-spinner>
        </app-block-centered>

        <app-form-centered *ngIf="!submitted" [formGroup]="forgotPasswordForm" (onSubmit)="forgotPassword()">
            <p>{{ 'FORGOT_PASSWORD_MESSAGE' | translate }}</p>
            <app-form-group>
                <app-textfield-group>
                    <label class="textfield__label" for="forgot-password-email">{{ 'EMAIL_ADDRESS' | translate }}</label>
                    <input type="email" class="textfield__input" id="forgot-password-email" name="email"
                        formControlName="email" autocomplete="email" email>
                    <app-validation-message [control]="f.email" [showOnSubmitted]="true" error="required"
                        message="REQUIRED_FIELD_EMAIL"></app-validation-message>
                    <app-validation-message [control]="f.email" error="email" message="FIELD_CHECK_EMAIL">
                    </app-validation-message>
                </app-textfield-group>
            </app-form-group>
            <app-recaptcha-container (response)="onToken($event)" action="forgot" class="display-flex justify-content-start justify-content-lg-end"></app-recaptcha-container>
            <app-form-buttons>
                <button class="button button--primary" type="submit" [disabled]="submitted || !token">{{'SUBMIT_BUTTON' | translate }}</button>
            </app-form-buttons>
        </app-form-centered>
        <app-block-centered *ngIf="pageContent | async">
            <p [innerHTML]="pageContent | async"></p>
            <p [innerHTML]="supportMessage | async"></p>
            <div class="button-group">
                <uimc-button [primary]="true" (click)="resend()">{{'BUTTON_TCMAN_RESEND_RESULT' | translate}}
                </uimc-button>
                <uimc-button [primary]="true" (click)="supportPage()">{{'SUPPORT' | translate}}
                </uimc-button>
            </div>
            @if(showRecaptcha) {
                <app-recaptcha-container (response)="onToken($event, forgotPassword.bind(this))" action="forgot" class="display-flex justify-content-start justify-content-lg-end"></app-recaptcha-container>
            }
        </app-block-centered>
    </app-narrow-wrap>
</app-page-container>
