import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RegistrationTypes } from '@pepconnect/features/account/register/register-constants';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state';
import { take } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseSmartComponent {

  protected legacyRegistration: boolean;

  constructor(protected store: Store<AppState>, private router: Router) {
    super(store);
  }

  postRegister(event: RegistrationTypes) {
    this.$userLocale.pipe(take(1)).subscribe(locale => {
      switch (event) {
        case RegistrationTypes.Legacy:
          this.router.navigate(['/', locale.locale, 'confirm']);
          break;
        case RegistrationTypes.NewSiemensHID:
          this.router.navigate(['/', locale.locale, 'next-step']);
          break;
        case RegistrationTypes.SiemensHIDAlreadyExisted:
          this.router.navigate(['/', locale.locale, 'account-exists']);
          break;
      }
    });
  }
}
