import { Component, OnInit, inject } from '@angular/core';
import { BaseComponent } from '@pepconnect/shared/base/base-component/base.component';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { UserService } from '@pepconnect/services/user.service';
import { first, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { CmsRequest } from '@pepconnect/features/sso/model/cms-request';
import { ssoConstants } from '@pepconnect/features/sso/model/sso-constants';
import { LocaleService } from '@pepconnect/services/locale.service';
import { setUserCountry, setUserLocale } from '@pepconnect/state/auth/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from '@pepconnect/models/localization/locale.model';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { CmsSsoService } from '@pepconnect/features/sso/services/cms-sso.service';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { SsoLaunchService } from '../../services/sso-launch.service';

@Component({
  selector: 'app-cms-sso-login',
  template: ''
})
export class CmsSsoLoginComponent extends BaseComponent implements OnInit {
  private $state = this.store.select(selectAuthState);
  private cmsRequest: CmsRequest;
  private ssoConstants = ssoConstants;
  private readonly launchService = inject(SsoLaunchService);

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private ssoService: CmsSsoService,
    private translateService: TranslateService,
    private envService: EnvironmentService,
    private localeService: LocaleService
  ) {
    super();
  }

  ngOnInit(): void {
    this.cmsRequest = this.populateRequest(this.route.snapshot.queryParamMap);

    // Make sure we're initialized before we do anything
    this.$state.pipe(
      first(state => state.isInitialized),
      switchMap(state => {
        if (state.isLoggedIn && this.shouldBeLoggedOut()) {
          return this.userService.logout(false, false).pipe(take(1), switchMap(() => of(state)));
        } else {
          return of(state);
        }
      })
    ).subscribe(state => this.processLaunch(state));
  }

  processLaunch(authState: AuthState) {
    // clear out the cms local storage keys
    localStorage.removeItem(this.ssoConstants.CMS_LS_USERID);
    localStorage.removeItem(this.ssoConstants.CMS_LS_LOCALE);

    // gets the decoded response
    this.ssoService.getCmsLoginResponse({ toDecrypt: this.cmsRequest.encryptedToken, isCert: false })
      .pipe(take(1))
      .subscribe(user => {
        // Set the appropriate session item based on which environment we're in
        if (this.envService.isInlineTranslate) {
          sessionStorage.setItem(this.ssoConstants.CMS_LS_LOG_INLINE_KEY, `${true}`);
        } else {
          sessionStorage.setItem(this.ssoConstants.CMS_LS_LOG_KEY, `${true}`);
        }
        localStorage.setItem(this.ssoConstants.CMS_LS_USERID, `${user.id}`);

        // if we're translating and have passed in a locale the user wants to be translating, shift to that locale
        if (user.canTranslate && this.cmsRequest.localeId && user.locales.includes(this.cmsRequest.localeId)) {
          this.localeService.getById(this.cmsRequest.localeId)
            .pipe(take(1))
            .subscribe(l => this.finishProcessing(l, authState));
        } else if (user.ssoResponse) { // otherwise set the site locale to the CMS user passed in.
          this.launchService.login(user.ssoResponse, this.router).pipe(take(1),
            switchMap(() => this.localeService.getByCode(user.userLocale))
          ).subscribe((l) => this.finishProcessing(l, authState));
        } else {
          if (user.country) {
            this.store.dispatch(setUserCountry({ country: user.country }));
          }
          this.localeService.getByCode(user.userLocale)
            .pipe(take(1))
            .subscribe(l => this.finishProcessing(l, authState));
        }
      });

  }

  /**
   * Once we have the locale we're supposed to be in, finalize the users settings
   * and send them off
   * @param l Either the users locale as passed back from the API or the locale they were trying to translate strings from.
   */
  finishProcessing(l: Locale, authState: AuthState) {
    // Store the CMS locale that we're here in.
    localStorage.setItem(this.ssoConstants.CMS_LS_LOCALE, `${l.id}`);
    // Update the user to the language passed in.
    this.localeService.setStoredLocale(l); // sets locale storage
    this.translateService.use(l.locale); // tells translate what locale to use
    this.store.dispatch(setUserLocale({ locale: l })); // updates the store to the new locale
    // redirect the user to wherever they're supposed to be.
    this.redirectUser(l.locale);
  }

  /**
   * Get the redirect path for the user based on why they're here and send them there
   * @param locale the locale the user is under
   */
  redirectUser(locale: string) {
    // Get the redirect path for the user based on why they're here
    let redirectPath = this.cmsRequest.redirect ?? `/${locale}`;

    if (this.cmsRequest.cm) {
      if (this.cmsRequest.page) {
        const page = this.cmsRequest.page.replace('.aspx', '').replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
        redirectPath = `/${locale}/support?path=${page}&source=cm`;
      } else {
        redirectPath = `/${locale}/support?source=cm`;
      }
    }
    this.router.navigateByUrl(redirectPath);
  }

  /**
   * Decode the query string params into an object
   * @param rt
   */
  populateRequest(rt: ParamMap): CmsRequest {
    return {
      encryptedToken: rt.get('ts'),
      localeId: parseInt(rt.get('lcid')),
      redirect: rt.get('r'),
      cm: rt.get('cm'),
      page: rt.get('page')
    };
  }

  /**
   *  If coming from cm and being routed to the support page, don't log out.
   * */
  shouldBeLoggedOut(): boolean {
    if (this.cmsRequest.cm && !this.cmsRequest.redirect) {
      return false;
    }
    return true;
  }

}
