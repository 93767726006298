import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants';
import { LoginRequest, LoginSource } from '@pepconnect/dtos/account/login-request.dto';
import { TermTypes } from '@pepconnect/enums/term-types.enum';
import { User } from '@pepconnect/models/user/user.model';
import { UserService } from '@pepconnect/services/user.service';
import { BaseComponent } from '@pepconnect/shared/base';
import { ControlsOf } from '@pepconnect/shared/types/controls-of';
import { selectUser } from '@pepconnect/state/auth/auth.selector';
import { AppState } from '@pepconnect/state/index';
import { take, takeUntil } from 'rxjs/operators';

type LoginForm = ControlsOf<Pick<LoginRequest, "email" | "password" | "source">>;

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent extends BaseComponent implements OnInit {

  private userService: UserService = inject(UserService);
  private store: Store<AppState> = inject(Store);
  private fb: FormBuilder = inject(FormBuilder);

  loginForm: FormGroup<LoginForm>;
  errorMessage: string = null;
  $user = this.store.pipe(select(selectUser));
  user: User;
  loading: boolean;
  termType = TermTypes.PrivacyPolicy;
  showTermsModal = false;

  @Output() loginSuccess = new EventEmitter<User>();
  @Input() loginSource = LoginSource.Unknown;
  @Input() showRegisterLink = true;

  ngOnInit(): void {
    this.setupForm();
    this.setState();
  }

  setState() {
    this.$user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.user = user;
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  setupForm() {
    this.loginForm = this.fb.nonNullable.group<LoginForm>({
      email: this.fb.control<string>('', Validators.required),
      password: this.fb.control<string>('', Validators.required),
      source: this.fb.control<LoginSource>(this.loginSource)
    });
  }

  getIsInvalid() {
    return this.loginForm.invalid;
  }


  onSubmit() {
    this.loading = true;
    this.errorMessage = null;
    // make sure a value was provided - if it wasn't we mark the control as dirty show a validation icon shows
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }

    this.userService.login(this.loginForm.value as LoginRequest)
      .pipe(take(1))
      .subscribe({
        next: u => {
          this.loginSuccess.emit(u);
        },
        error: err => this.showErrorMessage(err)
      });
  }

  /**
   * Show an error message if the error is not handled by the user service.
   * @param err
   */
  showErrorMessage(err) {
    if (err.unhandled) {
      this.errorMessage = err.error_description;
      this.loading = false;
    }
  }

  get forgotPasswordLink(): string {
    return `/${this.user.locale.locale}/${constants.FORGOT_PASSWORD}`;
  }

  get registerLink(): string {
    return `/${this.user.locale.locale}/${constants.REGISTER}`;
  }
}
