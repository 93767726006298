<div class="row" *ngIf="errorMessage">
    <uimc-alert title="" indicator="error" submit-button-label="" cancel-button-label="" class="col-12 margin-bottom-1">
        {{errorMessage | translate }}
    </uimc-alert>
</div>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="login-form">
    <app-form-group>
        <app-textfield-group id="email-group">
            <input id="email-input" type="email" class="textfield__input" autocomplete="username"
                   formControlName="email" name="email" />
            <label id="email-label" for="email-input" class="textfield__label">{{ 'LABEL_EMAIL' | translate }}</label>
            <app-validation-message [control]="f.email" error="required" message="REQUIRED_FIELD_EMAIL" [showOnSubmitted]="true"></app-validation-message>
        </app-textfield-group>
    </app-form-group>
    <app-form-group>
        <app-textfield-group id="password-group">
            <input id="password-input" type="password" class="textfield__input" autocomplete="off"
                   formControlName="password" name="password" />
            <label id="password-label" for="password-input" class="textfield__label"> {{ 'CHANGE_PASSWORD_TITLE' | translate }} </label>
            <app-validation-message [control]="f.password" error="required" message="REQUIRED_FIELD_PASSWORD" [showOnSubmitted]="true"></app-validation-message>
        </app-textfield-group>
    </app-form-group>
        <div class="row">
            <div class="register-link-block">
                <a class="color-black-60 text-decoration-underline font-size-medium forgot-password-link"  [routerLink]="forgotPasswordLink" id="action-forgot-password">
                    {{ 'FORGOT_YOUR_PASSWORD_V2' | translate }}
                </a>
            </div>
            <div class="login-button-group">
                <a *ngIf="showRegisterLink" [routerLink]="registerLink" class="button button-xs-small" id="action-register">
                    {{ 'REGISTER_BUTTON' | translate }}
                </a>
                <button class="button button--primary margin-left-half button-xs-small" type="submit" id="action-submit-login-form" [disabled]="loading">
                    {{ 'LOGIN_TITLE' | translate }}
                </button>
            </div>
        </div>
</form>
