import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, createUrlTreeFromSnapshot, Router, RouterStateSnapshot, UrlSerializer, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AppState } from '@pepconnect/state/index';
import { selectPolicyGuardProps, selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { PageRequirement } from '@pepconnect/enums/page-requirement.enum';
import { PolicyGuardProps } from '@pepconnect/models/auth/policy-guard-props.model';
import { constants } from '@pepconnect/constants';
import { AuthService } from '@pepconnect/services/auth.service';
import { EnvironmentService } from '@pepconnect/services/environment.service';

/** Page Policy Route Guard
 *  This guard is the primary means of handling if a page can be accessed or if a redirect to safe-harbor should happen.
 *  One important distinction to remember is a user can be authenticated, but still be anonymous.   Having an access token
 *  does not mean that you are "logged in".
 *
 *  The Page Requirement can be set on the route data when defining a route in the router module.
 *
 *  If future needs neccessitate, enums could be combined to include more than one flag.   Or a type could be used instead.
 *  Performance matters here - Use the Chromium profiler to analyze your code and measure any changes.
 *
 */
class PagePolicyGuard {
  /** Determines if the users authentication state matches the specified requirements. */
  static onNext(pagePolicy: PageRequirement, next: ActivatedRouteSnapshot, store: Store<AppState>, authService: AuthService, router: Router, serializer: UrlSerializer) {
    switch (pagePolicy) {
      // Go forth and conquer - If they have gotten this far then they have an access token.
      case PageRequirement.ShouldHaveAuthToken:
        return of(true);
      // The User should not be logged in.  Login, register, home page etc.
      case PageRequirement.ShouldBeAnonymous:
        return store.select(selectPolicyGuardProps).pipe(
          switchMap(props => this.evaluateAnonymousRequirement(props, authService, router))
        );
      // the User should be logged in to view page
      case PageRequirement.ShouldBeLoggedIn:

        // get the current path in case we need it for login redirect
        return store.select(selectPolicyGuardProps).pipe(
          switchMap(props => this.evaluateLoggedInRequirement(props, next, authService, router, serializer))
        );
      default:
        return of(false);
    }
  }

  /**
   * Evaluate if the user meets the requirement to view a page that has the Anonymous requirement.
   * If the requirement fails, return a new router tree to navigate too
   * @param props: {PolicyGuardProps}
   * @returns {Observable}  We need to return an observable to meet the requirements of the switchMap in the callee
   */
  static evaluateAnonymousRequirement(props: PolicyGuardProps, authService: AuthService, router: Router): Observable<boolean | UrlTree> {
    const isLoggedIn = authService.isTokenForLoggedInUser();
    if (isLoggedIn) {
      const tree = router.createUrlTree(
        ['/', props.routerLocale]);
      return of(tree);
    }
    return of(true);
  }

  /**
  * Evaluate if the user meets the requirement to view a page that has the Logged In requirement.
  * If the requirement fails, return a new router tree to navigate too
  * @param props: {PolicyGuardProps}
  * @returns {Observable}  We need to return an observable to meet the requirements of the switchMap in the callee
  */
  static evaluateLoggedInRequirement(props: PolicyGuardProps, next: ActivatedRouteSnapshot, authService: AuthService, router: Router, serializer: UrlSerializer): Observable<boolean | UrlTree> {
    const isLoggedIn = authService.isTokenForLoggedInUser();
    if (!isLoggedIn) {
      // get the current route path and save it for after login
      const urlTree = createUrlTreeFromSnapshot(next, []);

      // set session so login will redirect to correct place
      sessionStorage.setItem(constants.SS_REDIRECT_URL, serializer.serialize(urlTree));

      // redirect to login
      const tree = router.createUrlTree(['/', props.routerLocale, constants.REDIRECT_REQUIREMENT_LOGGEDIN]);
      return of(tree);
    }
    return of(true);
  }

}


export const canActivatePagePolicyChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const store = inject(Store<AppState>);
  const authService = inject(AuthService);
  const router = inject(Router);
  const serializer = inject(UrlSerializer);
  let pagePolicy = route.data?.policy as PageRequirement;
  // if the requirement is missing - throw an error.   Can't use ! because the value of an enum can be 0.
  if (typeof pagePolicy === 'undefined') {
    throw new Error(constants.ERROR_MISSING_REQUIREMENT);
  }
  // Override the the page policy for inline users.
  if (inject(EnvironmentService).isInlineTranslate) {
    pagePolicy = PageRequirement.ShouldHaveAuthToken;
  }
  return store.select(selectAuthState)
    .pipe(
      first(authState => authState.isInitialized),
      switchMap(_ => authService.getAuthData()),
      switchMap(verifed => verifed ? PagePolicyGuard.onNext(pagePolicy, route, store, authService, router, serializer) : of(false))
    );
}

export const canActivatePagePolicy: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return canActivatePagePolicyChild(route, state);
}
