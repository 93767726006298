<p class="font-size-medium">{{ 'TEXT_HID_LOGIN_PAGE_INFO' | translate }}</p>
<app-hid-saml-link></app-hid-saml-link>
<p class="font-size-small margin-top-1"><strong>{{ 'FORGOT_YOUR_PASSWORD_LINK' | translate }}</strong> {{ 'TEXT_HID_LOGIN_PAGE_PASS_DISCLAIMER' | translate }}</p>
<div class="margin-top-2">
    <div class="row align-items-baseline margin-bottom-1">
        <div class="col-xs-12 col-sm-8" [class.col-sm-12]="guestCheckout">
            <p class="font-size-small" [innerHTML]="pepExistingAccountText"></p>
        </div>
        <div class="padding-0 col-xs-12 col-sm-4 justify-content-center button-container display-flex" [class.col-sm-12]="guestCheckout">
            <button type="button" class="button button--primary" (click)="linkAccount()">{{ 'TEXT_HID_LOGIN_PAGE_LINK_ACCOUNT' | translate }}</button>
        </div>
    </div>
    <div class="row align-items-baseline register-container margin-bottom-1">
        <div class="col-xs-12 col-sm-8" [class.col-sm-12]="guestCheckout">
            <p class="font-size-small" [innerHTML]="registerText"></p>
        </div>
        <div class="padding-0 col-xs-12 col-sm-4 button-container justify-content-center button-container display-flex" [class.col-sm-12]="guestCheckout">
            <a [routerLink]="['/', locale, 'register']" class="button">{{ 'REGISTER_BUTTON' | translate }}</a>
        </div>
    </div>
</div>
