<app-page-container id="unsubscribe-page-container">
    <app-standard-wrap>
        <app-block-centered>
            <div *ngIf="hasError" id="error-panel">
                <app-alert indicator="error" [accent-border]="true">
                    {{ errorMessage | translate }}
                </app-alert>
            </div>
        </app-block-centered>
    </app-standard-wrap>
    <app-standard-wrap>
        <app-block-centered>
            <app-headline headline="LABEL_UNSUBSCRIBE" [container]="false"></app-headline>
            <h4>{{message}}</h4>
        </app-block-centered>
    </app-standard-wrap>
    <app-standard-wrap>
        <app-block-centered>
            <div class="unsubscribe-button-group">
                <uimc-button *ngIf="confirm" [primary]="true" (click)="unsubscribe()">{{'LABEL_UNSUBSCRIBE' | translate}}
                </uimc-button>

                <uimc-button *ngIf="authState?.isLoggedIn" [primary]="true" (click)="goToSettings()">{{'SETTINGS_MENU' | translate}}
                </uimc-button>
            </div>
        </app-block-centered>
    </app-standard-wrap>
</app-page-container>