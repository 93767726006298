import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Link } from '@pepconnect/models/general/link.model';
import { take, takeUntil } from 'rxjs/operators';
import { AppState } from '@pepconnect/state/index';
import { BaseComponent } from '@pepconnect/shared/base';
import { UserService } from '@pepconnect/services/user.service';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { LinksService } from '@pepconnect/services/links.service';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { constants } from '@pepconnect/constants';
import { UserAvatar } from '@pepconnect/models/user/user-avatar.model';

@Component({
  selector: 'app-user-identifier',
  templateUrl: './user-identifier.component.html',
  styleUrls: ['./user-identifier.component.scss'],
})
export class UserIdentifierComponent extends BaseComponent implements OnInit {
  $state = this.store.select(selectAuthState);
  navItems: Link[];
  $authState = this.store.select(selectAuthState);
  authState: AuthState;
  loading = true;
  userAvatar: UserAvatar;
  visible = true;

  constructor(
    private store: Store<AppState>,
    private linksService: LinksService,
    private userService: UserService,
    private router: Router,
    private env: EnvironmentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe(authState => {
      this.loading = true;
      this.authState = authState;
      this.setNavItems();
      this.mapUser();
    })
  }

  setNavItems() {
    if (this.authState.isLoggedIn) {
      this.navItems = [];
      this.navItems.push({ rel: LinkRel.path, href: 'profile', text: 'ACCOUNT_MENU', v2: false });
      this.navItems.push({ rel: LinkRel.path, href: 'profile/settings', text: 'SETTINGS_MENU', v2: false });
      this.navItems.push({ rel: LinkRel.path, href: 'messagecenter', text: 'MESSAGES_MENU', v2: false });
      if (this.authState.user.isSiemensAdmin && !this.env.isCMS) {
        this.navItems.push({ rel: LinkRel.voSSO, text: 'ADMIN_LINK', v2: false });
      }
      this.navItems.push({ rel: LinkRel.logout, text: 'LOGOUT_MENU', v2: false });
    }
    if (!this.authState.isLoggedIn) {
      this.navItems = [
        { rel: LinkRel.path, text: 'LOGIN_TITLE', href: 'login', v2: false },
        { rel: LinkRel.path, text: 'REGISTER_BUTTON', href: 'register', v2: false }
      ];
    }

  }

  show() {
    return !this.loading
  }

  mapUser() {
    this.userAvatar = {
      firstname: this.authState.user.firstname,
      lastname: this.authState.user.lastname,
      avatar: this.authState.user.imageURL
    };
  }


  dispatch(link: Link) {
    this.visible = false;
    switch (link.rel) {
      case LinkRel.path:
        if(link.href === 'login') {
          this.setRedirectUrl();
        }
        this.linksService.dispatch(link);
        break;
      case LinkRel.logout:
        this.userService.logout(true).pipe(take(1)).subscribe(u => {
          this.router.navigate([this.authState.user.locale.locale, constants.REDIRECT_REQUIREMENT_ANONYMOUS]);
        });
        break;
      case LinkRel.voSSO:
        this.userService.ssoVirtualOffice();
        break;
    }
    // Marcom UI provides no mechanism to close the dropdown menu... so we do this instead.
    setTimeout(() => this.visible = true, 100);
  }

  setRedirectUrl() {
    if(!sessionStorage.getItem(constants.SS_REDIRECT_URL) &&
        this.router.url !== `/${this.authState.user.locale.locale}` && 
        this.router.url !== `/${this.authState.user.locale.locale}/${constants.HOME}`) {
      sessionStorage.setItem(constants.SS_REDIRECT_URL, this.router.url);
    }
  }
}
