import { Injectable } from '@angular/core';
import { User } from '@pepconnect/models/user/user.model';
import { ApiService } from '@pepconnect/services/api.service';
import { Observable } from 'rxjs';
import { SsoResponse } from '@pepconnect/features/sso/model/sso-response';

@Injectable({
  providedIn: 'root'
})
export class LtiSsoService {

  constructor(private api: ApiService) { }

  getLaunchRequest(requestId: string, nonce: string, user: User): Observable<SsoResponse> {
    return this.api.get<SsoResponse>(`sso/lti/${requestId}/${nonce}?countrycode=${user.country.code}&locale=${user.locale.locale}`);
  }
}
