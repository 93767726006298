import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { SearchBarTheme } from '@pepconnect/enums/searchbar-theme.enum';
import { BaseComponent } from '@pepconnect/shared/base';
import { selectAuthState, selectIsUserInitialized } from '@pepconnect/state/auth/auth.selector';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { selectSuppressSearchBar, selectUseCheckoutLayout, selectUseMinimalLayout } from '@pepconnect/state/flags/flags.selector';
import { AppState } from '@pepconnect/state/index';
import { takeUntil } from 'rxjs/operators';
import { setCancelCheckout } from '@pepconnect/state/events/events.actions';

@Component({
  selector: 'app-app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.scss'],
})
export class AppBarComponent extends BaseComponent implements OnInit {
  $authState = this.store.pipe(select(selectAuthState));
  authState: AuthState;

  $useMinimalLayout = this.store.pipe(select(selectUseMinimalLayout));
  $useCheckoutLayout = this.store.pipe(select(selectUseCheckoutLayout));
  useCheckoutLayout: boolean;

  $isInitialized = this.store.pipe(select(selectIsUserInitialized));
  $suppressSearchBar = this.store.pipe(select(selectSuppressSearchBar));

  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: 767px)`);

  localeV2Homepage: string;
  searchBarTheme = SearchBarTheme.Gray;
  menuExpanded: boolean = false;
  enableSearchBar: boolean = false;
  hideBorder: boolean = true;
  appIdentifier = 'ABOUT_PEPCONNECT_TITLE';
  suppressSearchBar = false;
  
  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe(authState => {
      this.authState = authState;
      this.localeV2Homepage = `/${authState.user.locale.locale}`;
    });
    this.setMQListener();
    this.$suppressSearchBar.subscribe(suppressSearchBar => {
      this.suppressSearchBar = suppressSearchBar;
    });
    this.$useCheckoutLayout.subscribe((useCheckoutLayout) => this.useCheckoutLayout = useCheckoutLayout);
  }

  setMQListener() {
    const listener = (mql: MediaQueryListEvent) => {
      if (mql.matches) {
        this.searchBarTheme = SearchBarTheme.White;
        this.hideBorder = false;
      } else {
        this.searchBarTheme = SearchBarTheme.Gray;
        this.hideBorder = true;
      }
    };
    if (this.mediaMatcher.addEventListener) {
      this.mediaMatcher.addEventListener('change', listener);
    } else {
      // this is to support Safari version older than 14
      this.mediaMatcher.addListener(listener);
    }
    this.searchBarTheme = this.mediaMatcher.matches ? SearchBarTheme.White : SearchBarTheme.Gray;
  }

  onSearchbarFocus(focused: boolean) {
    this.menuExpanded = focused;
    this.cd.detectChanges();
  }

/**
 * Called when "PEPconnect logo" is clicked in upper left of navbar
 *
 * if we're in checkout, need to trigger cancellation of transaction before redirection
 *
 * */
  confirmRedirect() {
    // if we're in checkout, prompt user before redirecting
    if (this.useCheckoutLayout) {
      this.store.dispatch(setCancelCheckout({ cancelCheckout: true, redirectHome: true }));
    }
    else {
      this.router.navigate([this.localeV2Homepage]);
    }
  }
}
