<app-block-centered>
    @if (emailAlreadyExists) {
    <div class="row align-items-baseline margin-bottom-1">
        <div class="col-xs-12 col-sm-8">
            <p class="font-size-small" [innerHTML]="('TEXT_EMAIL_EXISTS_GOTO_LOGIN' | translate)"></p>
        </div>
        <div class="padding-0 col-xs-12 col-sm-4 justify-content-center button-container display-flex">
            <button type="button" class="button button--primary" (click)="gotoLogin()">{{ 'BUTTON_LOGIN' | translate }}</button>
        </div>
    </div>
    }
    @else if (errorMessage) {
    <div id="error-panel">
        <app-alert indicator="error"
                   [accent-border]="true">
            {{ errorMessage | translate }}
        </app-alert>
    </div>
    }
</app-block-centered>
@if (registerForm && countryIsActive) {
<app-form-centered (onSubmit)="onSubmit()" [formGroup]="registerForm">
    <p *ngIf="showTabsNavigationMessage">{{ 'REGISTER_USE_TABS_TO_NAVIGATE' | translate }}</p>
    <app-form-group>
        <app-textfield-group [skip-invalid]="false">
            <label class="textfield__label" for="register-first-name">{{ 'FIRST_NAME' | translate }}</label>
            <input type="text" class="textfield__input" id="register-first-name" name="first" formControlName="firstname" autocomplete="given-name">
            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true"></app-form-hint>
            <app-validation-message [control]="f.firstname" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_FIRST"></app-validation-message>
            <app-validation-message [control]="f.firstname" error="pattern" message="FIELD_VALIDATE_PATTERN_REGISTER"></app-validation-message>
        </app-textfield-group>
    </app-form-group>
    <app-form-group>
        <app-textfield-group [skip-invalid]="false">
            <label class="textfield__label" for="register-last-name">{{ 'LAST_NAME' | translate }}</label>
            <input type="text" class="textfield__input" id="register-last-name" name="last" formControlName="lastname" autocomplete="family-name">
            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true" [control]="f.lastname"></app-form-hint>
            <app-validation-message [control]="f.lastname" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_LAST"></app-validation-message>
            <app-validation-message [control]="f.lastname" error="pattern" message="FIELD_VALIDATE_PATTERN_REGISTER"></app-validation-message>
        </app-textfield-group>
    </app-form-group>
    <app-form-group>
        <app-textfield-group [skip-invalid]="false">
            <label class="textfield__label" for="register-email">{{ 'EMAIL' | translate }}</label>
            <input type="email" class="textfield__input" id="register-email" name="email" formControlName="email" autocomplete="email" email>
            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true" [control]="f.email"></app-form-hint>
            <app-validation-message [control]="f.email" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_EMAIL"></app-validation-message>
            <app-validation-message [control]="f.email" error="email" message="FIELD_CHECK_EMAIL"></app-validation-message>
            <div *ngIf="f.email.errors?.['siemensEmail']" class="textfield__hint">
                <p>
                    {{ 'FIELD_SIEMENS_EMAIL' | translate }}
                    <button class="button button--primary button--small display-block" (click)="redirectToSsoLink()">{{'SIEMENS_TCMAN_LINK' | translate }}</button>
                    <br />
                </p>
            </div>
        </app-textfield-group>
    </app-form-group>
    @if (legacyRegistration) {
    <app-form-group>
        <app-textfield-group [skip-invalid]="false">
            <label class="textfield__label" for="register-password">{{ 'CHANGE_PASSWORD_TITLE' | translate }}</label>
            <input type="password" class="textfield__input" id="register-password" name="password" formControlName="password" autocomplete="new-password">
            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true" [control]="f.password"></app-form-hint>
            <app-validation-message [control]="f.password" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_PASSWORD"></app-validation-message>
            <app-validation-message [control]="f.password" error="minLength" message="REQUIRED_FIELD_PASSWORD"></app-validation-message>
            <app-validation-message [control]="f.password" error="passwordChars" message="REQUIRED_FIELD_PASSWORD"></app-validation-message>
        </app-textfield-group>
    </app-form-group>
    <p class="help-block">{{ 'FIELD_CHECK_PASSWORD_REQ' | translate }}</p>
    <app-form-group>
        <app-textfield-group [skip-invalid]="false">
            <label class="textfield__label" for="register-email">{{ 'CONFIRM_PASSWORD' | translate }}</label>
            <input type="password" class="textfield__input" id="register-confirm-password" name="confirmPassword" formControlName="confirmPassword" autocomplete="new-password">
            <app-form-hint hint="PLACEHOLDER_REQUIRED" [hideOnSubmitted]="true" [control]="f.confirmPassword"></app-form-hint>
            <app-validation-message [control]="f.confirmPassword" [showOnSubmitted]="true" error="required" message="FIELD_CHECK_PASSWORD_TWICE"></app-validation-message>
            <app-validation-message [control]="f.confirmPassword" error="passwordMatch" message="FIELD_CHECK_PASSWORD_MATCH"></app-validation-message>
        </app-textfield-group>
    </app-form-group>
    }
    <app-form-group>
        <app-country-selector-v2 formControlName="countryCode" (onCountrySelect)="onSelectedCountry($event)"></app-country-selector-v2>
        <app-validation-message [control]="f.countryCode" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD_COUNTRY"></app-validation-message>
        <div class="selectbox__hint"></div>
    </app-form-group>
    <app-form-group>
        <app-system-identifiers [formArray]="f.systemIdentifiers"></app-system-identifiers>
    </app-form-group>
    <app-form-group>
        <app-communication-preferences [optInControl]="f.optIn" [optInFeedControl]="f.optInFeed" [useDefaultValues]="true"></app-communication-preferences>
    </app-form-group>
    <app-form-group>
        <h6>{{ 'AGREEMENT_BLURB_HEADER' | translate }}</h6>
        <uimc-checkbox (checkedChange)="onTermsAgree($event)">
            <a href="http://www.healthcare.siemens.com/terms-of-use" target="_blank" class="icon-link-intern">{{ 'TERMS_OF_USE'  | translate }}</a>
        </uimc-checkbox>
        <app-validation-message [control]="f.termsAgree" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD" type="none-with-invalid"></app-validation-message>
        <br />
        <uimc-checkbox (checkedChange)="onPrivacyAgree($event)">
            <a [routerLink]="['/', userLocale, 'privacy']" target="_blank" class="icon-link-intern">{{ 'PRIVACY_POLICY'  | translate }}</a>
        </uimc-checkbox>
        <app-validation-message [control]="f.privacyAgree" [showOnSubmitted]="true" error="required" message="REQUIRED_FIELD" type="none-with-invalid"></app-validation-message>
    </app-form-group>
    <app-recaptcha-container (response)="onToken($event)" action="register" class="display-flex justify-content-start justify-content-lg-end"></app-recaptcha-container>
    <app-form-buttons>
        <button class="button button--primary" type="submit" [disabled]="loading || !token">{{ 'REGISTER_JOIN' | translate }}</button>
    </app-form-buttons>
</app-form-centered>
}
@else if (!countryIsActive && userLocale) {
<div id="inactive-country-warning-register">
    <p class="text-center">{{'REGISTER_INACTIVE_COUNTRY' | translate}}</p>
    <div class="display-flex justify-content-center">
        <button type="button" class="button button--primary" [routerLink]="['/', userLocale, 'support']">{{ 'REDIRECT_REDIRECT_LINK_SUPPORT' | translate }}</button>
        <button type="button" class="button button--primary" [routerLink]="['/', userLocale, 'explore']">{{ 'REDIRECT_GUEST_LINK' | translate }}</button>
    </div>
</div>
}

