<!-- TOP NAVIGATION -->
<div class="nav" [class.is-open]="toggleOpen" data-ste-chapter="navigation">
    <div class="nav-bar__wrapper">
        <button class="nav-bar__toggle-btn" (click)="toggleOpen = !toggleOpen"> <i class="icon-burger"></i></button>
        <div class="nav-bar__app-identifier">{{header}}</div>
    </div>
    <ul class="nav-container" [class.click-pending]="clickPending">
        <ng-content></ng-content>
        <li *ngIf="isCMS" class="cms-header hidden-md font-size-xxsmall color-red-dark">{{ 'CMS_HEADER' | translate }}</li>
    </ul>
</div>
