import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '@pepconnect/constants';
import { forms } from '@pepconnect/constants/forms';
import { RegisterUserDTO } from '@pepconnect/dtos/account/register-user.dto';
import { RegistrationTypes, registerConstants } from '@pepconnect/features/account/register/register-constants';
import { OnToken } from '@pepconnect/interfaces/itoken.interface';
import { Country } from '@pepconnect/models//intl/country.model';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { Locale } from '@pepconnect/models/localization/locale.model';
import { ModalsService } from '@pepconnect/services/modals.service';
import { NavigationService } from '@pepconnect/services/navigation.service';
import { NotificationService } from '@pepconnect/services/notification.service';
import { UserHidService } from '@pepconnect/services/user-hid.service';
import { UserService } from '@pepconnect/services/user.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { FormsService } from '@pepconnect/shared/services/forms.service';
import { ScrollService } from '@pepconnect/shared/services/scroll.service';
import { passwordMatchValidator } from '@pepconnect/shared/validators/password-match-validator.directive';
import { AppState } from '@pepconnect/state';
import { setPendingAuth } from '@pepconnect/state/auth/auth.actions';
import { selectMetadataCountries, selectMetadataLocales } from '@pepconnect/state/metadata/metadata.selector';
import { first, forkJoin, take, takeUntil } from 'rxjs';

/** Standalone register form that can be dropped in as a component.  Emits the onRegister event for post register action.  NOTE:  pendingAuth will be stored in the ngrx store for  */

type RegisterForm = Required<{
  countryCode: FormControl<string>
  firstname: FormControl<string>;
  lastname: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
  locale: FormControl<string>;
  systemIdentifiers: FormArray<FormControl<string>>;
  optIn: FormControl<boolean>;
  optInFeed: FormControl<boolean>;
  termsAgree: FormControl<boolean>;
  privacyAgree: FormControl<boolean>;
}>


@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html'
})
export class RegisterFormComponent extends BaseSmartComponent implements OnInit, OnToken {
  private readonly navigationService = inject(NavigationService);
  private readonly notificationService = inject(NotificationService);
  private readonly translate = inject(TranslateService);
  private readonly userHideService = inject(UserHidService);
  private readonly router = inject(Router);

  $countries = this.store.select(selectMetadataCountries);
  $locales = this.store.select(selectMetadataLocales);
  registerForm: FormGroup<RegisterForm>;
  submitted = false;
  successMessage: string;
  errorMessage: string;
  loading = false;

  countryIsActive = true;
  showTabsNavigationMessage = false;
  isSelectedCountryDisabled = false;

  countries: Country[];
  locales: Locale[];

  userLocale: string;
  userCountryCode: string;
  legacyRegistration: boolean = false;
  emailAlreadyExists: boolean = false;

  @Output() registered = new EventEmitter<RegistrationTypes>();
  token: string;

  private readonly cd = inject(ChangeDetectorRef);
  private readonly scrollService = inject(ScrollService);

  constructor(
    protected store: Store<AppState>,
    private fb: FormBuilder,
    private userService: UserService,
    private modalsService: ModalsService,
    private formsService: FormsService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    forkJoin({
      countries: this.$countries.pipe(first(c => this.populatedGuard(c))),
      locales: this.$locales.pipe(first(l => this.populatedGuard(l))),
      hidFlag: this.checkFeatureFlag(constants.FLAG_PEP_HID_LOGIN).pipe(take(1))
    }).subscribe((res) => {
      this.countries = res.countries;
      this.locales = res.locales;
      this.legacyRegistration = !res.hidFlag;

      this.setupForm();
      this.setUser();
    });
  }

  get f() {
    return this.registerForm?.controls;
  }

  get systemIdentifiers() {
    return this.f.systemIdentifiers;
  }

  setupForm() {
    this.registerForm = this.fb.nonNullable.group({
      firstname: this.fb.control('', [Validators.required, Validators.pattern(forms.VALIDATOR_FIRSTNAME_REGEX), Validators.maxLength(forms.VALIDATOR_FIRSTLAST_MAX_LENGTH)]),
      lastname: this.fb.control('', [Validators.required, Validators.pattern(forms.VALIDATOR_LASTNAME_REGEX), Validators.maxLength(forms.VALIDATOR_FIRSTLAST_MAX_LENGTH)]),
      email: this.formsService.getEmailControl(this.fb),
      password: this.formsService.getPasswordControl(this.fb),
      confirmPassword: this.fb.control('', { validators: [Validators.required], updateOn: 'blur' }),
      countryCode: this.fb.control('', [Validators.required]),
      locale: this.fb.control(''),
      systemIdentifiers: this.formsService.getSystemIdentifiersArray(),
      optIn: this.fb.control(undefined, [Validators.required]),
      optInFeed: this.fb.control(undefined, [Validators.required]),
      termsAgree: this.fb.control(false, [Validators.requiredTrue]),
      privacyAgree: this.fb.control(false, [Validators.requiredTrue])
    });

    if (this.legacyRegistration) {
      this.registerForm.setValidators(passwordMatchValidator());
    }
    else {
      this.registerForm.controls.password.clearValidators();
      this.registerForm.controls.confirmPassword.clearValidators();
    }
  }

  setUser() {
    this.$user.pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
      this.registerForm.controls.locale.setValue(user.locale.locale);
      this.countryIsActive = user.country.isActive;
      this.userLocale = user.locale.locale;
      this.userCountryCode = user.country.code;
      // show a message to use tabs nav is user is ko-kr
      this.showTabsNavigationMessage = this.userLocale === 'ko-kr';

      if (!this.f.countryCode.value) {
        this.f.countryCode.setValue(this.userCountryCode);
      }
    });
  }

  onSelectedCountry($event: Country) {
    this.isSelectedCountryDisabled = $event.id === -1;
  }

  onTermsAgree($event) {
    this.f.termsAgree.setValue($event);
  }
  onPrivacyAgree($event) {
    this.f.privacyAgree.setValue($event);
  }

  onToken($event) {
    this.token = $event;
    // Because this is an external callback, angular doesn't always see the changes
    this.cd.detectChanges();
  }

  async onSubmit() {
    if (!this.registerForm?.valid || !this.token) {
      return;
    }
    this.loading = true;
    this.resetMessages();
    this.submitted = true;
    // Since we need to send the country/locale as full objects, we can't map from the typed form value to UserDto so we do it manually
    const value = this.registerForm.value;
    const payload: RegisterUserDTO = {
      firstname: value.firstname,
      lastname: value.lastname,
      email: value.email,
      password: value.password,
      optIn: value.optIn,
      optInFeed: value.optInFeed,
      registrationCode: this.token
    };

    payload.country = this.countries.find(c => c.code === this.f.countryCode.value);
    payload.locale = this.locales.find(l => l.locale === this.f.locale.value);
    payload.systemIdentifiers = value.systemIdentifiers.map(s => ({ text: s }));

    this.userService.register(payload).pipe(take(1)).subscribe({
      next: () => this.onSuccess(payload),
      error: (err: UnserializedResponseMessage) => this.onError(err, payload)
    });
  }

  resetMessages() {
    this.errorMessage = undefined;
    this.emailAlreadyExists = false;
  }

  cancelLoadingFeedback() {
    this.loading = false;
    this.submitted = false;
    this.scrollService.scrollTop();
  }

  emitResult(result) {
    this.reset();
    this.registered.emit(result);
  }

  reset() {
    this.resetMessages();
    this.cancelLoadingFeedback();
    this.registerForm.reset();
  }

  onSuccess(payload: RegisterUserDTO) {
    this.setPendingLogin(payload);
    if (this.legacyRegistration) {
      this.emitResult(RegistrationTypes.Legacy);
    }
    else {
      this.emitResult(RegistrationTypes.NewSiemensHID);
    }
  }

  onError(err: UnserializedResponseMessage, payload: RegisterUserDTO) {
    if (err?.Message === registerConstants.USER_ALREADY_EXISTS) {
      this.emailAlreadyExists = true;
      this.notificationService.showWarning(this.translate.instant(err?.Message));
      this.cancelLoadingFeedback();
    }
    else if (err?.Message === registerConstants.HID_USER_LINKED) {
      this.setPendingLogin(payload);
      this.emitResult(RegistrationTypes.SiemensHIDAlreadyExisted);
    }
    else {
      this.errorMessage = err.Message;
      this.cancelLoadingFeedback();
    }
  }

  setPendingLogin(payload: RegisterUserDTO) {
    const pendingAuthentication = {
      email: payload.email,
      password: payload.password,
      optIn: payload.optIn,
      optInFeed: payload.optInFeed
    }
    this.store.dispatch(setPendingAuth({ pendingAuthentication }));
  }

  openSidModal() {
    this.modalsService.definitions.openSidModal();
  }

  gotoLogin() {
    this.navigationService.gotoLogin();
  }

  redirectToSsoLink() {
    this.userHideService.hidSamlLink$.pipe(take(1)).subscribe((res) => {
      this.router.navigate([res]);
    });
  }
}
