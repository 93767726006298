<section data-ste-chapter="chapter-content">
    <!-- Matilda Section -->
    <app-hero></app-hero>

    <!-- Spinner to show while carousel data is being fetched -->
    <app-spinner *ngIf="carouselLoading" class="col-md-12 spinner">
    </app-spinner>

    <!-- Carousel recommendation Cards -->
    <app-recommendation-carousel id="recommendation-carousel" *ngIf="showCarousel && !carouselLoading"
                                 [popularItems]="popularItems" [newestItems]="newestItems"></app-recommendation-carousel>

    <!-- Marketing banner section -->
    <app-marketing></app-marketing>
</section>
