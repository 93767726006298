export const modalConfig = {
  sizes: {
    sm: {
      width: 300, // 300px
      minHeight: 250 // 250px
    },
    md: {
      width: '750px',
    },
    lg: {
      width: '90vw',
    },
    fullScreen: {
      width: '100vw',
      height: '100vh'
    },
    stretch: {
      minHeight: 500,
      maxHeight: '85%'
    },
    video: {
      width: 1080,
    },
    'custom-small': {
      width: '30vh'
    },
    'custom-large': {
      width: 1250
    },
    auto: {
      width: 'auto',
      height: 'auto'
    }
  }
}
