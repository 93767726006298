import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { first } from 'rxjs';

@Component({
  selector: 'app-hid-transition-intermediate',
  templateUrl: './hid-transition-intermediate.component.html',
  styles: ``
})
export class HidTransitionIntermediateComponent extends BaseV2SmartComponent implements OnInit{

  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  userEmail: string;

  ngOnInit(): void {
    this.authState$.pipe(first(authState => this.initializationGuard(authState))).subscribe(authState => {
      this.userEmail = authState.pendingAuthentication?.email;
      // if the user went here directly, then they need to login first
      if(!this.userEmail) {
        this.router.navigate([this.route.snapshot.paramMap.get('locale'),'login']);
      }

    });
  }

}
