<h2 class="select-header font-size-medium">{{'LABEL_SELECT_COUNTRY_V2' | translate}}</h2>
<form id="country-selection-form" [formGroup]="countryForm" class="form-style" (ngSubmit)="submit()">
    <uimc-filtered-select-v2 formControlName="country"
                          [showCountryFlags]="true"
                          [filterThreshold]="1"
                          [label]="'COUNTRY' | translate"
                          [labelSearch]="'PLACEHOLDER_TYPE_FILTER' | translate">
        <option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option>
    </uimc-filtered-select-v2>
    <button id="country-selection-submit" class="button button--primary margin-left-half button-xs-small" type="submit">{{'SUBMIT_BUTTON' | translate}}</button>
</form>
