/**
 * Constants for dealing with SSO things and their associated magic strings
 * */
class SsoConstants {
  /** Locale storage keys for the CMS/Inline stuff */
  readonly CMS_LS_LOCALE = 'ls.cms-site-locale';
  readonly CMS_LS_LOG_INLINE_KEY = 'inline-loggedin';
  readonly CMS_LS_LOG_KEY = 'cms-loggedin';
  readonly CMS_LS_USERID = 'ls.cms-pep-userid';
  /** SAML request keys */
  readonly SAML_AUTHN_REQUEST_NAME = 'SAMLRequest';
  readonly SAML_AUTHN_RESPONSE_NAME = 'SAMLResponse';
  readonly SAML_BINDING_POST = 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST';
}

export const ssoConstants = new SsoConstants();
