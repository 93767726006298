import { Component } from '@angular/core';
import { BaseComponent } from '@pepconnect/shared/base';
import { ModalsService } from '@pepconnect/services/modals.service';

@Component({
  selector: 'app-translate-spanner',
  templateUrl: './translate-spanner.component.html',
  styleUrls: ['./translate-spanner.component.scss']
})


export class TranslateSpannerComponent extends BaseComponent {
  constructor(
    private modalService: ModalsService,
  ) {
    super();
  }

  onTranslateModalOpen() {
    this.modalService.definitions.openTranslateModal();
  }

  onDataModalOpen() {
    this.modalService.definitions.openTranslateDataOptionsModal();
  }

}



