import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { insertHtmlTags } from '@pepconnect/utils/text-helpers';
import { delay, first, of, switchMap, take } from 'rxjs';
import { scrollToAnchor } from '@pepconnect/functions/scroll-to-anchor';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AppState } from '@pepconnect/state';
import { selectRouteParam } from '@pepconnect/state/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends BaseSmartComponent implements AfterViewInit {

  $fragment = this.store.pipe(select(selectRouteParam('fragment')))

  constructor(protected store: Store<AppState>, @Inject(DOCUMENT) private document: Document) {
    super(store)
  }

  ngAfterViewInit() {
    this.$authState.pipe(first(authState => this.initializationGuard(authState)), switchMap(() => this.$fragment)).subscribe(fragment => {
      if (fragment) {
        of(null).pipe(delay(1000), take(1)).subscribe(() => scrollToAnchor(this.document, fragment));
      }
    });
  }

  insertHtmlTags(value: string) {
    return insertHtmlTags(value);
  }

}
