<ng-template #componentTemplate>
    <ng-template #content>
        <ng-content select="[nav-content]"></ng-content>
    </ng-template>
    <li #item [class.is-active]="active" class="l-nav-container">
        <a href='#' (click)="onClick($event)" [class.unclickable]="!clickable" class="l-nav-item">
            <ng-content></ng-content><i *ngIf="hasSubmenu" [class.drop-down-indicator]="isToplevel"
                [class.menu-expansion-indicator]="!isToplevel"></i>
        </a>
        <ul *ngIf="shouldShowSubmenu" [class.is-open]="isExpanded" [style]="'transform: translate(0, ' + transformSubmenuAmount + ')'" class="l-nav-subitem">
            <li class="back-link">
                <button class="back" (click)="isExpanded = !isExpanded">
                    <i class="mobile-menu-expansion"></i>
                    {{'LABEL_BACK_BUTTON' | translate}}
                </button>
            </li>
            <li *ngIf="hasTitle">
                <ng-content select="app-layered-nav-title"></ng-content>
            </li>
            <ng-content select="app-layered-nav-link"></ng-content>
            <li *ngIf="isToplevel && hasContent" class="menu-content default-menu">
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </li>
        </ul>
    </li>
    <li class="menu-content" *ngIf="!isToplevel && hasContent">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </li>
    <li *ngIf="hasSubmenu && isToplevel" class="cover"></li>
</ng-template>
