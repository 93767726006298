import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ObjectTypes } from '@pepconnect/enums/object-types.enum';
import { isMobileDevice } from '@pepconnect/functions/is-mobile';
import { ObjectCardSearchResult } from '@pepconnect/models/object-card/object-card.model';
import { User } from '@pepconnect/models/user/user.model';
import { AnalyticsService } from '@pepconnect/services/analytics.service';
import { MetadataTranslateService } from '@pepconnect/services/metadata-translate.service';
import { SearchService } from '@pepconnect/services/search.service';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends BaseV2SmartComponent implements OnInit {

  $authState = this.store.select(selectAuthState);
  objectType = ObjectTypes.Default;
  results: ObjectCardSearchResult;
  user: User;
  searchLogID: number;
  loading: boolean = true;
  hasError = false;

  public searchSvc = inject(SearchService);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private translate = inject(TranslateService);
  private analyticsService = inject(AnalyticsService);
  private metaDataTranslateService = inject(MetadataTranslateService);
  private document = inject(DOCUMENT);

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe(state => {
      this.user = state.user;
    });

    if (isMobileDevice()) {
      this.searchSvc.pageSize = 5;
    } else {
      this.searchSvc.pageSize = 20;
    }

    this.searchSvc.resetFiltersOnTermChange = true;
    this.searchSvc.searchResults.subscribe({next: r => this.handleNext(r), error: () => this.handleError() });
  }

  private handleNext(r: ObjectCardSearchResult) {
    this.hasError = false;
    this.results = r;
    this.loading = false;
    this.searchLogID = this.results.searchLogID;
    this.sendAnalytics(r);
  }

  private handleError() {
    this.hasError = true;
  }

  private sendAnalytics(r: ObjectCardSearchResult) {
    //adobe analytics track
    const query = r.query;
    this.analyticsService.onLoadPageData({
      url: this.router.url,
      isSearching: true,
      searchData: {
        source: 'Site Search',
        term: query.term ?? '',
        resultCount: this.results.totalCount,
        filters: query.filters.map(filter => ({
          name: this.translate.instant(filter.name ?? ''),
          value: this.translate.instant(filter.value ?? '')
        }))
      }
    });

  }

  getMoreResults() {
    this.searchSvc.nextPage();
  }

  applyNoscrollToBody(checked) {
    if (checked) {
      this.renderer.addClass(this.document.body.parentElement, 'mobile-noscroll');
    } else {
      this.renderer.removeClass(this.document.body.parentElement, 'mobile-noscroll');
    }
  }

  resultListIsIncomplete(res: ObjectCardSearchResult): boolean {
    return res.results.length < res.totalCount;
  }

  get headerLabel(): string {
    let translationKey = 'SEARCH_TITLE_MORE';
    if (this.results?.results?.length >= this.results?.totalCount) {
      translationKey = 'SEARCH_TITLE';
    }

    const searchPageHeader = this.translate.instant(translationKey)
      .replace('@@RESULTSCOUNT@@', this.results?.results?.length.toString())
      .replace('@@TOTALCOUNT@@', this.results?.totalCount.toString())
      .replace('@@SEARCHTERM@@', this.results?.query?.term ?? '');

    this.metaDataTranslateService.setPageTitle(searchPageHeader);

    return searchPageHeader;
  }
}
