import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SsoType } from '@pepconnect/features/sso/enums/sso-type';
import { SsoRequest } from '@pepconnect/features/sso/model/sso-request';
import { SsoResponse } from '@pepconnect/features/sso/model/sso-response';
import { PepSsoService } from '@pepconnect/features/sso/services/pep-sso.service';
import { SsoLaunchService } from '@pepconnect/features/sso/services/sso-launch.service';
import { UserService } from '@pepconnect/services/user.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { setIsDirectLaunch } from '@pepconnect/state/auth/auth.actions';
import { of } from 'rxjs';
import { distinct, first, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-tcm-sso-login',
  template: ''
})
export class TcmSsoLoginComponent extends BaseSmartComponent implements OnInit {
  private readonly userService = inject(UserService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly ssoService = inject(PepSsoService);
  private readonly launchService = inject(SsoLaunchService);

  private encryptedTicket: string;
  private encryptedHidLaunch: string;

  ngOnInit(): void {
    this.encryptedTicket = this.route.snapshot.queryParamMap.get('ts') ?? '';
    this.encryptedHidLaunch = this.route.snapshot.queryParamMap.get('tshid') ?? '';

    if (this.encryptedTicket) {
      this.tcmLaunch();
    } else if (this.encryptedHidLaunch) {
      this.processHidLaunch(SsoType.TCMANHEALTHINEERS, this.encryptedHidLaunch);
    } else {
      this.router.navigate(['404']);
    }
  }

  tcmLaunch() {
    // Make sure we're initialized before we do anything
    this.$authState.pipe(
      first(state => state.isInitialized),
      switchMap(state => {
        if (state.isLoggedIn) {
          return this.userService.logout(false, false).pipe(take(1), switchMap(_ => of(state)));
        } else {
          return of(state);
        }
      })
    ).subscribe(() => this.processTcmLaunch(SsoType.TCMService, this.encryptedTicket));
  }

  processTcmLaunch(ssoType: SsoType, messageToDecrypt: string) {
    const ssoRequest: SsoRequest = {
      toDecrypt: messageToDecrypt,
      isCert: false
    };
    this.ssoService.getSsoResponse(ssoRequest, ssoType)
      .pipe(take(1),
        switchMap(response => {
          this.setDirectLaunchIfFlagged(response);

          return this.launchService.login(response, this.router)
            .pipe(take(1),
              switchMap(user => {
                return this.launchService.launch(response, user, this.router).pipe(take(1));
              })
            );
        })
      ).subscribe();
  }

  processHidLaunch(ssoType: SsoType, messageToDecrypt: string) {
    const ssoRequest: SsoRequest = {
      toDecrypt: messageToDecrypt,
      isCert: false
    };
    this.ssoService.getSsoResponse(ssoRequest, ssoType)
      .pipe(take(1),
        switchMap(response => {
          this.setDirectLaunchIfFlagged(response);

          return this.$authState
            .pipe(take(1),
              distinct(authState => authState.isLoggedIn),
              switchMap(authState => {
                return this.launchService.launch(response, authState.user, this.router).pipe(take(1));
              })
            );
        })
      ).subscribe();
  }

  setDirectLaunchIfFlagged(response: SsoResponse) {
    // If this is a direct launch set the state for that
    if (response.isDirectLaunch) {
      this.store.dispatch(setIsDirectLaunch({ directLaunch: response.isDirectLaunch }));
    }
  }
}
