<app-notification class="no-print"></app-notification>
<div id="splash-page" [hidden]="!loading">
    <div id="loader">
        <app-spinner id="translate-spinner"></app-spinner>
    </div>
</div>
<div [class.fill-height]="!loading" [hidden]="loading">
    <div class="view-container ns-v2">
        <app-header></app-header>
        <main class="main-container" data-ste-chapter="main">
            <router-outlet></router-outlet>
        </main>
        <app-footer></app-footer>
    </div>
</div>

<!-- Disclaimer alert message for V2 pages -->
<app-disclaimer-alert [hidden]="loading" id="disclaimer-message" *ngIf="!isCrawlerUA && showAlert" class="no-print">
</app-disclaimer-alert>

<app-translate-spanner *ngIf="isInlineTranslate()" [hidden]="loading"></app-translate-spanner>
