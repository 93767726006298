import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { forms } from '@pepconnect/constants/forms';
import { ConfirmAccount } from '@pepconnect/dtos/account/confirm-account.dto';
import { LoginRequest } from '@pepconnect/dtos/account/login-request.dto';
import { ResendConfirm } from '@pepconnect/dtos/account/password-request.dto';
import { OnToken } from '@pepconnect/interfaces/itoken.interface';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { CheckResetCodeResponse } from '@pepconnect/models/user/check-reset-code-response';
import { UserAccountInfo } from '@pepconnect/models/user/user-account-info';
import { User } from '@pepconnect/models/user/user.model';
import { UserService } from '@pepconnect/services/user.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { passwordValidator } from '@pepconnect/shared/validators/password-validator.directive';
import { selectPendingAuth } from '@pepconnect/state/auth/auth.selector';
import { AppState } from '@pepconnect/state/index';
import { combineLatest, first, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html'
})
export class ConfirmComponent extends BaseSmartComponent implements OnInit, OnToken {
  extractedUser: CheckResetCodeResponse;
  submitted: boolean;
  confirmForm: FormGroup;
  loading = false;
  errorMessage: string;
  hasError: boolean;
  showErrorMessage: string;
  $pendingAuth = this.store.select(selectPendingAuth);
  pendingUser: UserAccountInfo;
  resendMessage: string;
  hasResendResponse: boolean;
  user: User;
  token: string;

  constructor(protected store: Store<AppState>,
    private userSvc: UserService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef) { super(store); }

  ngOnInit(): void {
    // if coming through register process
    const $pendingUser = this.$pendingAuth.pipe(takeUntil(this.ngUnsubscribe), first());
    // if coming through email link
    const $routeData = this.$routeData.pipe(takeUntil(this.ngUnsubscribe));
    // if user decide to confirm at later point
    const $user = this.$user.pipe(takeUntil(this.ngUnsubscribe));

    combineLatest([$routeData, $pendingUser, $user]).subscribe(([routeData, pendingUser, user]) => {
      this.extractedUser = routeData.content;
      this.pendingUser = pendingUser;
      this.user = user;

      this.setUpForm(this.extractedUser, this.pendingUser);
      this.populateFormFields(this.extractedUser, this.pendingUser);
    });
  }

  setUpForm(user: CheckResetCodeResponse, pendingUser: UserAccountInfo) {
    let distilledUser = { email: this.user.email, password: '', optIn: false, optInFeed: false };
    if (pendingUser) {
      distilledUser.email = pendingUser.email;
      distilledUser.password = pendingUser.password;
      distilledUser.optIn = pendingUser.optIn;
      distilledUser.optInFeed = pendingUser.optInFeed;
    } else if (user) {
      distilledUser.email = user.email;
      distilledUser.optIn = user.optIn;
      distilledUser.optInFeed = user.optInFeed;
    }
    this.confirmForm = this.fb.nonNullable.group({
      email: this.fb.control(distilledUser.email),
      code: this.fb.control('', Validators.required),
      password: this.fb.control(distilledUser.password, { validators: [Validators.required, Validators.minLength(forms.VALIDATOR_PASSWORD_MIN_LENGTH), passwordValidator()] }),
      optIn: this.fb.control(distilledUser.optIn, Validators.required),
      optInFeed: this.fb.control(distilledUser.optInFeed, Validators.required)
    });
  }

  populateFormFields(user: CheckResetCodeResponse, pendingUser) {
    const email = pendingUser?.email ?? user.email;
    const optIn = pendingUser?.optIn ?? user.optIn;
    const optInFeed = pendingUser?.optInFeed ?? user.optInFeed;
    const password = pendingUser?.password ?? '';
    this.confirmForm.patchValue({
      email, password, optIn, optInFeed
    });
    // update form validation status
    this.confirmForm.updateValueAndValidity();
  }

  get f() {
    return this.confirmForm?.controls;
  }

  onToken($event: string) {
    this.token = $event;
    // Because this is an external callback, angular doesn't always see the changes
    this.cd.detectChanges();
  }

  async confirm() {
    if (!this.confirmForm?.valid || !this.token) {
      return;
    }

    this.hasResendResponse = false;
    const token = this.token;
    const request: ConfirmAccount = {
      email: this.confirmForm.get('email').value,
      password: this.confirmForm.get('password').value,
      optIn: this.confirmForm.get('optIn').value,
      optInFeed: this.confirmForm.get('optInFeed').value,
      confirmationCode: this.confirmForm.get('code').value,
      checkCode: token
    };

    this.userSvc.confirm(request).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      // success
      next: () => {
        this.submitted = true;
        const loginRequest: LoginRequest = {
          email: this.confirmForm.get('email').value,
          password: this.confirmForm.get('password').value
        };
        this.userSvc.login(loginRequest)
          .pipe(take(1))
          .subscribe({
            next: () => { // intentional, do nothing
            },
            error: (err) => this.errorMessage = err.error_description
          });
      },
      // failure
      error: (error: UnserializedResponseMessage) => {
        {
          this.hasError = true;
          this.submitted = true;
          this.showErrorMessage = error.Message;
        }
      }
    });
  }

  async requestCode() {
    if (!this.confirmForm) {
      return;
    }

    if (this.confirmForm.get('email').invalid) {
      return;
    }
    const token = this.token;
    this.hasError = false;
    const request: ResendConfirm = {
      email: this.confirmForm.get('email').value,
      password: this.confirmForm.get('password').value,
      resetCode: this.extractedUser?.resetCode,
      checkCode: token
    };

    this.userSvc.resendConfirmation(request)
      .pipe(take(1))
      .subscribe({
        next: (response: UnserializedResponseMessage) => {
          this.hasResendResponse = true;
          this.resendMessage = response.Message;
        },
        error: () => {
          // placeholder, can be used for showing error messages
        }
      });
  }

}
