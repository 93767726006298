import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { Tier } from '@pepconnect/models/content/tier.model';
import { Link } from '@pepconnect/models/general/link.model';
import { NavItem, NavItemType } from '@pepconnect/models/ui/nav-item.model';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { BaseComponent } from '@pepconnect/shared/base';
import { AppState } from '@pepconnect/state';
import { selectAuthState, selectUserTiers } from '@pepconnect/state/auth/auth.selector';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { selectUseCheckoutLayout, selectUseMinimalLayout } from '@pepconnect/state/flags/flags.selector';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent extends BaseComponent implements OnInit {
  routeLocale: string;
  tiers: Tier[];
  $authState = this.store.select(selectAuthState);
  $useMinimalLayout = this.store.select(selectUseMinimalLayout);
  $useCheckoutLayout = this.store.select(selectUseCheckoutLayout);
  authState: AuthState;
  navItems: Array<NavItem> = [];
  // temporary flag to switch nav design, remove when ready for PROD
  showNewNav: boolean;
  currentRoute: string;
  isCMS: boolean;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private env: EnvironmentService
  ) {
    super();
  }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe(s => {
      this.routeLocale = s.user.locale.locale;
      this.authState = s;
      this.showNewNav = this.authState?.user?.flags && this.authState.user.flags[constants.FLAG_STORE];
      this.setNavItems();
      this.setTiers();
    });
    this.router.events.pipe(takeUntil(this.ngUnsubscribe), filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.currentRoute = (event as NavigationEnd).url;
      this.navItems.forEach(item => this.getIsActive(item));
    });
    this.isCMS = this.env.isCMS;
  }

  setInitialNavItems() {
    const navItems: NavItem[] = [
      { label: 'BUTTON_HOME', type: NavItemType.link, link: new Link(LinkRel.page, '/') },
      {
        label: 'LABEL_EDUCATION_CATALOG', type: NavItemType.dropdown,
        /** commenting out for now until there is actual content available to display
        content: `<img style="max-height: 400px; max-width: 100%" src="/assets/img/temporary-menu.png">`,
         */
        children: []
      }
    ];

    if (this.authState.isCustomer && this.showNewNav) {
      navItems.push({
        label: 'LABEL_PEPCONNECT_STORE', type: NavItemType.dropdown,
        titleItem: {
          label: 'LABEL_PEPCONNECT_STORE', type: NavItemType.link, link: new Link(LinkRel.page, 'store/home', 'Store - Home'),
        },
        children: [
          (this.authState.user.flags['pep-store-for-you'] && this.authState.user.country.eCommerceEnabled) ?
            {
              label: 'LABEL_FOR_YOU', type: NavItemType.dropdown, children: [
                { label: 'LABEL_EDUCATION_OFFERINGS', type: NavItemType.link, link: new Link(LinkRel.page, 'store/education-offerings-for-you', 'Education Offerings') },
                { label: 'LABEL_EVENTS', type: NavItemType.link, link: new Link(LinkRel.page, 'store/events', 'Events') }
              ]
            }
            :
            {
              label: 'LABEL_FOR_YOU', type: NavItemType.dropdown, children: [
                { label: 'LABEL_EVENTS', type: NavItemType.link, link: new Link(LinkRel.page, 'store/events', 'Events') }
              ]
            },
          {
            label: 'LABEL_FOR_YOUR_WORKFORCE', type: NavItemType.dropdown, children: [
              { label: 'LABEL_WORKFORCE_SOLUTIONS', type: NavItemType.link, link: new Link(LinkRel.page, 'store/workforce-solutions', 'Workforce Solutions') },
              { label: 'LABEL_EDUCATION_OFFERINGS', type: NavItemType.link, link: new Link(LinkRel.page, 'store/education-offerings', 'Education Offerings') }
            ]
          }
        ]
      });
    }

    if (this.authState.isLoggedIn) {
      navItems.push(
        { label: 'LABEL_PROFILE', type: NavItemType.link, link: new Link(LinkRel.page, 'activity') },
        { label: 'PLAN_MENU', type: NavItemType.link, link: new Link(LinkRel.page, 'plan') },
        { label: 'BUTTON_GROUPS', type: NavItemType.link, link: new Link(LinkRel.page, 'groups') },
        { label: 'TRANSCRIPT_HEADER', type: NavItemType.link, link: new Link(LinkRel.page, 'transcript') });
    }
    // copy the local to the class prop to minimize layout shift
    this.navItems = navItems;
  }

  setNavItems() {
    this.setInitialNavItems();
    this.currentRoute = this.router.url
    this.navItems.forEach(item => this.getIsActive(item));
  }

  setTiers() {
    this.store.select(selectUserTiers).pipe(takeUntil(this.ngUnsubscribe)).subscribe(tiers => {
      const items = []
      for (let tier of tiers) {
        items.push({ type: NavItemType.link, label: tier.name, link: new Link(LinkRel.navLink, tier.links[1].href) });
      }
      // copy over for performance
      this.navItems[1].children = items;
      this.currentRoute = this.router.url
      this.navItems.forEach(item => this.getIsActive(item));
    });
  }

  // todo: abstract this to a common func/service
  getNavigateLink(item: NavItem): string[] {
    if (item.type === NavItemType.dropdown) {
      return [];
    }
    if (item.link?.rel === LinkRel.page) {
      if (item.link?.href) {
        if (item.link.v2) {
          return [this.routeLocale, constants.NS_V2].concat(...item.link.href.split('/'));
        } else if (item.link.href === '/') {
          return [this.routeLocale];
        } else {
          return [this.routeLocale].concat(...item.link.href.split('/'));
        }
      }

      return null;
    }

    if (item.link?.href) {
      return item.link.href.split('/').filter(n => n.length > 0);
    }

    return null;

  }

  // todo: abstract this to a common func
  dispatch(item?: NavItem) {
    // if there's no item or the item that's already active was clicked
    // do nothing
    if (!item || item.isActive) {
      return;
    }
    const link = this.getNavigateLink(item);

    // if it is a valid link with no entries, do nothing
    if (link?.length === 0) {
      return;
    }

    // otherwise navigate to the link if it's defined
    // and call the action otherwise
    if (link) {
      this.router.navigate(link);
    } else {
      item.action()
    }
  }

  isClickable(item?: NavItem): boolean {
    return !!item?.link?.href;
  }

  private getIsActive(item: NavItem): boolean {
    // get whether *any* child is active
    const result = (item.children ?? []).reduce((r, child) => this.getIsActive(child) || r, false);

    // otherwise check if the route is self
    const linkUrl = `/${this.getNavigateLink(item)?.join('/') ?? ''}`;
    item.isActive = result || (!!this.currentRoute && this.currentRoute === linkUrl);

    // mark the title as well if it exists
    if (item?.titleItem) {
      const titleLinkUrl = `/${this.getNavigateLink(item.titleItem)?.join('/') ?? ''}`;
      item.titleItem.isActive = (!!this.currentRoute && this.currentRoute === titleLinkUrl);
      item.isActive = item.isActive || item.titleItem.isActive;
    }

    return item.isActive;
  }
}
