<div>
    <div class="card translate-card">
        <div class="card__body">
            <div class="button-group button-group--stacked align-items-center">
                <button type="button" class="button" (click)="onTranslateModalOpen()">Translate</button>
                <button type="button" class="button" (click)="onDataModalOpen()">Data Options</button>
            </div>
        </div>
    </div>
</div>



