<div class="container home-carousel" data-ste-component="recommendation-carousel">
    <h3>{{'LABEL_RECOMMENDATION_TITLE' | translate}}</h3>
    <app-tab-panel>
        <app-tab title="{{'LABEL_RECOMMENDATION_TAB1' | translate}}">
            <uimc-card-slider>
                <app-object-card class="override-marcom" *ngFor="let item of popularItems" [isCarousel]="true"
                    [item]="item">
                </app-object-card>
            </uimc-card-slider>
        </app-tab>
        <app-tab title="{{'LABEL_RECOMMENDATION_TAB2' | translate}}">
            <uimc-card-slider>
                <app-object-card class="override-marcom" *ngFor="let item of newestItems" [isCarousel]="true"
                    [item]="item">
                </app-object-card>
            </uimc-card-slider>
        </app-tab>
    </app-tab-panel>
</div>