import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RouteData } from '@pepconnect/models/general/route-data.model';
import { MetadataTranslateService } from '@pepconnect/services/metadata-translate.service';
import { BaseStateService } from '@pepconnect/shared/base/base-state-service/base-state-service';
import { ScrollService } from '@pepconnect/shared/services/scroll.service';
import { setUIFlags } from '@pepconnect/state/flags/flags.actions';
import { AppState } from '@pepconnect/state/index';
import { forkJoin, of } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { constants } from '../constants/constants';
import { AnalyticsService } from './analytics.service';
import { EnvironmentService } from './environment.service';

/**
 * Route Listener - Call on startup
 * The route listener service listens for route changes and acts on route data.For instance,
 * it sets the page title and if the application uses a minimal layout.
 * It's handy to listen to router events in a single place.
 * */


@Injectable({
  providedIn: 'root'
})
export class RouteListenerService extends BaseStateService {

  constructor(
    private metadataTranslateService: MetadataTranslateService,
    private analyticsSvc: AnalyticsService,
    private translate: TranslateService,
    protected store: Store<AppState>,
    private envService: EnvironmentService,
    private scrollService: ScrollService
  ) {
    super(store);
  }

  start(): void {
    localStorage.removeItem(constants.LS_ENTRY_POINT);
    this.listenforRouteChange();
  }

  /** Listen for routing events.
   *  Routing events are powered by the NgRx store.   See store/router for details.
   * */
  listenforRouteChange() {
    this.$routeData.pipe(filter(routeData => !!routeData)).subscribe((routeData) => {
      this.handleRouteChange(routeData as RouteData);
    });

  }


  async handleRouteChange(data: RouteData) {
    /** Update the title after we translate the keys.   Call analytics page load */
    if (data.title || data.description) {
      const $title = data.title ? this.translate.get(data.title) : of(null);
      const $description = data.description ? this.translate.get(data.description) : of(null);

      forkJoin([$title, $description]).pipe(first()).subscribe(async ([title, description]) => {
        title = typeof title == "object" ? Object.values(title) : title;
        await this.metadataTranslateService.setHTMLMetadata(title, description);
        if (!this.envService.isInlineTranslate && !data.content?.metadata) {
          this.analyticsSvc.onLoadPageData();

        }
      });
    }

    // this might not be needed after ng1 is removed
    setTimeout(() => this.scrollService.scrollTop(), 100);

    /**
     * Handle updating metdata and analytics for dynamic content.
     * Use a resolver that returns an object in the shape of IContent.
     */
    if (data.content?.metadata) {
      const metadata = data.content.metadata;
      await this.metadataTranslateService.setHTMLMetadata(metadata.title, metadata.description);
      if (!this.envService.isInlineTranslate) {
        this.analyticsSvc.onLoadPageData();
      }
    }


    this.store.dispatch(setUIFlags({ useMinimalLayout: data.useMinimalLayout ?? false, useCheckoutLayout: data.useCheckoutLayout ?? false, suppressSearchBar: data.suppressSearchBar ?? false }));
  }
}
