<li class="navigation-bar__item">
    <a id="backButton" (click)="toggleMenuItems($event)" class="navigation-bar__link hidden"><i
            class="icon-arrow-left-medium"></i>{{'LABEL_BACK_BUTTON' | translate}}</a></li>
<li class="navigation-bar__item dropdown dropdown--right js-dropdown" appMcDropdown style="width:100%;">
    <button #dropdownButton (click)="changeDropdownIcon()" class="navigation-bar__link navigation-bar__dropdown-toggle dropdown__toggle">
        {{ item.label | translate }}<i id='ddlIndicator' class="drop-down-indicator"></i>
    </button>
    <div class="menu dropdown__panel navigation-bar__dropdown-panel">
        <a *ngFor="let child of item.children" (click)="dispatch(child, $event)"
            class="navigation-bar__dropdown-item menu__item hidden">{{child.label}}</a>
    </div>
</li>
