import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  /**
   * Setter that sets a LocalStorage item with expiration time in milliseconds
   */
  setItemWithExpiration(key: string, value: string, time: number) {
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expireInMilliSeconds: time
    };

    localStorage.setItem(key, JSON.stringify(item));
  }

  /**
   * Getter that returns null if queried LocalStorage item has expired and also cleans it up,
   * otherwise it will return the item
   */
  getItemWithExpiration(key): any | null {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expireInMilliSeconds) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item;
  }
}
