import { Link } from './../general/link.model';

export interface NavItem {
  type: NavItemType,
  link?: Link;
  titleItem?: NavItem;
  label: string;
  isActive?: boolean;
  action?: Function;
  children?: NavItem[];
  // additional content for the nav-item
  content?: any;
}

export enum NavItemType {
  link,
  dropdown
}
