import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, first, map } from 'rxjs/operators';
import { CountryService } from '@pepconnect/services/country.service';
import { LocaleService } from '@pepconnect/services/locale.service';
import * as metadataActions from './metadata.actions';

@Injectable()
export class MetadataEffects {

  // Fetch locales from the API and load into the store
  loadLocales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(metadataActions.MetadataActions.LoadLocales),
      first(),
      exhaustMap(action => {
        return this.localeService.fetchLocales().pipe(
          map(locales => {
            return metadataActions.setLocales({ locales })
          })
        )
      })
    )
  );

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(metadataActions.MetadataActions.LoadCountries),
      first(),
      exhaustMap(action => {
        return this.countryService.getActive().pipe(
          map(countries => {
            return metadataActions.setCountries({ countries })
          })
        )
      })
    )
  );

  constructor(
    private actions$: Actions,
    private localeService: LocaleService,
    private countryService: CountryService,
  ) { }
}
