import { APP_INITIALIZER } from "@angular/core";
import { AuthService } from "@pepconnect/services/auth.service";
import { ConfigService } from "@pepconnect/services/config-service";
import { UserService } from "@pepconnect/services/user.service";
import { Observable, first, switchMap, take } from "rxjs";

function initializeAppFactory(authService: AuthService, configService: ConfigService, userService: UserService): () => Observable<any> {
  return () => authService.initialize().pipe(
    switchMap(() => configService.initialize()),
    take(1),
    switchMap(() => userService.initialize())
  )
    .pipe(first());
 }

export const PEPCONNECT_INITIALIZER =  {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: initializeAppFactory,
  deps: [AuthService,  ConfigService, UserService,],
}
