import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EmailSubscriptionRequest, UnsubscribeRequest } from '@pepconnect/dtos/account/unsubscribe.dto';
import { UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { UserService } from '@pepconnect/services/user.service';
import { BaseSmartComponent } from '@pepconnect/shared/base/base-smart-component/base-smart.component';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { AppState } from '@pepconnect/state/index';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent extends BaseSmartComponent implements OnInit {
  message: string;
  authState: AuthState;
  confirm: boolean;
  userGuid: string;
  hasError: boolean;
  errorMessage: string;

  constructor(protected store: Store<AppState>,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { super(store); }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (authState) => {
        if (authState) {
          this.authState = authState;
        }

        const routeParam = this.activatedRoute.snapshot;
        if (routeParam && routeParam.params.guid) {
          this.userGuid = routeParam.params.guid;
          const req: EmailSubscriptionRequest = {
            userGuid: routeParam.params.guid,
            isLoggedIn: authState.isLoggedIn
          }
          this.userService.getEmailSubscriptions(req).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: (res) => {
              if (res) {
                this.confirm = res.Message === "TEXT_UNSUBSCRIBE_CONFIRM" || res.Message === "TEXT_UNSUBSCRIBE_CONFIRM_LOGGEDIN";
                this.message = this.translate.instant(res.Message);
              }
            },
            error: (error: UnserializedResponseMessage) => {
              {
                this.hasError = true;
                this.errorMessage = error.Message;
              }
            }
          })
        } else {
          this.message = this.authState.isLoggedIn ? this.translate.instant('TEXT_UNSUBSCRIBE_NOGUID_LOGGEDIN') : this.translate.instant('TEXT_UNSUBSCRIBE_NOGUID');
        }
      }
    })
  }

  goToSettings() {
    this.router.navigate([this.authState.user.locale.locale, 'profile', 'settings']);
  }

  unsubscribe() {
    const req: UnsubscribeRequest = {
      userGuid: this.userGuid,
      isLoggedIn: this.authState?.isLoggedIn
    };

    this.userService.unsubscribe(req).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (response) => {
        if (response) {
          this.message = this.translate.instant(response.Message);
          this.confirm = false;
        }
      },
      error: (error: UnserializedResponseMessage) => {
        {
          this.hasError = true;
          this.errorMessage = error.Message;
        }
      }
    })
  }

}
