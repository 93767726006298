import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-layered-nav-title',
  template: '<a href="#" class="nav-title" appPreventDefault [class.title-clickable]="clickable" [class.is-active]="clickable && active" (click)="onClick($event)"><ng-content></ng-content></a>',
  styleUrls: ['./layered-nav-title.component.scss']
})
export class LayeredNavTitleComponent {

  @Output() click = new EventEmitter();

  @Input() clickable: boolean = true;
  @Input() active: boolean = false;

  constructor() { }

  onClick(event: MouseEvent) {
    if (this.clickable) {
      this.click.emit(event);
    }
  }
}
