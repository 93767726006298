import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { ApiMainService } from '@pepconnect/extensions/api-main.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomTranslateLoaderService implements TranslateLoader {

  constructor(private api: ApiMainService) { }

  getTranslation(lang: string): Observable<any> {
    return this.fetchTranslations(lang);
  }

  fetchTranslations(lang) {
    return this.api.get(`localization?lang=${lang}`);
  }
}
