import { Component } from '@angular/core';
import { constants } from '@pepconnect/constants';
import { LocalStorageService } from '@pepconnect/services/local-storage.service';

@Component({
  selector: 'app-disclaimer-alert',
  templateUrl: './disclaimer-alert.component.html',
  styleUrls: ['./disclaimer-alert.component.scss']
})
export class DisclaimerAlertComponent {

  constructor(private lsService: LocalStorageService) { }
  show = true;

  confirmConsent() {
    const now = new Date();
    // set date to 6months in future
    const futureDate = new Date(now.setMonth(now.getMonth() + 6));

    // getTime() gives milliseconds number for a given date
    this.lsService.setItemWithExpiration(constants.LS_DISCLAIMER_CONSENT, 'acknowledged', futureDate.getTime());
    this.show = false;
  }
}



