import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Link } from '@pepconnect/models/general/link.model';
import { NavItem } from '@pepconnect/models/ui/nav-item.model';

@Component({
  selector: 'app-nav-dropdown',
  templateUrl: './nav-dropdown.component.html',
  styleUrls: ['./nav-dropdown.component.scss']
})
export class NavDropdownComponent implements AfterViewInit {
  @Input() item: NavItem

  @ViewChild('dropdownButton') btn: ElementRef<HTMLButtonElement>;

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) { }

  ngAfterViewInit() {
    this.bindToTopnavPage();
  }

  bindToTopnavPage() {
    // NOTE - this will still be broken when used in Angular
    // because the dom node will change with things like ngIf anywhere
    // in the hierarchy.
    const navBar = this.document.getElementsByClassName("navigation-bar");
    navBar[0].addEventListener("click", (e: Event) => this.homeClicked())
  }

  gotoLink(link: Link) {
    this.changeDropdownIcon();
    console.log('link', link);
    this.router.navigate([link.href]);
  }

  dispatch(item: NavItem, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    if (item.link?.href) {
      this.gotoLink(item.link);
    } else {
      item.action();
    }
  }

  // in the future, we may need to subscribe to router events and see if the item href is in the event url.
  getIsActive(item): boolean {
    return this.document.URL.includes(item.link?.href);
  }

  changeDropdownIcon() {
    var homeButton = this.document.getElementsByClassName("navigation-bar__toggle-button")[0];

    if (!!this.btn && window.getComputedStyle(homeButton).display !== "none") {
      var element = document.getElementById("ddlIndicator");
      element.classList.toggle("hidden");
      this.btn.nativeElement.classList.toggle("navigation-bar__link_Selected");

      var backButton = document.getElementById("backButton");
      backButton.classList.toggle("hidden");

      var thisElement = this.btn.nativeElement.parentElement.parentElement.parentElement;
      var siblings = this.btn.nativeElement.parentElement.parentElement.parentElement.parentElement.childNodes;

      for (var i = 0; i < siblings.length; i++) {
        if (thisElement !== siblings[i]) {
          (siblings[i] as HTMLElement).hidden = !(siblings[i] as HTMLElement).hidden;
        }
       }
    }

    var x = this.document.getElementsByClassName("navigation-bar__list");

    if (window.getComputedStyle(x[0]).display !== "none") {
      homeButton.setAttribute("style", "color:#1a1a1a;")
    }
   }

  toggleMenuItems(e?) {
    if(e) {
      e.stopPropagation();
    }
    this.btn?.nativeElement?.click();
  }

  homeClicked() {
    var homeButton = this.document.getElementsByClassName("navigation-bar__toggle-button")[0];
    if (homeButton) {
      homeButton.removeAttribute("style");

      var x = this.document.getElementsByClassName("navigation-bar__list");

      if (window.getComputedStyle(x[0]).display === "none") {
        homeButton.setAttribute("style", "color:#666666;")
      }
    }
  }
}
