<div class="hero-block">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8">
                <div class="row hero-block-panel"
                     [ngStyle]="{
                        'background-image': backgroundImage
                     }">
                    <div class="col">
                        <h1 class="hero-block__title">{{ 'ABOUT_PEPCONNECT_TITLE' | translate }}</h1>
                        <span class="h5 hero-block__subtitle">{{ 'MAIN_TAGLINE' | translate }}</span>
                        <app-search-bar class="hero-search-bar"></app-search-bar>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hero-block__text">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10">
                    <div class="row">
                        <div class="col-12 col-lg-10 col-xl-8">
                            &nbsp;
                            <!--Maintain proper marcom spacing-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
