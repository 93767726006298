import { Injectable } from '@angular/core';
import { ApiMainService } from '@pepconnect/extensions/api-main.service';
import { Observable } from 'rxjs';
import { CmsPepUser } from '@pepconnect/features/sso/model/cms-pep-user';
import { SsoRequest } from '@pepconnect/features/sso/model/sso-request';

/**
 * Service for connecting to the API to decrypt the SSO request.  This is seperated
 * from the PEP equivalent because this service needs explicit access to the ApiMainService
 * in order to facilitate api connection even when in the inline project.
 * */

@Injectable({
  providedIn: 'root'
})
export class CmsSsoService {

  constructor(private api: ApiMainService) { }

  getCmsLoginResponse(request: SsoRequest): Observable<CmsPepUser> {
    return this.api.post(`sso/cmspep`, request);
  }
}
