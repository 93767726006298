import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '@pepconnect/constants/constants';
import { setScormSettings } from '@pepconnect/functions/scorm-settings';
import { CustomWindow, WINDOW } from '@pepconnect/providers/window-provider';
import { AppStateService } from '@pepconnect/services/app-state.service';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { LocalStorageService } from '@pepconnect/services/local-storage.service';
import { MetadataTranslateService } from '@pepconnect/services/metadata-translate.service';
import { RouteListenerService } from '@pepconnect/services/route-listener.service';
import { BaseComponent } from '@pepconnect/shared/base';
import { AppState } from './store';
import { selectAuthState, selectIsUserInitialized } from './store/auth/auth.selector';
import { selectMetadataState } from './store/metadata/metadata.selector';
import { ConfigService } from '@pepconnect/services/config-service';


/** This components boots up the application and is the first one loaded. */


/** IMPORTANT
 *
 *  Do not show any content until the user is completely initialized to stay compatible with the CN license.
 *  Initialization is done via factory provider in core/providers/initialization-provider.ts
 *  See also: app-state watcher service and route listener
 *
 */

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {

  constants = constants;
  $metadataState = this.store.select(selectMetadataState);
  $authState = this.store.select(selectAuthState);
  $isUserInitialized = this.store.select(selectIsUserInitialized);
  showAlert = false;

  private _initialLoadComplete = false;
  private _userIsInitialized = false;

  loading = true;

  constructor(
    private metadataService: MetadataTranslateService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private environmentService: EnvironmentService,
    private routeListener: RouteListenerService,
    private appState: AppStateService,
    @Inject(WINDOW) private window: CustomWindow,
    private lsService: LocalStorageService,
    private cd: ChangeDetectorRef,
    private configService: ConfigService,
  ) {
    super();
    this.onStartup();
  }

  ngOnInit() {
    setScormSettings(this.configService.cdn, this.window);
    this.routeListener.start();
    this.appState.start();
    this.listenForReadyStateChange();
    this.listenForInitialized();

    this.showAlert = this.showAlertMessage();
  }

  private listenForReadyStateChange() {
    if(document.readyState == 'complete') {
      this._initialLoadComplete = true;
      this.cd.markForCheck();
    } else {
      document.addEventListener('readystatechange', () => {
        if (document.readyState == 'complete') {
          this._initialLoadComplete = true;
          this.setLoadingMarker();
        }
      });
    }
  }

  private listenForInitialized() {
    this.$isUserInitialized.subscribe(isUserInitialized => {
      this._userIsInitialized = isUserInitialized;
      this.setLoadingMarker();
     });
  }


  get isLoading(): boolean {
    return !this._initialLoadComplete || !this._userIsInitialized;
  }

  setLoadingMarker() {
    this.loading = !this._initialLoadComplete || !this._userIsInitialized;
  }

  /** Kickstart configuration of v2 */
  onStartup() {
    this.configureInitialTranslation();
    this.setInitialPageTitle();
  }

  /** Set the default language (may be redundant with the import in the app module file) and do the initial load of translations. */
  configureInitialTranslation() {
    this.translate.setDefaultLang(constants.DEFAULT_LOCALE);
    this.translate.use(constants.DEFAULT_LOCALE);
  }

  /** Use the metadata service to set the initial page title   * */
  setInitialPageTitle() {
    this.metadataService.setPageTitleWithTranslation(constants.SITE_TITLE);
  }


  get isCrawlerUA() {
    return /bot|bingbot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
  }


  showAlertMessage(): boolean {
    const lsItem = this.lsService.getItemWithExpiration(constants.LS_DISCLAIMER_CONSENT);
    // if the item doesn't exist, return true
    if (!lsItem) {
      return true;
    }
    return false;
  }

  isInlineTranslate() {
    return this.environmentService.isInlineTranslate;
  }

}
