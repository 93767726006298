import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ssoConstants } from '@pepconnect/features/sso/model/sso-constants';
import { SamlService } from '@pepconnect/features/sso/services/saml.service';
import { WINDOW } from '@pepconnect/providers/window-provider';
import { UserService } from '@pepconnect/services/user.service';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { of } from 'rxjs';
import { first, switchMap, take } from 'rxjs/operators';

/**
 * This component provides Service Provider initiated login with PEPconnect acting as the
 * service provider.  A user can be directed to here from the login options components,
 * they can be sent here from the IdP if they cannot do IdP initiated login, or the user
 * can browse here directly so this page may be in a state of initialization when the user
 * lands here.
 * */
@Component({
  selector: 'app-saml-sp-initiate',
  template: ''
})
export class SamlSpInitiateComponent extends BaseV2SmartComponent implements OnInit {
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);
  private readonly samlService = inject(SamlService);
  private readonly route = inject(ActivatedRoute);
  private readonly window = inject(WINDOW);

  private requestId: string;
  private relayState: string;

  ngOnInit(): void {
    this.requestId = this.route.snapshot.paramMap.get('launchId');
    this.relayState = this.route.snapshot.queryParamMap.get('RelayState') ?? '';

    if (this.requestId) {
      // Make sure we're initialized before we do anything
      this.authState$.pipe(
        first(s => s.isInitialized),
        switchMap(s => {
          if (s.isLoggedIn) {
            return this.userService.logout(false, false).pipe(take(1), switchMap(_ => of(s)));
          } else {
            return of(s);
          }
        })
      ).subscribe(() => this.processLaunch());
    } else {
      this.router.navigate(['404']);
    }
  }

  processLaunch() {
    this.samlService.getAuthnRequest(this.requestId, this.relayState)
      .pipe(take(1))
      .subscribe(request => {
        // look to see what type of redirect binding we should be using
        if (request.Binding === ssoConstants.SAML_BINDING_POST) {
          // if it's post then we need to post the auth data to the endpoint
          // I could not find a better working way of doing this in Typescript so we're just doing it the old school way
          // create a form we will submit
          let form = document.createElement('form');
          form.method = 'post';
          form.action = request.LoginUrl;
          // create a hidden field for our data
          let hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = ssoConstants.SAML_AUTHN_REQUEST_NAME;
          hiddenField.value = request.SAMLRequest;
          form.appendChild(hiddenField);
          // add the form to the DOM and submit
          document.body.appendChild(form);
          form.submit();
        } else {
          // if we're doing a redirect everything is crammed into the URL and we just toss them over the fence
          this.window.location.href = request.LoginUrl;
        }
      });
  }

}
