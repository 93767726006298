<app-page-container id="reset-password-container">
    <app-narrow-wrap>
        <app-block-centered>
            <app-headline [headline]="'RESET_TITLE' | translate">
            </app-headline>
        </app-block-centered>

        <!-- Main Form Section -->
        <app-form-centered *ngIf="!submitted || hasError" [formGroup]="resetPasswordForm" (onSubmit)="resetPassword()">
            <p>{{ 'RESET_MESSAGE' | translate }}</p>
            <app-form-group>
                <app-textfield-group [skip-invalid]="false">
                    <label class="textfield__label" for="reset-first-name">{{ 'FIRST_NAME' | translate }}</label>
                    <input type="text" class="textfield__input" id="reset-first-name" name="first"
                        formControlName="firstname">
                </app-textfield-group>
            </app-form-group>
            <app-form-group>
                <app-textfield-group [skip-invalid]="false">
                    <label class="textfield__label" for="reset-last-name">{{ 'LAST_NAME' | translate }}</label>
                    <input type="text" class="textfield__input" id="reset-last-name" name="last"
                        formControlName="lastname">
                </app-textfield-group>
            </app-form-group>
            <app-form-group>
                <app-textfield-group [skip-invalid]="false">
                    <label class="textfield__label" for="reset-email">{{ 'EMAIL' | translate }}</label>
                    <input type="email" class="textfield__input" id="reset-email" name="email" formControlName="email"
                        autocomplete="email" email>
                </app-textfield-group>
            </app-form-group>
            <app-form-group>
                <app-textfield-group [skip-invalid]="false">
                    <label class="textfield__label" for="reset-code">{{ 'CONFIRMATION_CODE' | translate }}</label>
                    <input type="text" class="textfield__input" id="reset-code" name="code" formControlName="code">
                    <app-validation-message [control]="f.code" error="required" message="REQUIRED_FIELD_CONFIRM">
                    </app-validation-message>
                </app-textfield-group>
            </app-form-group>
            <app-form-group>
                <app-textfield-group [skip-invalid]="false">
                    <label class="textfield__label" for="reset-password">{{ 'CHANGE_PASSWORD_TITLE' | translate
                        }}</label>
                    <input type="password" class="textfield__input" id="reset-password" name="password"
                        formControlName="password" autocomplete="new-password">
                    <app-validation-message [control]="f.password" [showOnSubmitted]="true" error="required"
                        message="REQUIRED_FIELD_PASSWORD"></app-validation-message>
                    <app-validation-message [control]="f.password" error="minLength" message="REQUIRED_FIELD_PASSWORD">
                    </app-validation-message>
                    <app-validation-message [control]="f.password" error="passwordChars"
                        message="REQUIRED_FIELD_PASSWORD"></app-validation-message>
                </app-textfield-group>
            </app-form-group>
            <p class="help-block">{{ 'FIELD_CHECK_PASSWORD_REQ' | translate }}</p>
            <app-form-group>
                <app-textfield-group [skip-invalid]="false">
                    <label class="textfield__label" for="reset-confirm-password">
                        {{ 'CONFIRM_PASSWORD' | translate }}</label>
                    <input type="password" class="textfield__input" id="reset-confirm-password" name="confirmPassword"
                        formControlName="confirmPassword" autocomplete="new-password">
                    <app-validation-message [control]="f.confirmPassword" [showOnSubmitted]="true" error="required"
                        message="FIELD_CHECK_PASSWORD_TWICE"></app-validation-message>
                    <app-validation-message [control]="f.confirmPassword" error="passwordMatch"
                        message="FIELD_CHECK_PASSWORD_MATCH"></app-validation-message>
                </app-textfield-group>
            </app-form-group>

            <app-form-group *ngIf="isGenerated">
                <app-communication-preferences [optInControl]="f.optIn" [optInFeedControl]="f.optInFeed">
                </app-communication-preferences>
            </app-form-group>
            <app-form-group *ngIf="isGenerated">
                <h6>{{ 'AGREEMENT_BLURB_HEADER' | translate }}</h6>
                <uimc-checkbox (checkedChange)="onTermsAgree($event)">
                    <a href="http://www.healthcare.siemens.com/terms-of-use" target="_blank" class="icon-link-intern">{{
                        'TERMS_OF_USE' | translate }}</a>
                </uimc-checkbox>
                <app-validation-message [control]="f.termsAgree" [showOnSubmitted]="true" error="required"
                    message="REQUIRED_FIELD" type="none-with-invalid"></app-validation-message>
                <br />
                <uimc-checkbox (checkedChange)="onPrivacyAgree($event)">
                    <a [routerLink]="['/', locale, 'privacy']" target="_blank"
                        class="icon-link-intern">{{ 'PRIVACY_POLICY' | translate }}</a>
                </uimc-checkbox>
                <app-validation-message [control]="f.privacyAgree" [showOnSubmitted]="true" error="required"
                    message="REQUIRED_FIELD" type="none-with-invalid"></app-validation-message>
            </app-form-group>
            <app-recaptcha-container (response)="onToken($event)" action="reset" class="display-flex justify-content-start justify-content-lg-end"></app-recaptcha-container>
            <app-form-buttons>
                <button class="button button--primary" type="submit" [disabled]="submitted || !token">{{'SUBMIT_BUTTON' |
                    translate}}</button>
            </app-form-buttons>
            <div *ngIf="hasError" id="error-panel">
                <app-alert indicator="error" [accent-border]="true">
                    {{ showErrorMessage | translate }}
                </app-alert>
            </div>
        </app-form-centered>
    </app-narrow-wrap>
</app-page-container>
