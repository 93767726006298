import { AfterViewInit, Component, EventEmitter, Input, NgZone, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SearchfieldDirective } from '@pepconnect-marcom/search/searchfield.directive';
import { constants } from '@pepconnect/constants';
import { AutocompleteRequest, SearchLogItem } from '@pepconnect/dtos/search-request.dto';
import { AutocompleteResponse } from '@pepconnect/models/ui/search/search.model';
import { SearchService } from '@pepconnect/services/search.service';
import { BaseComponent } from '@pepconnect/shared/base';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { AuthState } from '@pepconnect/state/auth/auth.state';
import { AppState } from '@pepconnect/state/index';
import { combineLatest, Observable, OperatorFunction } from 'rxjs';
import { debounceTime, filter, map, mergeMap, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent extends BaseComponent implements AfterViewInit {

  @ViewChild(SearchfieldDirective)
  heroSearchField: SearchfieldDirective;

  @Input() prefix: ('search' | 'none') = "search";

  @Input() theme: ('white' | 'gray' | 'dark') = "white";

  @Input('hide-border') hideBorder: boolean;

  @Output() searchFocusChange = new EventEmitter<boolean>();

  $authState = this.store.select(selectAuthState);
  private locale: string;

  constructor(
    private searchSvc: SearchService,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private router: Router,
    private ngZone: NgZone,
    private activatedRoute: ActivatedRoute) {
    super();
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe(state => {
      this.locale = state.user?.locale?.locale;
    });
  }


  ngAfterViewInit() {
    combineLatest([
      this.activatedRoute.queryParams,
      this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd), map(e => e.urlAfterRedirects), startWith(this.router.url))])
      .subscribe(([params, url]) => {
        if (url.match('^\/[A-Za-z]{2,2}-[A-Z-a-z]{2,2}\/search')) {
          // if the user is on the search page and the querystring parameter was
          // updated, set that in the form control so it appears in the text box
          if (params.q) {
            setTimeout(() => this.searchForm.get('searchText').setValue(decodeURIComponent(params.q)), 500);
          }
        }
      });
  }

  // search form
  searchForm = this.formBuilder.group({
    searchText: this.formBuilder.control('', { validators: [] })
  })

  // an operator function that reacts to strings with search results
  search: OperatorFunction<string, readonly AutocompleteResponse[]> = (text$: Observable<string>) =>
    combineLatest([text$, this.$authState]).pipe(
      debounceTime(250),
      mergeMap(([term, authState]) => this.autocomplete(term, authState))
    );


  autocomplete(term: string, authState: AuthState): Observable<readonly AutocompleteResponse[]> {
    return this.searchSvc.getAutocomplete({
      term: term.length > constants.SEARCH_TERM_CHAR_LIMIT ? term.substring(0, constants.SEARCH_TERM_CHAR_LIMIT) : term,
      countryCode: authState?.user?.country?.code,
      group: authState?.user?.group ? authState.user.group.groupID.toString() : '2',
      locale: authState?.user?.locale?.locale,
      hasSystemidentifier: (!!authState.user.systemIdentifiers && authState.user.systemIdentifiers.length > 0 && authState.user.systemIdentifiers[0].text !== '')
    } as AutocompleteRequest);
  }

  onSubmit(): boolean {
    const currentText = this.searchForm.get('searchText').value;
    if (currentText === '' || currentText.length < 2) {
      return false;
    }

    this.navigateSearch(this.searchForm.get('searchText').value);
  }

  itemSelected(item: AutocompleteResponse) {
    const logItem: SearchLogItem = {
        friendlyUrl: item?.linkUrl,
        searchTerm: this.searchForm.get('searchText')?.value,
        objectTypeName: item?.type?.name,
        locale: this.locale
    }
    this.searchSvc.recordAutocompleteClick(logItem).pipe(takeUntil(this.ngUnsubscribe)).subscribe();

    // get the selected item text instead of try parsing the element
    const tempspan = this.renderer.createElement('span') as HTMLSpanElement;
    tempspan.innerHTML = item.text;

    this.searchForm.get('searchText').setValue(tempspan.innerText);
    if (item.linkUrl) {
      this.ngZone.run(() => this.router.navigateByUrl(item.linkUrl));
    } else {
      this.navigateSearch(item.text);
    }
  }

  private navigateSearch(searchText) {
    this.router.navigate([`/${this.locale}/search`], { queryParams: { q: searchText } });
    this.heroSearchField.dismissPopup();
  }
}
