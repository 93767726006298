<!--Logged in-->
<uimc-user-identifier [user]="userAvatar" *ngIf="($state | async).isLoggedIn" id="loggedin-identifier">
    <uimc-menu *ngIf="visible" id="loggedin-menu">
        <uimc-menu-item *ngFor="let item of navItems" (click)="dispatch(item)">{{item.text | translate}}</uimc-menu-item>
    </uimc-menu>
</uimc-user-identifier>
<!--Logged out-->
<uimc-user-identifier *ngIf="!($state | async).isLoggedIn" [label]="'HEADER_LOGINREGISTER' | translate" id="anonymous-identifier">
    <uimc-menu *ngIf="visible" id="anonymous-menu">
        <uimc-menu-item *ngFor="let item of navItems" (click)="dispatch(item)">{{item.text | translate}}</uimc-menu-item>
    </uimc-menu>
</uimc-user-identifier>
