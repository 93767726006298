import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants';
import { Locales } from '@pepconnect/enums/locale.enum';
import { User } from '@pepconnect/models/user/user.model';
import { UserService } from '@pepconnect/services/user.service';
import { BaseComponent } from '@pepconnect/shared/base/base-component/base.component';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/store';

// cloudMedia as non-TS, non-Angular JS
declare var cloudMedia: any;

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent extends BaseComponent implements OnInit {

  $authState = this.store.pipe(select(selectAuthState));
  user: User;
  cloudMedia: any;
  showEPT = false;
  pepconnectionsLink = '';

  constructor(private store: Store<AppState>, private userService: UserService) {
    super();
    this.cloudMedia = cloudMedia;
  }

  ngOnInit() {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe(authState => {
      this.user = authState.user;
      if (this.user.locale.locale === Locales.Portuguese) {
        this.pepconnectionsLink = '/pt-br/pepconnect';
      } else {
        this.pepconnectionsLink = `/${this.user.locale.locale}/about/pepconnect`;
      }
    });

    this.userService.checkFeatureFlag(constants.FLAG_EPT).subscribe(flag => {
      this.showEPT = !!flag;
    }),
      catchError(() => of(null))
  }

  routeToTMCAcademy() {
    window.open('https://pep.siemens-info.com/en-us/search?q=tmc&filters=SEARCH_TYPE%7CLEARNING_TYPE_SUBSCRIPTION', 'noopener,noreferrer');
  }

  transformedVideoSource(): string {
    // translated PEP video for Japan
    if (this.user.locale.locale === Locales.Japanese) {
      return cloudMedia.Utils.cloudTransformUrl('CMS/Videos/258b8b53-418e-47db-8dc0-19e90837fabf/mp4_720.mp4');
    }

    // translated PEP video for Portuguese
    if (this.user.locale.locale === Locales.Portuguese) {
      return cloudMedia.Utils.cloudTransformUrl('CMS/Videos/93c555b6-8b6a-4186-a80e-59dfb670409b/mp4_720.mp4');
    }

    // translated PEP video for Spanish
    if (this.user.locale.locale === Locales.Spanish) {
      return cloudMedia.Utils.cloudTransformUrl('CMS/Videos/280c3a46-fe5a-4915-bde6-315348ad20ec/mp4_720.mp4');
    }

    // English video for rest countries
    return cloudMedia.Utils.cloudTransformUrl('CMS/Videos/20b77761-edfd-4657-8a06-b429633421d1/mp4_720.mp4');
  }

  transformedPoster(): string {
    // Japan video poster
    if (this.user.locale.locale === Locales.Japanese) {
      return cloudMedia.Utils.cloudTransformUrl('CMS/Thumbnails/258b8b53-418e-47db-8dc0-19e90837fabf_28142.jpg');
    }

    // Portuguese video poster
    if (this.user.locale.locale === Locales.Portuguese) {
      return cloudMedia.Utils.cloudTransformUrl('CMS/Thumbnails/93c555b6-8b6a-4186-a80e-59dfb670409b_28142.jpg');
    }
    
    // Spanish video poster
    if (this.user.locale.locale === Locales.Spanish) {
      return cloudMedia.Utils.cloudTransformUrl('CMS/Thumbnails/280c3a46-fe5a-4915-bde6-315348ad20ec_28142.jpg');
    }

    // video poster for rest countries
    return cloudMedia.Utils.cloudTransformUrl('ui/pep_video_poster.png');
  }

  goToEpt() {
    // Future implementation - No action for now
  }
}
