import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChildFn } from '@angular/router';
import { of } from 'rxjs';
import { UserService } from '@pepconnect/services/user.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '@pepconnect/services/environment.service';
import { ssoConstants } from '@pepconnect/features/sso/model/sso-constants';
import { inject } from '@angular/core';
import { WINDOW } from '@pepconnect/providers/window-provider';

export const canActivateCMSChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const window: Window = inject(WINDOW);
  const envService = inject(EnvironmentService);
  const userService = inject(UserService);
  // Only check if inside one of the CMS sites and its not the cms SSO page (avoid a race condition)
  if (envService.isCMS && !window.location.href.includes('cmspeplogin')) {
    return userService.initialize().pipe(map(() => {
      // inline-loggedin and cms-loggedin can only be set when authenticating via sso/cmspeplogin depending on if it's inline or review PEP
      if ((envService.isInlineTranslate && sessionStorage.getItem(ssoConstants.CMS_LS_LOG_INLINE_KEY) === null)
          || (!envService.isInlineTranslate && sessionStorage.getItem(ssoConstants.CMS_LS_LOG_KEY) === null)) {
  
        // Clear local storage and redirect to error page
        localStorage.clear();
        window.location.href = '/cmsnotloggedin.html';
        return false;
      }
      else {
        return true;
      }
    }));
  }
  else {
    return of(true);
  }
}
