import { AuthState } from "@pepconnect/state/auth/auth.state";
import { constants } from "@pepconnect/constants/constants";
import { Transitions } from "@pepconnect/models/user/transitions.model";

/**
 *  Given an old state and a new state, compute the transitions (key differences) of the state
 * @param oldState
 * @param newState
 */
export function computeTransitions(oldState: AuthState, newState: AuthState): Transitions {
  return {
    localeChanged: (oldState?.user.locale?.id !== newState.user?.locale?.id),
    countryChanged: (oldState?.user.country?.id !== newState.user?.country?.id),
    isLoggingIn: (!oldState?.isLoggedIn && newState.isLoggedIn),
    isLoggingOut: (oldState?.isLoggedIn && !newState.isLoggedIn),
    isInitializing: (!oldState?.isInitialized && newState.isInitialized),
    redesignFlagChange: (oldState?.user?.flags !== undefined) && (oldState?.user?.flags[constants.FLAG_REDESIGN] !== newState.user?.flags[constants.FLAG_REDESIGN]),
    storeFlagChange: (oldState?.user?.flags !== undefined) && (oldState?.user?.flags[constants.FLAG_STORE] !== newState.user?.flags[constants.FLAG_STORE]),
    deInitialized: !newState.isInitialized
  };
}
