import { Component, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerConstants } from '@pepconnect/features/account/register/register-constants';
import { BaseV2SmartComponent } from '@pepconnect/shared/base/base-smart-component-v2';
import { first, take } from 'rxjs';

@Component({
  selector: 'app-next-step',
  templateUrl: './next-step.component.html'
})
export class NextStepComponent extends BaseV2SmartComponent implements OnInit {
  private readonly translate = inject(TranslateService);
  protected nextStepText: string;

  ngOnInit(): void {
    this.authState$.pipe(first(authState => this.initializationGuard(authState))).subscribe(authState =>
      // A hard refresh can prevent the local key from getting translated so use the subscription to make sure the translation is present.
      this.translate.get('REGISTER_NEXT_STEP_TEXT').pipe(take(1)).subscribe((t) => { this.nextStepText = t.replace(registerConstants.EMAIL_PLACEHOLDER, this.getEmail(authState)) })
    );
  }

  getEmail(authState: any): string {
    return authState?.pendingAuthentication?.email ?? this.translate.instant('REGISTER_NEXT_STEP_LOST_EMAIL');
  }
}
