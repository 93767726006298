<app-page-container id="confirm-container">
    <app-narrow-wrap>
        <app-block-centered>
            <app-headline [headline]="'REGISTER_CONFIRM_TITLE' | translate">
            </app-headline>
        </app-block-centered>

        <!-- Main Form Section -->
        <app-form-centered *ngIf="f" [formGroup]="confirmForm" (onSubmit)="confirm()">
            <p>{{ 'REGISTER_CONFIRM_CONTENT' | translate }}</p>
            <app-form-group>
                <app-textfield-group [skip-invalid]="false">
                    <label class="textfield__label" for="email">{{ 'EMAIL' | translate }}</label>
                    <input type="email" class="textfield__input" id="email" name="email" formControlName="email"
                        autocomplete="email" email>
                    <app-validation-message [control]="f.email" [showOnSubmitted]="true" error="required"
                        message="FIELD_CHECK_EMAIL"></app-validation-message>
                </app-textfield-group>
            </app-form-group>
            <app-form-group>
                <app-textfield-group [skip-invalid]="false">
                    <label class="textfield__label" for="password">{{ 'CHANGE_PASSWORD_TITLE' | translate }}</label>
                    <input type="password" class="textfield__input" id="password" name="password"
                        formControlName="password" autocomplete="new-password">
                    <app-validation-message [control]="f.password" [showOnSubmitted]="true" error="required"
                        message="REQUIRED_FIELD_PASSWORD"></app-validation-message>
                    <app-validation-message [control]="f.password" error="minLength" message="REQUIRED_FIELD_PASSWORD">
                    </app-validation-message>
                    <app-validation-message [control]="f.password" error="passwordChars"
                        message="REQUIRED_FIELD_PASSWORD"></app-validation-message>
                </app-textfield-group>
            </app-form-group>
            <app-form-group>
                <app-textfield-group [skip-invalid]="false">
                    <label class="textfield__label" for="code">{{ 'CONFIRMATION_CODE' | translate }}</label>
                    <input type="text" class="textfield__input" id="code" name="code" formControlName="code">
                    <app-validation-message [control]="f.code" error="required" message="REQUIRED_FIELD_CONFIRM">
                    </app-validation-message>
                </app-textfield-group>
            </app-form-group>

            <app-form-group>
                <app-communication-preferences [optInControl]="f.optIn" [optInFeedControl]="f.optInFeed">
                </app-communication-preferences>
            </app-form-group>
            <app-recaptcha-container (response)="onToken($event)" action="confirm" class="display-flex justify-content-start justify-content-lg-end"></app-recaptcha-container>
            <app-form-buttons>
                <button class="button button--primary" type="submit" [disabled]="(submitted && !hasError && !hasResendResponse) || !token">{{'CONFIRM' | translate}}</button>
                <a href="#" (click)="requestCode()">{{ 'REGISTER_CONFIRM_RESEND' | translate }}</a>
            </app-form-buttons>
            <div *ngIf="hasError && !hasResendResponse" id="error-panel">
                <app-alert indicator="error" [accent-border]="true">
                    {{ showErrorMessage | translate }}
                </app-alert>
            </div>
            <!-- resend code message -->
            <div *ngIf="hasResendResponse && !hasError">
                <app-alert indicator="success" [accent-border]="false">
                    {{ resendMessage | translate }}
                </app-alert>
            </div>
        </app-form-centered>
    </app-narrow-wrap>
</app-page-container>
