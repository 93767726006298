import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SearchComponent } from '@pepconnect/features/search/search.component';
import { constants } from '@pepconnect/constants/constants';
import { PageRequirement } from '@pepconnect/enums/page-requirement.enum';
import { TermTypes } from '@pepconnect/enums/term-types.enum';
import { CustomRoutes } from '@pepconnect/extensions/custom-route';
import { forSSO, withCommonRouteData, withMinimalLayout } from '@pepconnect/functions/route-data';
import { AboutComponent } from './features/about/about.component';
import { InactiveCountryRedirectComponent } from './features/account/intl/inactive-country-redirect/inactive-country-redirect.component';
import { LoginComponent } from './features/account/login/login.component';
import { TermsComponent } from './features/account/terms/terms.component';
import { HomeComponent } from './features/home/home.component';
import { CmsSsoLoginComponent } from './features/sso/components/cms-sso-login/cms-sso-login.component';
import { LtiSsoLoginComponent } from './features/sso/components/lti-sso-login/lti-sso-login.component';
import { PepSsoLoginComponent } from './features/sso/components/pep-sso-login/pep-sso-login.component';
import { SamlIdpInitiateComponent } from './features/sso/components/saml-idp-initiate/saml-idp-initiate.component';
import { SamlSpInitiateComponent } from './features/sso/components/saml-sp-initiate/saml-sp-initiate.component';
import { SamlSpLoginComponent } from './features/sso/components/saml-sp-login/saml-sp-login.component';
import { SsoMainComponent } from './features/sso/components/sso-main/sso-main.component';
import { TcmSsoLoginComponent } from './features/sso/components/tcm-sso-login/tcm-sso-login.component';
import { ForgotPasswordComponent } from './features/account/forgot-password/forgot-password.component';
import { RegisterComponent } from './features/account/register/register.component';
import { ResetPasswordComponent } from './features/account/reset-password/reset-password.component';
import { ConfirmComponent } from './features/account/confirm/confirm.component';
import { userAccountPathResolver } from './features/account/user-account-path.resolver';
import { UnsubscribeComponent } from './features/profile/features/unsubscribe/unsubscribe.component';
import { CheckoutShellComponent } from './features/checkout/features/checkout/checkout-shell/checkout-shell.component';
import { DeniedComponent } from './features/denied/denied.component';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { canActivateCMSChild } from '@pepconnect/guards/cms.guard';
import { canActivateRouteLocale } from '@pepconnect/guards/route-locale.guard';
import { canActivateInactiveCountries, canActivateInactiveCountriesChild } from '@pepconnect/guards/inactive-countries.guard';
import { canActivatePagePolicyChild } from '@pepconnect/guards/page-policy.guard';
import { canActivateStoreChild } from '@pepconnect/guards/store.guard';
import { HidTransitionIntermediateComponent } from './features/account/login/hid-transition-intermediate/hid-transition-intermediate.component';
import { NextStepComponent } from '@pepconnect/features/account/next-step/next-step.component';
import { AccountExistsComponent } from '@pepconnect/features/account/account-exists/account-exists.component';

@Component({ template: '' })
export class EmptyComponent { }

/** Router Module
 *  When adding a route, make sure you have the correct data options as needed to control necessary behavior such as
 *  page title and  page policy (see PageRequirements)
 */

/** See also
 *  core/components/route-listener
 *  core/guards/page-policy.guard
 *  core/enums/page-requirement.enum
 *  core/models/route-data.model
 *  core/extensions/custom-routeout
 *  core/functions/route-data
 */


/*****************************************************************************
 * IF your route 404's after BEING ADDED its most likely is missing a policy.*
 * or an incorrect policy was applied.                                        *
 *****************************************************************************/

/**
 * IMPORTANT: When adding a route, that does not have /v2/ in the url, be sure to add it to the whitelist in ngx-route.service.ts.
 * Otherwise, the route will get picked up by angularjs and 404
 */

/** SEE README: Routing.md */


export const routes: CustomRoutes = [
  {
    path: '',
    canActivateChild: [canActivateCMSChild],
    children: [
      { path: '', redirectTo: 'en-us', pathMatch: 'full' },
      {
        path: `sso`,
        component: SsoMainComponent,
        children: [
          { path: 'cmspeplogin', component: CmsSsoLoginComponent, data: forSSO() },
          { path: 'saml/sp/:launchId', component: SamlSpInitiateComponent, data: forSSO() },
          { path: 'saml/:requestId/:nonce', component: SamlSpLoginComponent, data: forSSO() },
          { path: 'lti/:launchid/:nonce', component: LtiSsoLoginComponent, data: forSSO() }
        ]
      },
      {
        path: `saml`,
        component: SsoMainComponent,
        children: [
          { path: 'idp/:sp', component: SamlIdpInitiateComponent, data: forSSO() },
          { path: 'idp/:sp/:requestId/:nonce', component: SamlIdpInitiateComponent, data: forSSO() }
        ]
      },
      {
        path: `tcman`,
        component: SsoMainComponent,
        children: [
          { path: 'launchservicecourse', component: TcmSsoLoginComponent, data: forSSO() },
          { path: 'servicecourse', component: TcmSsoLoginComponent, data: forSSO() },
          { path: 'tcmanhealthineers', component: TcmSsoLoginComponent, data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken) },
        ]
      },
      {
        path: `pep1`,
        component: PepSsoLoginComponent, data: forSSO()
      },
      {
        path: `launch`,
        loadChildren: () => import('./features/launch/launch.module').then(m => m.LaunchModule)
      },
      /** Routes that DO NOT NEED v2/ in the url - Pages that have been migrated, translated or DO NOT EXIST in v1 should be nested under here */
      {
        path: `:locale`,
        canActivate: [canActivateRouteLocale, canActivateInactiveCountries],
        canActivateChild: [canActivatePagePolicyChild],
        component: HomeComponent, data: { ...withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'Home']), suppressSearchBar: true }
      },
      {
        path: `:locale`,
        canActivate: [canActivateRouteLocale],
        canActivateChild: [canActivatePagePolicyChild, canActivateInactiveCountriesChild],
        children: [
          { path: 'home', component: HomeComponent, data: { ...withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'Home']), suppressSearchBar: true } },
          { path: 'login', component: LoginComponent, data: withMinimalLayout(PageRequirement.ShouldBeAnonymous, [constants.SITE_TITLE, 'HEADER_LOGIN']) },
          { path: 'privacy', component: TermsComponent, data: { ...withMinimalLayout(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'PRIVACY_POLICY']), terms: TermTypes.PrivacyPolicy } },
          { path: 'special-terms', component: TermsComponent, data: { ...withMinimalLayout(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'EULA_MODAL_TITLE']), terms: TermTypes.SpecialTerms } },
          { path: 'unsupported-country', component: InactiveCountryRedirectComponent, data: withMinimalLayout(PageRequirement.ShouldBeLoggedIn, [constants.SITE_TITLE, 'HEADER_LOGIN']) },
          { path: 'store', data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken), canActivateChild: [canActivateStoreChild], loadChildren: () => import('@pepconnect/store/module').then(m => m.PEPStoreModule) },
          { path: 'support', data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken), loadChildren: () => import('./features/support/support.module').then(m => m.SupportModule) },
          { path: 'about', component: AboutComponent, data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'ABOUT_PAGE_HEADER']) },
          { path: 'about/:fragment', component: AboutComponent, data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'ABOUT_PAGE_HEADER']) },
          { path: 'search', component: SearchComponent, data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'TITLE_SEARCH']) },
          { path: 'forgot', component: ForgotPasswordComponent, data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'FORGOT_PASSWORD']) },
          { path: 'register', component: RegisterComponent, data: withCommonRouteData(PageRequirement.ShouldBeAnonymous, [constants.SITE_TITLE, 'REGISTER_TITLE']) },
          { path: 'reset/:code', resolve: { content: userAccountPathResolver }, component: ResetPasswordComponent, data: { ...withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'RESET_TITLE']), hasCode: true } },
          { path: 'complete-signup/gen/:code', resolve: { content: userAccountPathResolver }, component: ResetPasswordComponent, data: { ...withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'RESET_TITLE']), isUserCreatedFromVo: true, hasCode: true } },
          { path: 'confirm', resolve: { content: userAccountPathResolver }, component: ConfirmComponent, data: { ...withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'REGISTER_CONFIRM_TITLE']), hasCode: false } },
          { path: 'confirm/:code', resolve: { content: userAccountPathResolver }, component: ConfirmComponent, data: { ...withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'REGISTER_CONFIRM_TITLE']), hasCode: true } },
          { path: 'next-step', component: NextStepComponent, data: withCommonRouteData(PageRequirement.ShouldBeAnonymous, [constants.SITE_TITLE, 'REGISTER_NEXT_STEP_TITLE']) },
          { path: 'account-exists', component: AccountExistsComponent, data: withCommonRouteData(PageRequirement.ShouldBeAnonymous, [constants.SITE_TITLE, 'REGISTER_ACCOUNT_EXISTS_TITLE']) },
          { path: 'profile', data: withCommonRouteData(PageRequirement.ShouldBeLoggedIn), loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule) },
          { path: 'events', data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken), loadChildren: () => import('./features/events/events.module').then(m => m.EventsModule) },
          { path: 'groups', data: withCommonRouteData(PageRequirement.ShouldBeLoggedIn), loadChildren: () => import('./features/teams/teams.module').then(m => m.TeamsModule) },
          { path: 'unsubscribe', component: UnsubscribeComponent, data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'LABEL_UNSUBSCRIBE']) },
          { path: 'unsubscribe/:guid', component: UnsubscribeComponent, data: withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'LABEL_UNSUBSCRIBE']) },
          { path: 'messagecenter', data: withCommonRouteData(PageRequirement.ShouldBeLoggedIn), loadChildren: () => import('./features/messages/messages.module').then(m => m.MessagesModule) },
          { path: 'transcript', data: withCommonRouteData(PageRequirement.ShouldBeLoggedIn), loadChildren: () => import('./features/transcript/transcript.module').then(m => m.TranscriptModule) },
          { path: 'transition', component: HidTransitionIntermediateComponent, data: withMinimalLayout(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'Home'])},
          {
            path: 'checkout',
            component: CheckoutShellComponent,
            data: { ...withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'CHECKOUT_PAGE_TITLE']), useCheckoutLayout: true },
            loadChildren: () => import('./features/checkout/checkout.module').then(m => m.CheckoutModule)
          },
          {
            path: 'activity',
            data: { ...withCommonRouteData(PageRequirement.ShouldBeLoggedIn, [constants.SITE_TITLE, 'TITLE_ACTIVITYFEED']) },
            loadChildren: () => import('./features/activity/activity.module').then(m => m.ActivityModule)
          },
          { path: 'denied', component: DeniedComponent, data: { ...withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, 'ACCESS_DENIED_TITLE']) } },
          { path: '404', component: NotFoundComponent, data: { ...withCommonRouteData(PageRequirement.ShouldHaveAuthToken, [constants.SITE_TITLE, '404_TITLE']) } },
          { path: 'plan', data: withCommonRouteData(PageRequirement.ShouldBeLoggedIn), loadChildren: () => import('./features/plan/plan.module').then(m => m.PlanModule) },
        ]
      },
      {
        path: `:contentLocale`,
        canActivateChild: [canActivatePagePolicyChild, canActivateInactiveCountriesChild],
        loadChildren: () => import('@pepconnect/content/module').then(m => m.ContentModule)
      },
      { path: ':locale/home', redirectTo: '/:locale', pathMatch: 'full' },
      {
        path: '**',
        component: NotFoundComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      enableTracing: false,
    }
  )],
  exports: [RouterModule]
})

export class AppRoutingModule { }
