import { Component, inject } from '@angular/core';
import { UserHidService } from '@pepconnect/services/user-hid.service';

@Component({
  selector: 'app-account-exists',
  templateUrl: './account-exists.component.html',
  styleUrls: ['./account-exists.component.scss']
})
export class AccountExistsComponent {
  private readonly userHidService = inject(UserHidService);

  login() {
    this.userHidService.loginWithHid();
  }
}
