import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants/constants';
import { Link } from '@pepconnect/models/general/link.model';
import { NavItem } from '@pepconnect/models/ui/nav-item.model';
import { WINDOW } from '@pepconnect/providers/window-provider';
import { BaseComponent } from '@pepconnect/shared/base';
import { selectUserLocaleCode } from '@pepconnect/state/auth/auth.selector';
import { AppState } from '@pepconnect/state/index';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nav-link',
  template: `
                <li class="navigation-bar__item">
                    <a href='#' (click)="dispatch(item, $event)" class="navigation-bar__link" [ngClass]="{'is-active': getIsActive(item)}">{{ item.label | translate}}</a>
                </li>
  `
})
export class NavLinkComponent extends BaseComponent implements OnInit {
  $userLocaleCode = this.store.select(selectUserLocaleCode);
  locale: string;
  @Input() item: NavItem

  constructor(private route: ActivatedRoute, private router: Router, @Inject(WINDOW) private window: Window, private store: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.$userLocaleCode.pipe(takeUntil(this.ngUnsubscribe)).subscribe(locale => this.locale = locale);
  }

  gotoV2Link(link: Link) {
    this.router.navigate([this.locale, constants.NS_V2, link.href]);
  }

  gotoLink(link: Link) {
    if (link.href === '/') {
      this.router.navigate([this.locale]);
    } else {
      this.router.navigate([this.locale, link.href]);
    }
  }

  dispatch(item: NavItem, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    if (item.link?.href) {
      if (item.link.v2) {
        this.gotoV2Link(item.link);
      } else {
        this.gotoLink(item.link);
      }
    } else {
      item.action();
    }
  }

  /**
   *  Determine if a given nav item should be highlighted.   I
   *  n the future, we may want a more precise way of doing this such as storing a regex in the navitem config,
   * @param item
   */
  getIsActive(item): boolean {
    let path = this.window.location.pathname.split('/')[2];
    return path && item.link.href.includes(path);
  }
}
