<div class="alert-styles" *ngIf="show">
    <div class="alert is-open">
        <div class="alert__container">
            <div class="alert__header justify-content-end">
                <span class="icon alert__indicator"></span>
                <button type="button" class="alert__close"  (click)="confirmConsent()">
                    <span class="icon-close"></span>
                </button>
            </div>
            <div class="alert__body">
                <p class="alert__text">
                    {{"DISCLAIMER_TEXT" | translate}}
                </p>
            </div>
            <div class="alert__footer">
                <button class="button button--primary" (click)="confirmConsent()">{{ 'MODAL_OK_BUTTON' | translate }}</button>
            </div>
        </div>
    </div>
</div>


